export interface StatusConfigValue {
  [key: number]: StatusValue;
}
export interface StatusValue {
  color: string;
  label: string;
}
export const statusConfig: StatusConfigValue = {
  1: {
    label: 'readyToUse',
    color: '#4dd633',
  },
  2: {
    label: 'active',
    color: '#19ad58',
  },
  3: {
    label: 'maintenancePending',
    color: '#f5f749',
  },
  4: {
    label: 'inMaintenance',
    color: '#8e2525',
  },
  5: {
    label: 'employeeIntervention',
    color: '#3993dd',
  },
  6: {
    label: 'defect',
    color: '#000',
  },
  7: {
    label: 'maintenanceOverdue',
    color: '#8e2525',
  },
  8: {
    label: 'underRepair',
    color: '#8e2525',
  },
  //shipping
  9: {
    label: 'onShipping',
    color: '#8e2525',
  },
  10: {
    label: 'onStock',
    // color: '#19ad58'
    color: 'orange',
  },
};

export const userStatusConfig: StatusConfigValue = {
  1: {
    label: '"',
    color: '#4dd633',
  },
};

export const checkStatusConfig: StatusConfigValue = {
  1: {
    label: 'completed',
    color: '#4dd633',
  },
  2: {
    label: 'inProgress',
    color: '#4dd633',
  },
};

// export const partnerStatusConfig: StatusConfigValue = {
//          '-1': {
//            label: 'completed',
//            color: '#4dd633'
//          },
//          1: {
//            label: 'Zurückgefordert',
//            color: '#8e2525'
//          }
//        };
