




































import { defineComponent, computed } from '@vue/composition-api';
import store from 'src/store/directStore';
// interfaces
import { Device, DeviceList } from 'src/interfaces/Device.Interface';
//confígs
import { statusConfig } from 'src/config/statusConfig';

export default defineComponent({
  name: 'ShippingDeviceSelect',
  components: {},
  props: {
    selectedDeviceList: {
      type: Array as () => Device[],
      required: true,
    },
    search: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const deviceList = computed(() => {
      let list: DeviceList[] = [];

      store.state.deviceAdmin.deviceList.forEach((device) => {
        if (
          device.assignedTo.type !== 'user' &&
          device.assignedTo.type !== 'serviceProvider'
        ) {
          const isDeviceAlreadySelected = props.selectedDeviceList.find(
            (entry) => entry._id === device._id
          )
            ? true
            : false;

          if (device._id && !isDeviceAlreadySelected) {
            let canAdd = true;
            if (props.search) {
              if (
                !device.inventoryNumber
                  .toLocaleLowerCase()
                  .includes(props.search.toLocaleLowerCase())
              ) {
                canAdd = false;
              }
            }
            if (canAdd)
              list.push({
                id: device._id,
                inventoryNumber: device.inventoryNumber,
                typeId: device.type,
                typeLabel: store.getters.deviceAdmin.getDeviceTypeLabelById(
                  device.type
                ),
                doctype: device.doctype,
                statusCode: device.status,
                status: context.root
                  .$t('status.' + statusConfig[device.status].label)
                  .toString(),
                statusColor: statusConfig[device.status].color,
                belongDefinition: device.belongDefinition
                  ? device.belongDefinition
                  : '',
              });
          }
        }
      });

      return list;
    });

    const addDevice = (deviceId: string) => {
      context.emit('add-device', deviceId);
    };
    return { deviceList, addDevice };
  },
});
