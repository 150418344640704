



















import { defineComponent, computed } from '@vue/composition-api';
import _ from 'lodash';
//components
import gwTextEditor from 'components/basic/gwTextEditor/gwTextEditor.vue';
//interfaces
import { PayloadVal, TextFieldValue } from 'src/interfaces/Form.Interface';

export default defineComponent({
  name: 'gwTextField',
  components: { gwTextEditor },
  props: {
    fieldValue: {
      type: Object as () => TextFieldValue,
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  setup(props, context) {
    const changeValue = (newValue: string) => {
      let editFieldValue = _.cloneDeep(props.fieldValue);
      editFieldValue.text = newValue;
      editFieldValue.lastChange = new Date();
      context.emit('input', editFieldValue);
    };

    const textFieldStyle = computed(() => {
      return {
        '--img-width': props.fieldValue.imgWidth
      };
    });

    const onClickTextfield = (optIdx: number, evt: Event) => {
      let payload: PayloadVal = {
        fieldType: 'textField',
        optIdx: optIdx
      };
      evt.stopPropagation();

      context.emit('click-option', payload);
    };

    return { changeValue, textFieldStyle, onClickTextfield };
  }
});
