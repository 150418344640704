import state from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';
import DeviceAdminStateInterface from './deviceAdminStateInterface';
import { defineModule } from 'direct-vuex';

const deviceAdmin = defineModule({
  namespaced: true,
  actions: actions,
  getters: getters,
  mutations: mutations,
  state: (): DeviceAdminStateInterface => state,
});

export default deviceAdmin;
