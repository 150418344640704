import { RoleConfig } from './../interfaces/Roles.Interface';
import { Report } from './../interfaces/Report.Interface';
import { ShippingTask } from './../interfaces/Shipping.Interface';
import { plainToClass } from 'class-transformer';
//interfaces
import { CheckDefinition } from 'src/interfaces/Check.Interface';
import { Notification, RecordTypes } from 'src/interfaces/Common.Interface';
import { ActionRequest } from 'src/interfaces/Action.Interface';
import {
  Device,
  DeviceType,
  PartnerConfigValue,
} from 'src/interfaces/Device.Interface';
import { Formular } from 'src/interfaces/Form.Interface';
import { OrganisationLocalLocation } from 'src/interfaces/Location.Interface';
import { RoleDefinition } from 'src/interfaces/Roles.Interface';
import { OrganisationValue } from 'src/interfaces/Settings.Interface';
import {
  Customer,
  ServiceProvider,
  User,
  UserContact,
} from 'src/interfaces/User.Interface';
import { ApplicationLog } from 'src/interfaces/Log.Interface';

export function createClass(doctype: string, val: RecordTypes) {
  let newClass: RecordTypes | undefined;

  switch (doctype) {
    case 'device':
      newClass = plainToClass(Device, val);
      break;
    case 'deviceType': {
      newClass = plainToClass(DeviceType, val);
      break;
    }
    case 'checkDefinition': {
      newClass = plainToClass(CheckDefinition, val);
      break;
    }
    case 'user': {
      newClass = plainToClass(User, val);
      break;
    }
    case 'formular': {
      newClass = plainToClass(Formular, val);
      break;
    }
    case 'actionRequest': {
      newClass = plainToClass(ActionRequest, val);
      break;
    }
    case 'applicationLog': {
      newClass = plainToClass(ApplicationLog, val);
      break;
    }
    case 'location': {
      newClass = plainToClass(OrganisationLocalLocation, val);
      break;
    }
    case 'organisation': {
      newClass = plainToClass(OrganisationValue, val);
      break;
    }
    case 'notification': {
      newClass = plainToClass(Notification, val);
      break;
    }
    case 'serviceProvider': {
      newClass = plainToClass(ServiceProvider, val);
      break;
    }
    case 'customer': {
      newClass = plainToClass(Customer, val);
      break;
    }
    case 'roleDefinition': {
      newClass = plainToClass(RoleDefinition, val);
      break;
    }
    case 'partnerConfig': {
      newClass = plainToClass(PartnerConfigValue, val);
      break;
    }
    case 'systemUser': {
      newClass = plainToClass(UserContact, val);
      break;
    }
    case 'shippingTask': {
      newClass = plainToClass(ShippingTask, val);
      break;
    }
    case 'report': {
      newClass = plainToClass(Report, val);
      break;
    }
    case 'roleConfig': {
      newClass = plainToClass(RoleConfig, val);
      break;
    }
  }

  if (newClass) {
    return newClass;
  }
}

export function checkIfDocumentNeedUpdateToVersion1(
  fetchDocument: RecordTypes
) {
  if (fetchDocument.doctype === 'formular') {
    if ('version' in fetchDocument) {
      return false;
    } else {
      return true;
    }
  }
  return false;
}

export function checkIfDocumentNeedUpdateToVersion2(
  fetchDocument: RecordTypes
) {
  if (fetchDocument.doctype === 'formular') {
    if ('version' in fetchDocument) {
      // eslint-disable-next-line
      // @ts-ignore: Unreachable code error
      const tmp = fetchDocument['version'];
      if (tmp) if (tmp < 2) return true;
    } else {
      return false;
    }
  }
  return false;
}
export function checkIfDocumentNeedUpdateToVersion3(
  fetchDocument: RecordTypes
) {
  if (fetchDocument.doctype === 'formular') {
    if ('version' in fetchDocument) {
      // eslint-disable-next-line
      // @ts-ignore: Unreachable code error
      const tmp = fetchDocument['version'];
      if (tmp) if (tmp < 3) return true;
    } else {
      return false;
    }
  }
  return false;
}

// const updateFormularToVersion1 = (formular: Formular) => {
//   formular['version'] = 1;

//   for (const section of formular.sections) {
//     for (const field of section.fields) {
//       if (field.typeName === 'gwValueTable') {
//         field.optionList = field.optionList as TableFieldValue;

//         for (const row of field.optionList.rows) {
//           for (const column of row) {
//             if (!('linkedCells' in column.correctResult)) {
//               const newLinkedCellList: LinkedCellAnswerValue[] = [];
//               // eslint-disable-next-line
//               // @ts-ignore: Unreachable code error
//               column.correctResult['linkedCells'] = newLinkedCellList;
//             }
//             if (!('format' in column)) {
//               // eslint-disable-next-line
//               // @ts-ignore: Unreachable code error
//               column['format'] = { mask: '' };
//             }
//           }
//         }
//       }
//     }
//   }
// };

// const updateFormularToVersion2 = (formular: Formular) => {
//   formular['version'] = 2;

//   for (const section of formular.sections) {
//     for (const field of section.fields) {
//       if (field.typeName === 'gwValueTable') {
//         field.optionList = field.optionList as TableFieldValue;
//         let maxCols = 0;
//         if (!('width' in field.optionList)) {
//           // eslint-disable-next-line
//           // @ts-ignore: Unreachable code error
//           field.optionList['width'] = [];
//         }
//         // field.optionList.width
//         field.optionList.rows.forEach(col => {
//           if (col.length > maxCols) maxCols = col.length;
//         });
//         for (let i = 0; i < maxCols; i++) {
//           field.optionList.width.push('300');
//         }

//         // for (const row of field.optionList.rows) {
//         // for (const column of row) {
//         // if (!('linkedCells' in column.correctResult)) {
//         //   const newLinkedCellList: LinkedCellAnswerValue[] = [];
//         //   // eslint-disable-next-line
//         //   // @ts-ignore: Unreachable code error
//         //   column.correctResult['linkedCells'] = newLinkedCellList;
//         // }
//         // if (!('format' in column)) {
//         //   // eslint-disable-next-line
//         //   // @ts-ignore: Unreachable code error
//         //   column['format'] = { mask: '' };
//         // }
//         // }
//         // }
//       }
//     }
//   }
// };
// const updateFormularToVersion3 = (formular: Formular) => {
//   formular['version'] = 3;

//   for (const section of formular.sections) {
//     for (const field of section.fields) {
//
//       if (field.typeName === 'gwValueTable') {
//         field.optionList = field.optionList as TableFieldValue;

//         for (const row of field.optionList.rows) {
//           for (const col of row) {
//             col.format['placeholder'] = '';
//           }
//         }
//       }
//     }
//   }
// };

// export function checkIfDocumentNeedUpdate2(fetchDocument: OldRecordTypes) {
//   if (fetchDocument.doctype === 'formular') {
//     const editDocument = fetchDocument as OldFormular;
//     const newDocument = new Formular();

//     if ('description' in fetchDocument) {
//     } else {
//       newDocument._id = editDocument._id;
//       newDocument._rev = editDocument._rev;
//       newDocument.label = editDocument.label;
//       newDocument.formtype = editDocument.formtype;
//       newDocument.icon = editDocument.icon;

//       const newSectionList: FormSection[] = [];
//       for (const oldSection of editDocument.sections) {
//         const newSection = new FormSection(oldSection.name, []);

//         for (const oldField of oldSection.fields) {
//           if (oldField.typeName === 'checkbox') {
//             const newFieldOptionList = new CheckboxFieldValue();
//             oldField.optionList.forEach(oldOpt => {
//               newFieldOptionList.options.push({
//                 label: oldOpt.label,
//                 result: false,
//                 correctResult: false
//               });
//             });
//             const newField = new FormFieldType(
//               oldField.label,
//               oldField.icon,
//               'gwCheckboxField',
//               oldField.required,
//               newFieldOptionList,
//               oldField.checkRelevant,
//               [],
//               ''
//             );
//             newSection.fields.push(newField);
//           }
//           if (oldField.typeName === 'text') {
//             const newFieldOptionList = new TextFieldValue();
//             oldField.optionList.forEach(oldOpt => {
//               newFieldOptionList.text += oldOpt.label + ' ';
//             });
//             const newField = new FormFieldType(
//               oldField.label,
//               oldField.icon,
//               'gwTextField',
//               oldField.required,
//               newFieldOptionList,
//               oldField.checkRelevant,
//               [],
//               ''
//             );
//             newSection.fields.push(newField);
//           }
//           if (oldField.typeName === 'radio') {
//             const newFieldOptionList = new RadioFieldValue();

//             oldField.optionList.forEach(oldOpt => {
//               if (typeof oldOpt.value === 'string')
//                 newFieldOptionList.options.push(oldOpt.value);
//             });

//             const newField = new FormFieldType(
//               oldField.label,
//               oldField.icon,
//               'gwRadioField',
//               oldField.required,
//               newFieldOptionList,
//               oldField.checkRelevant,
//               [],
//               ''
//             );
//             newSection.fields.push(newField);
//           }
//         }
//         newSectionList.push(newSection);
//       }
//       newDocument.sections = newSectionList;
//     }

//     // return editDocument;
//     return newDocument;
//   }

//   // return fetchDocument;
// }

// export function updateFormularDocument(fetchDocument: RecordTypes) {

// }

///testing
// if (newClass instanceof Formular) {
//   //
//   // let sectionList: FormSection[] = [];
//   // newClass.sections.forEach(sec => {
//   //   let fieldList: FormFieldType[] = [];
//   //   sec.fields.forEach(field => {
//   //       fieldList.push(new FormFieldType(field.label,field.icon,field.typeName,field.required,field.));
//   //   })
//   //   sectionList.push(new FormSection(sec.name, sec.fields));
//   // });
//   // newClass.sections = sectionList;
//   // if (newClass.sections[0] instanceof FormSection) {
//   //
//   // }
//   //
// }
// for (let i = 0; i < editDocument.sections.length; i++) {
//   const section = editDocument.sections[i];

//
//   const newFieldList: FormFieldType[] = [];

//   for (let j = 0; j < section.fields.length; j++) {
//     const field = section.fields[j];

//
//     switch (field.typeName) {
//       case 'checkbox':
//         const newCheckboxField = new FormFieldType(
//           field.label,
//           field.icon,
//           'gwCheckboxField',
//           field.required,
//           new CheckboxFieldValue(),
//           field.checkRelevant,
//           [],
//           ''
//         );

//         if (newCheckboxField.optionList instanceof CheckboxFieldValue) {
//
//
//           const newCheckboxOptions: CheckboxFieldOptionsValue[] = [];

//           field.optionList.forEach(element => {
//

//             newCheckboxOptions.push({
//               label: element.label,
//               result: false,
//               correctResult: false
//             });
//           });
//

//           newCheckboxField.optionList.options = newCheckboxOptions;
//         }

//         newFieldList.push(newCheckboxField);
//         break;
//       case 'text':
//         const newTextField = new FormFieldType(
//           field.label,
//           field.icon,
//           'gwTextField',
//           field.required,
//           new TextFieldValue(),
//           false,
//           [],
//           ''
//         );
//

//         if (newTextField.optionList instanceof TextFieldValue) {
//           let newText = '';
//           field.optionList.forEach(tt => {
//             //

//             newText += tt.label + '';
//           });
//           // newTextField.optionList.text =
//           //   field.optionList[0].label + ' ' + field.optionList[1].label;

//           newTextField.optionList.text = newText;
//         }
//         newFieldList.push(newTextField);
//         break;
//       case 'radio':
//         const newRadioField = new FormFieldType(
//           field.label,
//           field.icon,
//           'gwRadioField',
//           field.required,
//           new RadioFieldValue(),
//           false,
//           [],
//           ''
//         );

//         if (newRadioField.optionList instanceof RadioFieldValue) {
//
//
//           const newRadioOptions: string[] = [];

//           field.optionList.forEach(gz => {
//
//             newRadioOptions.push(gz.label);
//           });
//

//           newRadioField.optionList.options = newRadioOptions;
//         }
//         newFieldList.push(newRadioField);

//         break;
//     }
//   }
//
//   // editDocument.sections[i].fields = newFieldList;
//   newDocument.sections[i].fields = newFieldList;
// }
