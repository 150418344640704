import { PublicOrganisationSettings } from './../interfaces/Settings.Interface';
import { i18n } from 'src/boot/i18n';

import { ScheduledJob } from './../interfaces/ScheduledJobs.Interface';
import {
  ServiceProviderMinCounter,
  ServiceProvidedWarningTimeCounter,
} from './../interfaces/Shipping.Interface';

import axios from 'axios';
import store from 'src/store/directStore';
import { Notify } from 'quasar';

//interfaces
import {
  RecordTypes,
  SamePeriodPartnerCheckList,
} from 'src/interfaces/Common.Interface';
//configs
import { apiServer } from 'src/config/serverConfig';
import { CreateDocumentResponse } from 'src/interfaces/Request.Interface';
import { Device } from 'src/interfaces/Device.Interface';
import { createClass } from 'src/utils/debug';
import { OrganisationValue } from 'src/interfaces/Settings.Interface';
import { plainToClass } from 'class-transformer';
import { PublicServiceRequest } from 'src/interfaces/Action.Interface';

export const useRequestHandling = () => {
  const instance = axios.create({
    baseURL: `${apiServer}`,
    // timeout: 1000,
    withCredentials: true,
    validateStatus: (status: number) => {
      switch (status) {
        default:
          return true;
          break;
      }
    },
  });

  const getScheduledJobs = async () => {
    const response = await instance.get<ScheduledJob[]>('/scheduledJob');

    const tmp = plainToClass(ScheduledJob, response.data);

    return tmp;
  };
  const refreshOrganisation = async () => {
    const response = await instance.get<OrganisationValue>('/organisation');
    const tmp = plainToClass(OrganisationValue, response.data);

    store.commit.deviceAdmin.setOrganisation(tmp);
  };

  const getDoctypeList = async () => {
    const response = await instance.get<string[]>('/control/doctypes');
    return response.data;
  };

  const refreshRecordList = async (doctype: string) => {
    console.log('refreshRecordList', doctype);

    const response = await instance.get<RecordTypes[]>(`/${doctype}`);

    const list: RecordTypes[] = [];
    for (const record of response.data) {
      const newInstance = createClass(record.doctype, record);
      if (newInstance !== undefined) list.push(newInstance);
    }

    store.commit.deviceAdmin.setList({ list: list, name: doctype + 'List' });
    return response;
  };

  const createDocument = async (record: RecordTypes) => {
    console.log('createDocument', record);

    const res = await instance.post<CreateDocumentResponse>(
      `/${record.doctype}`,
      {
        record: record,
      }
    );
    return res.data;
  };

  const getDocument = async (recordId: string, doctype: string) => {
    const response = await instance.get<RecordTypes>(`/${doctype}/${recordId}`);

    console.log('getDocument', response.data);

    const parsedRecord = createClass(doctype, response.data);
    return parsedRecord;
  };

  const getDocumentList = async <T>(doctype: string) => {
    const response = await instance.get<RecordTypes[]>(`/${doctype}`);

    const list: RecordTypes[] = [];

    for (const document of response.data) {
      const newInstance = createClass(doctype, document);
      if (newInstance !== undefined) list.push(newInstance);
    }

    return list as unknown as T[];
  };

  const updateDocument = async (record: RecordTypes, showNotifiy = true) => {
    console.log('updateDocument', record);

    const res = await instance.put(`/${record.doctype}`, {
      record: record,
    });

    if (record.doctype === 'organisation') {
      await refreshOrganisation();
    }

    if (showNotifiy) {
      createNotifiy(
        i18n.t(`doctype.${record.doctype}`).toString() + ' aktualisiert',
        1
      );
    }

    return res;
  };

  const deleteDocument = async (
    id: string,
    doctype: string,
    showNotifiy = true
  ) => {
    try {
      await instance.delete(`${doctype}/${id}`);
      if (showNotifiy) {
        createNotifiy(
          (i18n.t(`doctype.${doctype}`) as string) + ' erfolgreich gelöscht',
          1
        );
      }
      return true;
    } catch (error) {
      if (showNotifiy)
        createNotifiy('Ein Fehler beim löschen ist aufgetreten', 2);
      return false;
    }
  };

  const getPublicDevice = async (databaseName: string, documentId: string) => {
    const res = await instance.get<Device>(
      `/device/public/${databaseName}/${documentId} `
    );

    return res.data;
  };

  const getPublicActionRequest = async (orga: string, actionReqId: string) => {
    const response = await instance.get<PublicServiceRequest>(
      `/actionRequest/publicServiceRequest/${orga}/${actionReqId}`
    );

    console.log(response);
    const parsed = plainToClass(PublicServiceRequest, response.data);
    console.log(parsed);

    if (parsed instanceof PublicServiceRequest) {
      return parsed;
    } else {
      return response.status;
    }
  };

  const getPublicOrganisationSettings = async (orga: string) => {
    const response = await instance.get<PublicOrganisationSettings>(
      `/organisation/public/${orga}/`
    );
    console.log('getPublicOrganisationSettings', response.data);

    return response.data;
  };

  const updatePublicActionRequest = async (
    serviceRequest: PublicServiceRequest,
    formResult?: boolean
  ) => {
    const response = await instance.post(
      '/actionRequest/publicServiceRequest',
      {
        record: serviceRequest,
        formularResult: formResult,
      }
    );
    return response.status;
  };

  const getFile = async (filename: string) => {
    const result = await instance.post(apiServer + '/get/file', {
      // auth: store.state.deviceAdmin.auth.token,
      filename: filename,
    });
    return result;
  };

  const createNotifiy = (message: string, type: number) => {
    let bgColor = 'info';
    let textColor = 'white';
    let icon = 'info';
    switch (type) {
      case 1:
        bgColor = 'positive';
        textColor = 'black';
        icon = 'done';
        break;
      case 2:
        bgColor = 'negative';
        textColor = 'white';
        icon = 'warning';
        break;
    }
    Notify.create({
      message: message,
      color: bgColor,
      textColor: textColor,
      icon: icon,
      position: 'top',
    });
  };

  const startHandleActionRequest = async (documentId: string) => {
    const res = await instance.get<RecordTypes>(
      `/actionRequest/${documentId}/startHandle`
    );
    return res.data;
  };

  const startCheckDevice = async (documentId: string) => {
    const res = await instance.get<RecordTypes>(
      `/device/${documentId}/startCheck`
    );
    return res.data;
  };

  const startCheckShippingWarning = async () => {
    const res = await instance.get<RecordTypes>('/device/checkShipping');
    return res.data;
  };

  // HELPER REQUESTS
  const getPartnerWithCheckInSamePeriod = async (
    recordId: string,
    checkDefinitionId: string
  ) => {
    try {
      const response = await instance.get<SamePeriodPartnerCheckList>(
        `/device/${recordId}/${checkDefinitionId}/getPartnerWithCheckInSamePeriod`
      );

      const list: SamePeriodPartnerCheckList = response.data;

      if (response.status === 404) return undefined;

      console.log('getPartnerWithCheckInSamePeriod', list);

      return list;
    } catch (error) {
      return undefined;
    }
  };

  const deviceHaveValidDeliveryCheck = async (deviceId: string) => {
    const response = await instance.get<boolean>(
      `/device/${deviceId}/haveValidDeliveryCheck`
    );

    console.log('getPartnerWithCheckInSamePeriod', response.data);

    return response.data;
  };

  const resetPassword = async (mail: string) => {
    const response = await instance.get('/auth/reset-password/' + mail);
    return response.status;
  };

  const getMinDeviceCounterForServiceProvider = async () => {
    const res = await instance.get<ServiceProviderMinCounter>(
      '/serviceProvider/minDeviceCounter'
    );

    return res.data;
  };

  const getWarningTimeForServiceProvider = async () => {
    const res = await instance.get<ServiceProvidedWarningTimeCounter>(
      '/serviceProvider/warningTimeCounter'
    );
    console.log('getWarningTimeForServiceProvider', res.data);

    return res.data;
  };

  const getServiceRequestLink = async (actionRequestId: string) => {
    const res = await instance.get<string>(
      '/control/service-request/' + actionRequestId
    );
    console.log('getServiceRequestLink', res.data);

    return res.data;
  };

  const createReport = async () => {
    const res = await instance.get('/control/createReport');
    return res.status;
  };

  const sentMailAgain = async (
    actionRequestId: string,
    type: string,
    name: string
  ) => {
    const url =
      '/control/sent-mail-again/' + actionRequestId + '/' + type + '/' + name;

    const res = await instance.get(url);

    console.log('res.status', res.status);
    switch (res.status) {
      case 200:
        Notify.create({
          message: 'Mail erfolgreich gesendet',
          color: 'positive',
          textColor: 'black',
          icon: 'done',
          position: 'top',
        });
        break;

      default:
        Notify.create({
          message: 'Mail konnte nicht gesendet werden',
          color: 'negative',
          textColor: 'white',
          icon: 'warning',
          position: 'top',
        });
        break;
    }
    console.log('sentMailAgain', res.data);
  };

  const getOldDevice = async (oldId: string) => {
    const res = await instance.get<string>('/control/old-qr-code/' + oldId);
    console.log('getOldDevice', res.data);

    return res.data;
  };

  return {
    createDocument,
    createReport,
    // createDocument2,
    getDocument,
    getDocumentList,
    updateDocument,
    deleteDocument,
    getPublicDevice,
    getPublicActionRequest,
    getPublicOrganisationSettings,
    updatePublicActionRequest,
    refreshRecordList,
    refreshOrganisation,
    getDoctypeList,
    getFile,
    createNotifiy,
    startHandleActionRequest,
    startCheckDevice,
    startCheckShippingWarning,
    getPartnerWithCheckInSamePeriod,
    resetPassword,
    getMinDeviceCounterForServiceProvider,
    getWarningTimeForServiceProvider,
    getServiceRequestLink,
    sentMailAgain,
    deviceHaveValidDeliveryCheck,
    getOldDevice,
    getScheduledJobs,
  };
};
