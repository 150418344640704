import { Type } from 'class-transformer';
import 'reflect-metadata';
// interfaces
import { BaseRecord, AssignedValue } from './Common.Interface';

export class ReportValue {
  type: string;
  deviceId: string;
  checkDefinitionId: string;
  @Type(() => Date)
  checkDate?: Date;
  checkUID?: string | number;
  @Type(() => AssignedValue)
  assignedTo?: AssignedValue;
  constructor(
    type: string,
    deviceId: string,
    checkDefinitionId: string,
    checkDate?: Date,
    checkUID?: string | number,
    assignedTo?: AssignedValue
  ) {
    this.type = type;
    this.deviceId = deviceId;
    this.checkDefinitionId = checkDefinitionId;
    this.checkDate = checkDate;
    this.checkUID = checkUID;
    this.assignedTo = assignedTo;
  }
}

export class Report extends BaseRecord {
  escalationMailReceiver: string[];
  @Type(() => ReportValue)
  reports: ReportValue[];
  @Type(() => Date)
  date: Date;
  type: string;

  constructor(
    escalationMailReceiver: string[],
    reports: ReportValue[],
    date: Date,
    type: string
  ) {
    super('report');

    this.escalationMailReceiver = escalationMailReceiver;
    this.reports = reports;
    this.date = date;
    this.type = type;
  }
}
