import { OptionValue } from './../../interfaces/Common.Interface';
import Vue from 'vue';
import axios from 'axios';
//interfaces
import { DeviceType } from 'src/interfaces/Device.Interface';
import {
  ComponentSettings,
  User,
  UserContact,
} from 'src/interfaces/User.Interface';
import deviceAdminStateInterface from './deviceAdminStateInterface';
import {
  RecordTypes,
  ServerConnectionStatusValue,
  ValidUser,
} from 'src/interfaces/Common.Interface';
//configs
import { apiServer } from 'src/config/serverConfig';
import { OrganisationValue } from 'src/interfaces/Settings.Interface';
import { RoleDefinition } from 'src/interfaces/Roles.Interface';

const mutation = {
  addComponentSetting(
    state: deviceAdminStateInterface,
    payload: { componentName: string; setting: ComponentSettings }
  ) {
    state.userSettings.components[payload.componentName] = payload.setting;
  },
  delteComponentSetting(
    state: deviceAdminStateInterface,
    componentName: string
  ) {
    delete state.userSettings.components[componentName];
  },
  setAuth(state: deviceAdminStateInterface, auth: ValidUser) {
    state.auth = auth;
  },
  setList(
    state: deviceAdminStateInterface,
    payload: {
      list: RecordTypes[];
      name: string;
    }
  ) {
    Vue.set(state, payload.name, payload.list);
  },
  // updateRecord(state: deviceAdminStateInterface, record: RecordTypes) {
  //   const recordList = state[record.doctype + 'List'];

  //   if (Array.isArray(recordList)) {
  //     recordList.forEach((entry, idx) => {
  //       if (typeof entry !== 'string') {
  //         if ('_id' in entry) {
  //           if (entry._id === record._id) {
  //             window.my.log('db1c', 'recordList: ', recordList);
  //             console.log('üüüüüü', record);
  //             console.log('üüüüüü', idx);

  //             Vue.set(recordList, idx, record);
  //             return;
  //           } else {
  //             window.my.log('db1c', 'not exist: ');
  //             recordList.push(record);
  //           }
  //         }
  //       }
  //     });
  //   }
  // },
  // setDeviceCounter(state: deviceAdminStateInterface, counter: number) {
  //   state.deviceCounter = counter;
  // },
  // setActionRequestList(
  //   state: deviceAdminStateInterface,
  //   actionRequestList: ActionRequest[]
  // ) {
  //   state.actionRequests = actionRequestList;
  // },
  // setDeviceTypeCounter(state: deviceAdminStateInterface, counter: number) {
  //   state.deviceTypesCounter = counter;
  // },
  // setDeviceList(state: deviceAdminStateInterface, deviceList: Device[]) {
  //   state.deviceList = deviceList;
  // },
  // setLocationList(
  //   state: deviceAdminStateInterface,
  //   locationList: OrganisationLocalLocation[]
  // ) {
  //   state.locations = locationList;
  // },

  // setDeviceTypeList(
  //   state: deviceAdminStateInterface,
  //   deviceType: DeviceType[]
  // ) {
  //   state.deviceTypeList = deviceType;
  // },
  addDeviceType(state: deviceAdminStateInterface, deviceType: DeviceType) {
    state.deviceTypeList.push(deviceType);

    axios
      .post(apiServer + '/device/add/devicetype', {
        device: deviceType,
        auth: state.auth,
      })
      .then(() => {
        state.deviceTypeList.push(deviceType);
      })
      .catch((e) => {
        throw new Error(e);
      });
  },
  // //USER
  // setUserList(state: deviceAdminStateInterface, userList: User[]) {
  //   state.userList = userList;
  // },
  // setNotificationList(
  //   state: deviceAdminStateInterface,
  //   userList: Notification[]
  // ) {
  //   state.notificationList = userList;
  // },

  addUser(state: deviceAdminStateInterface, userData: User) {
    return userData;
  },
  setSystemUserList(state: deviceAdminStateInterface, userData: UserContact[]) {
    state.systemUserList = userData;
  },
  // setRoleDefinition(
  //   state: deviceAdminStateInterface,
  //   userData: RoleDefinition
  // ) {
  //   state.roleDefinition = userData;
  // },
  // setCurrentUser(
  //   state: deviceAdminStateInterface,
  //   userData: { firstname: string; lastname: string; email: string }
  // ) {
  //   state.currentUserFirstname = userData.firstname;
  //   state.currentUserLastName = userData.lastname;
  //   state.currentUserEmail = userData.email;
  // },
  updateUser(
    state: deviceAdminStateInterface,
    payload: { documentId: string; newValue: string }
  ) {
    return payload;
  },
  changeServerStatus(
    state: deviceAdminStateInterface,
    payload: ServerConnectionStatusValue
  ) {
    state.serverStatus = payload;
  },
  // lockDocument(state: deviceAdminStateInterface, id: string) {
  //   let check = true;
  //   state.lockedDocuments.forEach((doc) => {
  //     if (doc === id) check = false;
  //   });
  //   if (check) state.lockedDocuments.push(id);
  // },
  // unlockDocument(state: deviceAdminStateInterface, id: string) {
  //   for (let i = 0; i < state.lockedDocuments.length; i++) {
  //     if (state.lockedDocuments[i] === id) state.lockedDocuments.splice(i, 1);
  //   }
  // },
  setIsLoadingState(state: deviceAdminStateInterface, val: boolean) {
    state.isLoadingDataFromServer = val;
  },

  setOrganisation(
    state: deviceAdminStateInterface,
    organisation: OrganisationValue
  ) {
    state.organisation = organisation;
  },
  setRoleDefinition(
    state: deviceAdminStateInterface,
    roleDefinition: RoleDefinition
  ) {
    state.roleDefinition = roleDefinition;
  },
  setDoctypeList(state: deviceAdminStateInterface, list: OptionValue[]) {
    state.doctypeList = list;
  },
};

export default mutation;
export interface FormChangeValue {
  sectionIndex: number;
  fieldIndex: number;
  result: string;
}
