import { CostEntry } from './Costs.Interface';
import { OutgoingCartValue, ShippingOptions } from './Shipping.Interface';
import { Type } from 'class-transformer';
//interfaces
import { CommentValue } from './Device.Interface';
import { Formular } from './Form.Interface';
import {
  BlockedDates,
  RangeTimeValue,
  ShippingSettingValue,
} from './Settings.Interface';
import { AddressValue } from './User.Interface';
import { CarriedFromValue, BaseRecord } from './Common.Interface';

export class PublicServiceRequest {
  actionRequestId: string;
  databaseName: string;
  organisationName: string;
  type: string;
  status: string;
  @Type(() => Date)
  created: Date;
  command: string;
  deviceInventoryNumber: string;
  deviceTypeName: string;
  checkDefinitionName: string;
  @Type(() => Date)
  dueOn: Date;
  @Type(() => Formular)
  formular: Formular;
  inspector: string;
  @Type(() => SelectedShippingDates)
  shippingDate: SelectedShippingDates[];
  blockedDates: BlockedDates;
  countryCode: string;
  shippingDatesCounter: number;
  shippingDatesType: string;
  shippingMode: string;
  @Type(() => ShippingSettingValue)
  blockedDatesConfig: ShippingSettingValue;
  shippingAddress?: AddressValue;
  shippingOptions: ShippingOptions[] = [];
  constructor(
    actionRequestId: string,
    databaseName: string,
    organisationName: string,
    type: string,
    status: string,
    created: Date,
    command: string,
    deviceInventoryNumber: string,
    deviceTypeName: string,
    checkDefinitionName: string,
    dueOn: Date,
    formular: Formular,
    inspector: string,
    shippingDate: SelectedShippingDates[],
    blockedDates: BlockedDates,
    countryCode: string,
    shippingDatesCounter = -1,
    shippingDatesType = 'normal',
    shippingMode: string,
    blockedDatesConfig: ShippingSettingValue,
    shippingAddress?: AddressValue
  ) {
    this.actionRequestId = actionRequestId;
    this.databaseName = databaseName;
    this.organisationName = organisationName;
    this.type = type;
    this.status = status;
    this.created = created;
    this.command = command;
    this.deviceInventoryNumber = deviceInventoryNumber;
    this.deviceTypeName = deviceTypeName;
    this.checkDefinitionName = checkDefinitionName;
    this.dueOn = dueOn;
    this.formular = formular;
    this.inspector = inspector;
    this.shippingDate = shippingDate;
    this.blockedDates = blockedDates;
    this.countryCode = countryCode;
    this.shippingDatesCounter = shippingDatesCounter;
    this.shippingDatesType = shippingDatesType;
    this.shippingMode = shippingMode;
    this.blockedDatesConfig = blockedDatesConfig;
    this.shippingAddress = shippingAddress;
  }
}

export abstract class CommandValue {
  type: string;
  constructor(type: string) {
    this.type = type;
  }
}
export class SentReminderValue {
  name: string;
  @Type(() => Date)
  date: Date;
  constructor(name: string, date = new Date()) {
    this.name = name;
    this.date = date;
  }
}

export class SelectedShippingDates {
  @Type(() => Date)
  date: Date;
  // @Type(() => Date)
  // specificTime: Date;
  @Type(() => RangeTimeValue)
  timeRange: RangeTimeValue;
  customOption: string[];
  type: string; //which property to look
  consider: string; //use only time or day or both from date
  constructor(
    date = new Date(),
    // specificTime: Date,
    timeRange: RangeTimeValue = new RangeTimeValue(),
    customOption: string[] = [],
    type = 'normal',
    consider = 'dateTime'
  ) {
    this.date = date;
    // this.specificTime = specificTime;
    this.timeRange = timeRange;
    this.customOption = customOption;
    this.type = type;
    this.consider = consider;
  }
}

export class ServiceRequestValue extends CommandValue {
  deviceId: string;
  checkDefinitionId: string;
  formularId: string;
  @Type(() => CarriedFromValue)
  carriedFrom: CarriedFromValue;
  @Type(() => Date)
  checkDate: Date;
  @Type(() => SentReminderValue)
  sentReminders: SentReminderValue[];
  @Type(() => SelectedShippingDates)
  shippingDate?: SelectedShippingDates[];
  filledFormular?: Formular;
  shippingAddress?: AddressValue;
  affectedPartnerDevices?: string[];

  constructor(
    deviceId: string,
    checkDefId: string,
    formularId: string,
    carriedFrom: CarriedFromValue,
    checkDate: Date,
    sentReminders: SentReminderValue[],
    shippingDate: SelectedShippingDates[] = [],
    filledFormular: Formular | undefined = undefined,
    shippingAddress?: AddressValue,
    affectedPartnerDevices: string[] = []
  ) {
    super('normalCheck');
    this.deviceId = deviceId;
    this.checkDefinitionId = checkDefId;
    this.formularId = formularId;
    this.carriedFrom = carriedFrom;
    this.checkDate = checkDate;
    this.sentReminders = sentReminders;
    this.shippingDate = shippingDate;
    this.filledFormular = filledFormular;
    this.shippingAddress = shippingAddress;
    this.affectedPartnerDevices = affectedPartnerDevices;
  }
}
export class DeliveryCheckValue extends CommandValue {
  checkDefinitionId: string;
  deviceId: string;
  //  status: string;
  formular: Formular | undefined;
  constructor(
    deviceId: string,
    checkDefId: string
    // status = 'new'
  ) {
    super('deliveryCheck');
    this.deviceId = deviceId;
    this.checkDefinitionId = checkDefId;
    // this.status = status;
  }
}

export class CmdData {
  command: string; // fill-check-form // spec-shipping-date
  @Type(() => CommandValue, {
    discriminator: {
      property: 'type',
      subTypes: [
        { value: ServiceRequestValue, name: 'normalCheck' },
        { value: DeliveryCheckValue, name: 'deliveryCheck' },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  value: ServiceRequestValue | DeliveryCheckValue;
  constructor(
    command: string,
    value: ServiceRequestValue | DeliveryCheckValue
  ) {
    this.command = command;
    this.value = value;
  }
}

export class SentMailValue {
  @Type(() => Date)
  date: Date;
  mailTemplate: string;
  receiver: string;
  success: boolean;
  manual?: boolean;
  subject?: string;
  type?: string;
  name?: string;
  contextInfo: string;
  constructor(
    date: Date,
    mailTemplate: string,
    receiver: string,
    success: boolean,
    manual = false,
    subject = '',
    type = '',
    name = '',
    contextInfo = ''
  ) {
    this.date = date;
    this.mailTemplate = mailTemplate;
    this.receiver = receiver;
    this.success = success;
    this.manual = manual;
    this.subject = subject;
    this.type = type;
    this.name = name;
    this.contextInfo = contextInfo;
  }
}

export class SentConditionalNotificationValue {
  @Type(() => Date)
  date: Date;
  receiver: {
    type: string;
    id: string;
  };
  configId: string;
  notificationType: string;
  constructor(
    date: Date,
    receiver: {
      type: string;
      id: string;
    },
    configId: string,
    notificationType: string
  ) {
    this.date = date;
    this.receiver = receiver;
    this.configId = configId;
    this.notificationType = notificationType;
  }
}

export class ActionRequestActivity {
  @Type(() => Date)
  date: Date;
  executedFromType: string;

  constructor(date: Date, executedFromType: string) {
    this.date = date;
    this.executedFromType = executedFromType;
  }
}

export class ActionRequest extends BaseRecord {
  status: string;
  @Type(() => Date)
  created: Date;
  @Type(() => Date)
  lastChange: Date;
  @Type(() => CmdData)
  command: CmdData;
  @Type(() => SentMailValue)
  sentMails?: SentMailValue[];
  @Type(() => OutgoingCartValue)
  outgoing?: OutgoingCartValue;
  @Type(() => CommentValue)
  comments?: CommentValue[];
  @Type(() => CostEntry)
  costs?: CostEntry[];
  savedCheckId?: string;
  @Type(() => SentConditionalNotificationValue)
  sentConditionalNotifications?: SentConditionalNotificationValue[];
  activity?: ActionRequestActivity[];
  constructor(
    created = new Date(),
    command: CmdData,
    lastChange = new Date(),
    sentMails: SentMailValue[] = [],
    outgoing = undefined,
    comments: CommentValue[] = [],
    costs: CostEntry[] = [],
    saveCheckId?: string,
    sentConditionalNotifications?: SentConditionalNotificationValue[],
    activity?: ActionRequestActivity[]
  ) {
    super('actionRequest');
    this.status = 'created';
    this.created = created;
    this.command = command;
    this.lastChange = lastChange;
    this.sentMails = sentMails;
    this.outgoing = outgoing;
    this.comments = comments;
    this.costs = costs;
    this.savedCheckId = saveCheckId;
    this.sentConditionalNotifications = sentConditionalNotifications;
    this.activity = activity;
  }
}

export interface ActionRequestMetaInformation {
  checkDefinitionName: string;
  deviceTypeName: string;
  checkDate: string;
  deviceUsername: string;
  inventoryNumber: string;
}

export interface ActionRequestNotificationEntry {
  receiver: string;
  date: string;
  name: string;
  type: string;
  mailTemplate: string;
  success: boolean;
  manual: boolean;
  subject: string;
}

export interface ActionRequestCustomNotificationEntry {
  receiver: string;
  date: string;
}
