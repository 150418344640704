import { Customer } from './../../interfaces/User.Interface';
import { moduleGetterContext } from '../directStore';
import deviceAdmin from './index';
import _ from 'lodash';
//interfaces
import { CheckDefinition } from 'src/interfaces/Check.Interface';
import {
  Device,
  DeviceType,
  PartnerConfigValue,
} from 'src/interfaces/Device.Interface';
import { User } from 'src/interfaces/User.Interface';
import { Formular } from 'src/interfaces/Form.Interface';
import { ActionRequest } from 'src/interfaces/Action.Interface';
import { OrganisationLocalLocation } from 'src/interfaces/Location.Interface';
import { OutgoingReceiverValue } from 'src/interfaces/Shipping.Interface';
import { AssignedValue } from 'src/interfaces/Common.Interface';

const getters = {
  // eslint-disable-next-line
  getComponentSetting(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (componentName: string, settingName: string) => {
      if (componentName in state.userSettings.components) {
        const tmp = state.userSettings.components[componentName][settingName];
        if (tmp) return tmp;
        else {
          return '';
        }
      }
    };
  },
  // eslint-disable-next-line
  getCurrentUserName(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return () => {
      if (state.auth) {
        return state.auth.firstname + ' ' + state.auth.lastname;
      } else {
        throw Error('User musst logged in');
      }
    };
  },
  // eslint-disable-next-line
  getCurrentUserId(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return () => {
      if (state.auth) {
        return state.auth.userId;
      } else {
        throw Error('User musst logged in');
      }
    };
  },
  // eslint-disable-next-line
  getCustomerById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (customerId: string): Customer | boolean => {
      let usr;

      state.customerList.forEach((customer) => {
        if (customerId === customer._id) usr = customer;
      });
      if (usr) return usr;
      // else throw Error('Error in getUserById User not found');
      else return false;
    };
  },
  // eslint-disable-next-line
  getUserById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (userId: string): User | boolean => {
      let usr;
      state.userList.forEach((user) => {
        if (userId === user._id) usr = user;
      });
      if (usr) return usr;
      // else throw Error('Error in getUserById User not found');
      else return false;
    };
  },

  // eslint-disable-next-line
  getUserNameById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (userId: string): string => {
      let name = '';
      state.userList.forEach((user) => {
        if (userId === user._id) name = user.firstname + ' ' + user.lastname;
      });
      return name;
    };
  },
  // eslint-disable-next-line
  getDeviceInventoryNumberById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (deviceId: string): string => {
      let inv = '';
      state.deviceList.forEach((device) => {
        if (deviceId === device._id) inv = device.inventoryNumber;
      });
      return inv;
    };
  },
  // eslint-disable-next-line
  getLocationById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (locationId: string): OrganisationLocalLocation | boolean => {
      let getLocation;
      state.locationList.forEach((location) => {
        if (locationId === location._id) getLocation = location;
      });
      if (getLocation) return getLocation;
      else return false;
    };
  },
  // eslint-disable-next-line
  getCustomerNameById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (customerId: string): string => {
      let name = '';
      state.customerList.forEach((customer) => {
        if (customerId === customer._id)
          name = customer.firstname + ' ' + customer.lastname;
      });
      return name;
    };
  },
  // eslint-disable-next-line
  getReceiverValueByAssignedValue(...args: any) {
    const { getters } = deviceAdminGetterContext(args);
    return (
      assigned: AssignedValue,
      useUserShippingAddress: boolean
    ): OutgoingReceiverValue => {
      const receiver = {
        name: '',
        assigned: {
          type: assigned.type,
          id: assigned.user,
        },
        address: {
          street: '',
          number: '',
          country: '',
          zip: '',
          city: '',
          state: '',
        },
      };

      const getUser = getters.getUserById(assigned.user);
      if (getUser instanceof User) {
        receiver.name = `${getUser.firstname} ${getUser.lastname}`;
        receiver.address = getUser.address;
        if (useUserShippingAddress && getUser.shippingAddress) {
          receiver.address = getUser.shippingAddress;
        }
      }
      return receiver;
    };
  },
  // eslint-disable-next-line
  getDeviceById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (deviceId: string): Device | undefined => {
      // let getDevice;
      const find = state.deviceList.find((entry) => entry._id === deviceId);
      // if (!find) console.log('----', deviceId);

      return find;
      // for (const device of state.deviceList) {

      // }
      // state.deviceList.forEach((device) => {
      //   if (deviceId === device._id) getDevice = _.cloneDeep(device);
      // });
      // if (getDevice) return getDevice;
      // // else throw Error('Error in getDeviceById Device not found');
      // else return false;
    };
  },
  // eslint-disable-next-line
  getPartnerConfigById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (partnerConfigId: string): PartnerConfigValue | boolean => {
      let getPartnerConfig;
      state.partnerConfigList.forEach((partnerConfig) => {
        if (partnerConfigId === partnerConfig._id)
          getPartnerConfig = _.cloneDeep(partnerConfig);
      });
      if (getPartnerConfig) return getPartnerConfig;
      else return false;
    };
  },
  // eslint-disable-next-line
  getDeviceByInventoryNumber(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (inventoryNumber: string): Device | boolean => {
      let getDevice;
      state.deviceList.forEach((device) => {
        if (inventoryNumber === device.inventoryNumber)
          getDevice = _.cloneDeep(device);
      });
      if (getDevice) return getDevice;
      // else throw Error('Error in getDeviceById Device not found');
      else return false;
    };
  },
  // eslint-disable-next-line
  getActionRequestById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (actionRequestId: string): ActionRequest | boolean => {
      let getActionRequest;
      state.actionRequestList.forEach((actionRequest) => {
        if (actionRequestId === actionRequest._id)
          getActionRequest = _.cloneDeep(actionRequest);
      });
      if (getActionRequest) return getActionRequest;
      else return false;
    };
  },
  // eslint-disable-next-line
  getActionRequestFromCheckDefId(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (
      deviceId: string,
      checkDefinitionId: string
    ): ActionRequest | boolean => {
      let getActionRequest;
      state.actionRequestList.forEach((actionRequest) => {
        if (actionRequest.command.value.deviceId === deviceId)
          if (
            actionRequest.command.value.checkDefinitionId === checkDefinitionId
          )
            getActionRequest = _.cloneDeep(actionRequest);
      });
      if (getActionRequest) return getActionRequest;
      else return false;
    };
  },
  // eslint-disable-next-line
  // getDeliveryCheckDefinitionFromDeviceById(...args: any) {
  //   const { state } = deviceAdminGetterContext(args);
  //   return (deviceId: string): CheckDefinition | boolean => {
  //     let getCheckDefinition;
  //

  //     let device123 = this.getDeviceById('sfssf');

  //

  //     // if (device instanceof Device) {
  //     //   let deviceType = this.getDeviceTypeById(device.type);
  //     //   if (deviceType instanceof DeviceType) {
  //     //     deviceType.checkDefinitions.forEach(checkDefId => {
  //     //       let checkDefinition = this.getCheckDefinitionById(checkDefId);
  //     //       if (checkDefinition instanceof CheckDefinition) {
  //     //         if (checkDefinition.type === 'deliveryCheck') {
  //     //           getCheckDefinition = checkDefinition;
  //     //         }
  //     //       }
  //     //     });
  //     //   }
  //     // }
  //     // state.deviceList.forEach(device => {
  //     //   if (deviceId === device._id) getCheckDefinition = _.cloneDeep(device);
  //     // });
  //     // if (getCheckDefinition) return getCheckDefinition;

  //     // else
  //     return false;
  //   };
  // },
  // eslint-disable-next-line
  getDeviceTypeById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (deviceId: string): DeviceType | boolean => {
      let getDeviceType;
      state.deviceTypeList.forEach((deviceType) => {
        if (deviceId === deviceType._id) getDeviceType = deviceType;
      });
      if (getDeviceType) return getDeviceType;
      else return false;
    };
  },
  // // eslint-disable-next-line
  // getDoctypeList(...args: any) {
  //   const { state } = deviceAdminGetterContext(args);
  //   return (doctype: string): RecordTypes[] => {
  //     let getDeviceType;
  //     returnstate[doctype]
  //     state.deviceTypeList.forEach(deviceType => {
  //       if (deviceId === deviceType._id) getDeviceType = deviceType;
  //     });
  //     if (getDeviceType) return getDeviceType;
  //     else return false;
  //   };
  // },
  // eslint-disable-next-line
  getDeviceTypeLabelById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (deviceId: string): string => {
      let getDeviceType = '';
      state.deviceTypeList.forEach((deviceType) => {
        if (deviceId === deviceType._id) getDeviceType = deviceType.label;
      });
      return getDeviceType;
    };
  },
  // eslint-disable-next-line
  getCheckDefinitionById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (checkDefinitionId: string): CheckDefinition | boolean => {
      let getCheckDefinition;
      state.checkDefinitionList.forEach((checkDef) => {
        if (checkDefinitionId === checkDef._id) getCheckDefinition = checkDef;
      });
      if (getCheckDefinition) return getCheckDefinition;
      else return false;
    };
  },
  // eslint-disable-next-line
  getCheckDefinitionNameById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (checkDefinitionId: string): string => {
      let getCheckDefinitionName = '';
      state.checkDefinitionList.forEach((checkDefinition) => {
        if (checkDefinitionId === checkDefinition._id)
          getCheckDefinitionName = checkDefinition.name;
      });
      return getCheckDefinitionName;
    };
  },
  // eslint-disable-next-line
  getFormularById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (formularId: string): Formular | boolean => {
      let getFormular;
      state.formularList.forEach((formular) => {
        if (formularId === formular._id) getFormular = formular;
      });
      if (getFormular) return _.cloneDeep(getFormular);
      else return false;
    };
  },
  // eslint-disable-next-line
  getFormularLabelById(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (formularId: string): string => {
      let getFormularLabel = '';
      state.formularList.forEach((formular) => {
        if (formularId === formular._id) getFormularLabel = formular.label;
      });
      return getFormularLabel;
    };
  },
  // eslint-disable-next-line
  getLocationByName(...args: any) {
    const { state } = deviceAdminGetterContext(args);
    return (locationName: string): OrganisationLocalLocation | undefined => {
      const find = state.locationList.find(
        (entry) => entry.name === locationName
      );
      if (find) return find;
      else return undefined;
    };
  },
};

export default getters;

// eslint-disable-next-line
const deviceAdminGetterContext = (args: [any, any, any, any]) =>
  moduleGetterContext(args, deviceAdmin);
