




























import { defineComponent, ref } from '@vue/composition-api';
import _ from 'lodash';
//components
import gwRadioField from 'src/components/formular/fieldComponents/gwRadioField/gwRadioField.vue';
import gwCheckboxField from 'src/components/formular/fieldComponents/gwCheckboxField/gwCheckboxField.vue';
import gwValueTable from 'src/components/formular/fieldComponents/gwValueTable/gwValueTable.vue';
import gwTextField from 'src/components/formular/fieldComponents/gwTextField/gwTextField.vue';
//interfaces
import { FieldOptionValue, FormFieldType } from 'src/interfaces/Form.Interface';

export default defineComponent({
  name: 'gwFieldAnswerCard',
  components: {
    gwRadioField,
    gwCheckboxField,
    gwValueTable,
    gwTextField
  },
  props: {
    value: {
      type: Object as () => FormFieldType,
      required: true
    }
  },

  setup(props, context) {
    const editField = ref<FormFieldType>();
    editField.value = _.cloneDeep(props.value);

    const changeFieldValue = (value: FieldOptionValue) => {
      if (editField.value) editField.value.optionList = value;
    };

    const abort = () => {
      context.emit('abort');
    };
    const submit = () => {
      context.emit('submit', editField.value?.optionList);
    };

    return {
      editField,
      changeFieldValue,
      abort,
      submit
    };
  }
});
