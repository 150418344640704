



















































































import { defineComponent, ref } from '@vue/composition-api';
//components
//interfaces

import { NavigationConfigValue } from 'src/interfaces/Common.Interface';
import VueI18n from 'vue-i18n';

export default defineComponent({
  name: 'gwTabCard',
  components: {},
  props: {
    tabs: {
      type: Array as () => NavigationConfigValue[],
      required: true,
    },
    submitButtonText: {
      type: String,
      default: 'submit',
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    showToolbar: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    showSubmitButton: {
      type: Boolean,
      default: true,
    },
    disableSubmitButton: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const onSubmit = () => {
      context.emit('submit');
    };

    const navigationActive = ref<string | VueI18n.TranslateResult>(
      props.tabs[0].label
    );

    const onClickCloseButton = () => {
      context.emit('close');
    };

    return {
      onSubmit,
      navigationActive,
      onClickCloseButton,
    };
  },
});
