// export const gwDebug = () => {};
// export type gwDebug = customDebugFunction;

// const customDebugFunction = () => {

// };
export interface gwDebugger {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  log(arg1: string, arg2: string, ...data: any[]): void;
}

export interface LogType {
  color: string;
  bgColor: string;
  indent: string;
  textStart: string;
  textEnd: string;
}

export interface LogTypes {
  [key: string]: LogType;
}
const logtypes: LogTypes = {
  db1: {
    color: 'ffffff',
    bgColor: '0066ff',
    indent: '|-',
    textStart: '==== T1 ',
    textEnd: ' ===='
  },
  db1c: {
    color: 'ffffff',
    bgColor: '4d94ff',
    indent: '|-',
    textStart: '-- ',
    textEnd: ''
  },
  db2: {
    color: 'ffffff',
    bgColor: '008060',
    indent: '  |-',
    textStart: '==== T2 ',
    textEnd: ' ===='
  },
  db2c: {
    color: '000000',
    bgColor: '00cc99',
    indent: '  |-',
    textStart: '## ',
    textEnd: ''
  },
  db3: {
    color: 'ffffff',
    bgColor: 'ff3399',
    indent: '    |-',
    textStart: '==== T3 ',
    textEnd: ' ===='
  },
  db3c: {
    color: 'ffffff',
    bgColor: 'ff80bf',
    indent: '    |-',
    textStart: '++ ',
    textEnd: ''
  },
  db4: {
    color: 'ffffff',
    bgColor: '990033',
    indent: '      |-',
    textStart: '==== T4 ',
    textEnd: ' ===='
  },
  db4c: {
    color: 'ffffff',
    bgColor: 'e6004c',
    indent: '      |-',
    textStart: '-- ',
    textEnd: ''
  },
  db5: {
    color: 'ffffff',
    bgColor: '003366',
    indent: '        |-',
    textStart: '===== T5 ',
    textEnd: ' ====='
  },
  db5c: {
    color: 'ffffff',
    bgColor: '004d99',
    indent: '        |-',
    textStart: '~~ ',
    textEnd: ''
  },
  dbs: {
    color: 'ffffff',
    bgColor: '006600',
    indent: '|-',
    textStart: '~~ ',
    textEnd: ''
  },
  dbe: {
    color: 'ffffff',
    bgColor: 'ff0000',
    indent: '|-',
    textStart: '~~ ',
    textEnd: ''
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function log(type: string, title: string, ...arg: any[]) {
  const config: LogType = logtypes[type];

  console.log(
    config.indent + '%c' + config.textStart + title + config.textEnd,
    'background: #' + config.bgColor + '; color: #' + config.color + ';',
    ...arg
  );
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
// eslint-disable-next-line no-console
