



















































































import { defineComponent, ref } from '@vue/composition-api';
import store from 'src/store/directStore';
import _ from 'lodash';
// import Vue from 'vue';
//components
//interfaces
import { CommentValue } from 'src/interfaces/Device.Interface';
//confígs
import { dateFormat } from 'src/config/settingsConfig';
//compositions
import { User } from 'src/interfaces/User.Interface';

export default defineComponent({
  name: 'CommentList',
  components: {},
  props: {
    value: {
      type: Array as () => CommentValue[],
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const showDeleteDialog = ref<boolean>(false);
    const showNewCommentDialog = ref<boolean>(false);
    const newComment = ref<CommentValue>();
    const deleteIdx = ref<number>();

    const getAutorUserNameShortcode = (userId: string) => {
      const getUser = store.getters.deviceAdmin.getUserById(userId);
      if (getUser instanceof User) {
        const usernameShortCode = `${getUser.firstname.charAt(
          0
        )}${getUser.lastname.charAt(0)}`;
        console.log('usernameShortCode', usernameShortCode);

        return usernameShortCode;
      }
    };

    const toggleNewComment = () => {
      newComment.value = new CommentValue(
        new Date(),
        store.getters.deviceAdmin.getCurrentUserId()
      );
      showNewCommentDialog.value = true;
    };

    const saveNewComment = () => {
      if (newComment.value) {
        const editCommentList = _.cloneDeep(props.value);
        editCommentList.unshift(newComment.value);
        showNewCommentDialog.value = false;
        context.emit('input', editCommentList);
      }
    };

    const toggleDeleteComment = (idx: number) => {
      deleteIdx.value = idx;
      showDeleteDialog.value = true;
    };

    const deleteComment = () => {
      console.log('deleteComment', deleteIdx.value);

      if (deleteIdx.value !== undefined) {
        const editCommentList = _.cloneDeep(props.value);
        editCommentList.splice(deleteIdx.value, 1);
        context.emit('input', editCommentList);
        showDeleteDialog.value = false;
      }
    };

    return {
      showDeleteDialog,
      showNewCommentDialog,
      newComment,
      dateFormat,
      getAutorUserNameShortcode,
      toggleNewComment,
      saveNewComment,
      toggleDeleteComment,
      deleteComment,
    };
  },
});
