





























































































import { defineComponent, ref } from '@vue/composition-api';
// components
// interfaces
import {
  ActionRequestCustomNotificationEntry,
  ActionRequestNotificationEntry,
} from 'src/interfaces/Action.Interface';
// compositions

export default defineComponent({
  name: 'BasicSentNotificationList',
  props: {
    list: {
      type: Array as () => ActionRequestNotificationEntry[],
      required: true,
    },
    customList: {
      type: Array as () => ActionRequestCustomNotificationEntry[],
      required: true,
    },
    showSendAgainButton: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const tab = ref<string>('systemNotifications');

    const onClickSentMailAgain = (type: string, name: string) => {
      context.emit('send-again', type, name);
    };

    return {
      // ===== interfaces =========
      // ===== stores =============
      // ===== compositions =======
      // ===== self ===============
      tab,
      onClickSentMailAgain,
    };
  },
});
