


























import { defineComponent, ref } from '@vue/composition-api';

// components
// interfaces
import { OptionValue } from 'src/interfaces/Common.Interface';
import { ExtendInterval } from 'src/interfaces/Settings.Interface';
// compositions

export default defineComponent({
  name: 'IntervalPicker',
  props: {
    value: {
      type: Object as () => ExtendInterval,
      required: true,
    },
  },

  setup() {
    const typeOptions = ref<OptionValue[]>([
      new OptionValue('weekly', 'Wöchentlich'),
    ]);
    const dayOptions = ref<OptionValue[]>([
      new OptionValue(1, 'Montag'),
      new OptionValue(2, 'Dienstag'),
      new OptionValue(3, 'Mittwoch'),
      new OptionValue(4, 'Donnerstag'),
      new OptionValue(5, 'Freitag'),
      new OptionValue(6, 'Samstag'),
      new OptionValue(0, 'Sonntag'),
    ]);

    return {
      // ===== interfaces =========
      // ===== stores =============
      // ===== compositions =======
      // ===== self ===============
      typeOptions,
      dayOptions,
    };
  },
});
