


























import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'gwFormularList',
  components: {},
  props: {},
  setup(props, context) {
    // context.root.$store.direct.dispatch.deviceAdmin
    //   .refreshFormulars()
    //   .catch(e => {
    //     throw new Error(e);
    //   });
    const localFormularList = computed(() => {
      return context.root.$store.direct.state.deviceAdmin.formularList;
    });
    return { localFormularList };
  }
});
