





































import { defineComponent, ref, watch, computed } from '@vue/composition-api';
//components
//interfaces

import { NavigationConfigValue } from 'src/interfaces/Common.Interface';

export default defineComponent({
  name: 'gwTabCardHorizontal',
  components: {},
  props: {
    tabs: {
      type: Array as () => NavigationConfigValue[],
      required: true
    },
    submitButtonText: {
      type: String,
      default: 'submit'
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    showToolbar: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    activeTab: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const onSubmit = () => {
      context.emit('submit');
    };

    const navigationActive = ref<string>(props.tabs[0].name);
    if (props.activeTab !== '') navigationActive.value = props.activeTab;

    watch(
      () => props.activeTab,
      () => {
        navigationActive.value = props.activeTab;
      }
    );

    const onClickCloseButton = () => {
      context.emit('close');
    };

    const onClickBack = () => {
      for (let i = 0; i < props.tabs.length; i++) {
        if (props.tabs[i].name === navigationActive.value)
          navigationActive.value = props.tabs[i - 1].name;
      }
      context.emit('change-tab', navigationActive.value);
    };

    const showBackButton = computed(() => {
      for (let i = 0; i < props.tabs.length; i++) {
        if (props.tabs[i].name === navigationActive.value)
          if (i !== 0) return true;
          else return false;
      }
    });

    const changeTabPanel = () => {
      context.emit('change-tab', navigationActive.value);
    };

    return {
      onSubmit,
      navigationActive,
      onClickCloseButton,
      onClickBack,
      showBackButton,
      changeTabPanel
    };
  }
});
