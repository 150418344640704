import { IntervallValue, BaseRecord } from './Common.Interface';

export class UserBase extends BaseRecord {
  doctype: string;
  firstname: string;
  lastname: string;
  address: AddressValue;
  shippingAddress?: AddressValue;
  email: string;
  phone: string;
  gender: string;
  status: number;
  constructor(
    doctype: string,
    firstname = '',
    lastname = '',
    address = {
      street: '',
      number: '',
      country: '',
      zip: '',
      city: '',
      state: '',
    },
    email = '',
    phone = '',
    gender = '',
    status = 1,
    shippingAddress?: AddressValue
  ) {
    super(doctype);
    this.doctype = doctype;
    this.firstname = firstname;
    this.lastname = lastname;
    this.address = address;
    this.email = email;
    this.phone = phone;
    this.gender = gender;
    this.status = status;
    this.shippingAddress = shippingAddress;
  }
}

export class User extends UserBase {
  [key: string]: string | string[] | undefined | AddressValue | number;
  deviceIds: string[];
  supervisor: string; //mail or userID

  constructor(
    firstname = '',
    lastname = '',
    address = {
      street: '',
      number: '',
      country: '',
      zip: '',
      city: '',
      state: '',
    },
    email = '',
    deviceIds: string[] = [],
    supervisor = ''
  ) {
    super('user', firstname, lastname, address, email, '', '', 1);
    this.deviceIds = deviceIds;
    this.supervisor = supervisor;
  }
}

export class Customer extends UserBase {
  limitedToBelongDefinition: string;
  identificationNumber: string;
  constructor(
    firstname = '',
    lastname = '',
    address = {
      street: '',
      number: '',
      country: '',
      zip: '',
      city: '',
      state: '',
    },
    email = '',
    phone = '',
    gender = '',
    limitedToBelongDefinition = '',
    identificationNumber = ''
  ) {
    super('customer', firstname, lastname, address, email, phone, gender);
    this.firstname = firstname;
    this.lastname = lastname;
    this.address = address;
    this.email = email;
    this.limitedToBelongDefinition = limitedToBelongDefinition;
    this.identificationNumber = identificationNumber;
  }
}

export class ServiceProviderWarningTimeDefinition {
  deviceType: string;
  warningTime: IntervallValue;
  minForShipping: number;
  constructor(
    deiceType: string,
    warningTime: IntervallValue = new IntervallValue(0),
    minForShipping = 0
  ) {
    this.deviceType = deiceType;
    this.warningTime = warningTime;
    this.minForShipping = minForShipping;
  }
}

export class ServiceProvider {
  _id?: string;
  _rev?: string;
  doctype = 'serviceProvider';
  name: string;
  address: AddressValue;
  email: string;
  phone: string;
  deviceTypes: string[];
  warningTime: ServiceProviderWarningTimeDefinition[];
  constructor(
    name = '',
    address = {
      street: '',
      number: '',
      country: '',
      zip: '',
      city: '',
      state: '',
    },
    email = '',
    phone = '',
    deviceTypes = [],
    warningTime = []
  ) {
    this.name = name;
    this.address = address;
    this.email = email;
    this.phone = phone;
    this.deviceTypes = deviceTypes;
    this.warningTime = warningTime;
  }
}

export class Employee extends UserBase {
  description: string;
  constructor() {
    super('employee');
    this.description = '';
  }
}

export class UserContact extends UserBase {
  firstname: string;
  lastname: string;
  gender: string;
  email: string;
  address: AddressValue;
  type: string; //ersetzt den doctype damit man weiß welche DB
  role: string;

  constructor(
    firstname = '',
    lastname = '',
    gender = '',
    email = '',
    address: AddressValue = {
      street: '',
      zip: '',
      number: '',
      city: '',
      country: '',
      state: '',
    },
    type = '',
    role = ''
  ) {
    super('systemUser');
    this.firstname = firstname;
    this.lastname = lastname;
    this.gender = gender;
    this.email = email;
    this.address = address;
    this.type = type;
    this.role = role;
  }
}

export interface AddressValue {
  [key: string]: string;
  street: string;
  number: string;
  country: string;
  zip: string;
  city: string;
  state: string;
}

export interface ComponentSettings {
  [key: string]: string;
}

export interface UserComponentSettings {
  [key: string]: ComponentSettings;
}

export interface UserSettings {
  components: UserComponentSettings;
}
