import { Type } from 'class-transformer';
import { date } from 'quasar';
import { v4 } from 'uuid';
import { CarriedFromValue } from './Common.Interface';

export class FormSectionStepDefinition {
  sectionIds: string[];
  @Type(() => CarriedFromValue)
  carriedFrom: CarriedFromValue;
  onlyVisibleForInspector: boolean;
  onlyCurrentInspectorCanEdit: boolean;
  label: string;
  constructor(
    label: string,
    sectionIds: string[],
    carriedFrom = new CarriedFromValue('self', '', ''),
    onlyVisibleForInspector = false,
    onlyCurrentInspectorCanEdit = false
  ) {
    this.label = label;
    this.sectionIds = sectionIds;
    this.carriedFrom = carriedFrom;
    this.onlyVisibleForInspector = onlyVisibleForInspector;
    this.onlyCurrentInspectorCanEdit = onlyCurrentInspectorCanEdit;
  }
}
export class Formular {
  _id?: string;
  _rev?: string;
  doctype = 'formular';
  version = 3;
  label: string;
  icon: string;
  @Type(() => FormSection)
  sections: FormSection[];
  formtype: string;
  description: string;
  @Type(() => FormSectionStepDefinition)
  steps: FormSectionStepDefinition[];
  currentStep: number;
  constructor(
    label = 'new formular',
    icon = '',
    sections: FormSection[] = [],
    formtype = 'check',
    description = '',
    steps: FormSectionStepDefinition[] = [],
    currentStep = -1
  ) {
    this.label = label;
    this.icon = icon;
    this.sections = sections;
    this.formtype = formtype;
    this.description = description;
    this.steps = steps;
    this.currentStep = currentStep;
  }
  resetResults() {
    return;
  }
  isCompleteFilledOut(carriedFromType?: string) {
    let completeFilledOut = true;

    if (carriedFromType) {
      const sectionIdsFromType: string[] = [];
      for (const step of this.steps) {
        if (step.carriedFrom.type === carriedFromType) {
          sectionIdsFromType.push(...step.sectionIds);
        }
      }
      for (const section of this.sections) {
        const find = sectionIdsFromType.find((entry) => entry === section.id);
        if (find) {
          for (const field of section.fields) {
            if (field.optionList instanceof TableFieldValue) {
              for (const row of field.optionList.rows) {
                for (const col of row) {
                  if (!col.requiredTest()) {
                    console.log('isCompleteFilledOut 1');

                    completeFilledOut = false;
                  }
                }
              }
            } else if (field.optionList instanceof RadioFieldValue) {
              if (field.required)
                if (field.optionList.result === '') completeFilledOut = false;
            }
          }
        }
      }
    } else {
      for (const section of this.sections) {
        for (const field of section.fields) {
          if (field.optionList instanceof TableFieldValue) {
            for (
              let rowIdx = 0;
              rowIdx < field.optionList.rows.length;
              rowIdx++
            ) {
              for (let i = 0; i < field.optionList.rows[rowIdx].length; i++) {
                if (!field.optionList.rows[rowIdx][i].requiredTest()) {
                  console.warn(
                    `isCompleteFilledOut requiredTest() table row:${rowIdx} column:${i} value:${field.optionList.rows[rowIdx][i].currentValue.value}`,
                    field.optionList.rows[rowIdx][i].currentValue.value,
                    field.optionList.rows[rowIdx][i],
                    section.name
                  );

                  completeFilledOut = false;
                }
              }
            }
          } else if (field.optionList instanceof RadioFieldValue) {
            if (field.required)
              if (field.optionList.result === '') completeFilledOut = false;
          }
        }
      }
    }

    return completeFilledOut;
  }
  isSuccessful() {
    let isSucc = true;

    for (const section of this.sections) {
      for (const field of section.fields) {
        if (field.optionList instanceof TableFieldValue) {
          for (const row of field.optionList.rows) {
            //check required for all cells
            for (const col of row) {
              if (!col.requiredTest()) {
                isSucc = false;
              }
            }
            for (const col of row) {
              // field.optionList.rows.forEach(row => {
              // row.forEach(col => {
              if (col.checkRelevant) {
                //check the cell self
                if (!col.isCorrect()) {
                  isSucc = false;
                }
              }
              if (col.correctResult.linkedCells.length !== 0) {
                const getLinkedCellValue =
                  field.optionList.rows[
                    col.correctResult.linkedCells[0].rowIdx
                  ][col.correctResult.linkedCells[0].colIdx];

                //check linked cell only with same format
                if (
                  getLinkedCellValue.currentValue.format.value ===
                  col.currentValue.format.value
                ) {
                  if (
                    col.currentValue.format.value === 'centigrade' ||
                    col.currentValue.format.value === '' ||
                    col.currentValue.format.value === 'noFormat'
                  ) {
                    let tmpCurrent: string | number =
                      col.currentValue.value.replace(',', '.');
                    let tmpLinkedCurrent: string | number =
                      getLinkedCellValue.currentValue.value.replace(',', '.');
                    let tmpCorrect: string | number =
                      col.correctResult.linkedCells[0].value.replace(',', '.');

                    tmpCurrent = parseFloat(tmpCurrent);
                    tmpLinkedCurrent = parseFloat(tmpLinkedCurrent);
                    tmpCorrect = parseFloat(tmpCorrect);

                    const difference = Math.abs(tmpCurrent - tmpLinkedCurrent);

                    switch (col.correctResult.linkedCells[0].mode.value) {
                      case 'equal':
                        if (tmpCurrent !== tmpLinkedCurrent) {
                          isSucc = false;
                          console.log('equal');
                        }
                        break;
                      case 'minDiff':
                        if (difference < tmpCorrect) {
                          isSucc = false;
                          console.log('minDiff');
                        }
                        break;
                      case 'maxDiff':
                        if (difference > tmpCorrect) {
                          isSucc = false;
                          console.log('maxDiff');
                          console.log('tmpCurrent', tmpCurrent);
                          console.log('tmpLinkedCurrent', tmpLinkedCurrent);
                          console.log('difference', difference);
                          console.log('tmpCorrect', tmpCorrect);
                        }
                        break;
                    }
                  } else if (col.currentValue.format.value === 'dateTime') {
                    const tmpCurrent: string | number =
                      col.currentValue.value.replace(',', '.');
                    const tmpLinkedCurrent: string | number =
                      getLinkedCellValue.currentValue.value.replace(',', '.');
                    const tmpCorrect: string | number =
                      col.correctResult.linkedCells[0].value.replace(',', '.');

                    let dateUnitDifference = '';
                    switch (tmpCorrect[tmpCorrect.length - 1]) {
                      case 'd':
                        dateUnitDifference = 'minutes';
                        break;
                      case 'm':
                        dateUnitDifference = 'minutes';
                        break;
                      case 'h':
                        dateUnitDifference = 'hours';
                        break;
                    }

                    switch (col.correctResult.linkedCells[0].mode.value) {
                      case 'equal':
                        if (tmpCurrent !== tmpLinkedCurrent) {
                          isSucc = false;
                          console.log('tmpCurrent !== tmpLinkedCurrent');
                        }
                        break;
                      case 'minDiff':
                      case 'maxDiff':
                        const createDateFromVal = (val: string) => {
                          const dateM = date.extractDate(
                            val,
                            'DD/MM/YYYY HH:mm'
                          );
                          return dateM;
                        };
                        const currentDate = createDateFromVal(tmpCurrent);
                        const linkedDate = createDateFromVal(tmpLinkedCurrent);
                        let diff = date.getDateDiff(
                          currentDate,
                          linkedDate,
                          dateUnitDifference
                        );
                        if (diff < 0) diff *= -1;

                        let calc = parseFloat(tmpCorrect);
                        if (tmpCorrect[tmpCorrect.length - 1] === 'd') {
                          calc *= 24 * 60;
                        }

                        if (
                          col.correctResult.linkedCells[0].mode.value ===
                          'minDiff'
                        ) {
                          if (diff < calc) {
                            isSucc = false;
                            console.log('diff < calc');
                          }
                        } else if (
                          col.correctResult.linkedCells[0].mode.value ===
                          'maxDiff'
                        )
                          if (diff > calc) {
                            isSucc = false;
                            console.log('diff > calc');
                          }
                        break;
                    }
                  } else {
                  }
                }
              }
            }
          }
        } else if (field.optionList instanceof RadioFieldValue) {
          if (field.required)
            if (field.optionList.result === '') {
              isSucc = false;
              console.log("field.optionList.result === ''");
            }

          if (field.optionList.checkRelevant)
            if (field.optionList.result !== field.optionList.correctResult) {
              isSucc = false;
              console.log(
                'field.optionList.result !== field.optionList.correctResult'
              );
            }
        } else if (field.optionList instanceof CheckboxFieldValue) {
          if (field.optionList.checkRelevant)
            field.optionList.options.forEach((opt) => {
              if (opt.result !== opt.correctResult) {
                isSucc = false;
                console.log('opt.result !== opt.correctResult');
              }
            });
        } else if (field.optionList instanceof TextFieldValue) {
        } else {
          throw Error(
            'Error in isSuccessful() in Formular: Field does not match any FieldType: ' +
              field.optionList.type
          );
        }
      }
    }
    return isSucc;
  }
  getCurrentCarriedFrom() {
    if (this.steps.length > 0 && this.currentStep !== -1) {
      const stepDefinition = this.steps[this.currentStep];
      return stepDefinition.carriedFrom;
    } else return undefined;
  }
}

export class FormSection {
  name: string;
  @Type(() => FormFieldType)
  fields: FormFieldType[];
  id: string;

  constructor(name = '', fields: FormFieldType[] = [], id = v4()) {
    this.name = name;
    this.fields = fields;
    this.id = id;
  }
}

export abstract class FieldOptionValue {
  type: string;
  constructor(type: string) {
    this.type = type;
  }
}

export class RadioFieldValue extends FieldOptionValue {
  options: string[];
  result: string;
  correctResult: string;
  required: boolean;
  checkRelevant: boolean;
  constructor() {
    super('radioField');
    this.options = [];
    this.result = '';
    this.correctResult = '';
    this.required = false;
    this.checkRelevant = false;
  }
}

export class CheckboxFieldValue extends FieldOptionValue {
  options: CheckboxFieldOptionsValue[];
  required: boolean;
  checkRelevant: boolean;
  constructor() {
    super('checkboxField');
    this.options = [];
    this.required = false;
    this.checkRelevant = false;
  }
  requiredTest() {
    for (const iterator of this.options) {
      if (!iterator.result) return false;
    }
    return true;
  }
}
export interface CheckboxFieldOptionsValue {
  label: string;
  result: boolean;
  correctResult: boolean;
}

export class TextFieldValue extends FieldOptionValue {
  text: string;
  imgWidth: string;
  input: boolean;
  isComment: boolean;
  @Type(() => Date)
  lastChange: Date;
  constructor() {
    super('textField');
    this.text = '';
    this.imgWidth = '200px';
    this.input = false;
    this.isComment = false;
    this.lastChange = new Date();
  }
}

export class TableFieldValue extends FieldOptionValue {
  columns: string[];
  width: string[]; //always px
  @Type(() => TableCellDefinition)
  rows: Array<TableCellDefinition>[];
  constructor() {
    super('tableField');
    this.columns = [];
    this.rows = [];
    this.width = [];
  }
  setAllCellsRequired(val: boolean) {
    for (const row of this.rows) {
      for (const col of row) {
        col.required = val;
      }
    }
  }
}
// export type FieldOptionValue =
//   | RadioFieldValue
//   | CheckboxFieldValue
//   | TextFieldValue
//   | TableFieldValue;

// export interface RadioFieldValue {
//   type: string;
//   options: string[];
//   result: string;
//   correctResult: string;
//   // constructor() {
//   //   this.type = 'radioField';
//   //   this.options = [];
//   //   this.result = '';
//   //   this.correctResult = '';
//   // }
// }

// export class TableFieldValue {
//   type: string;
//   columns: string[];
//   width: string[]; //always px
//   rows: Array<TableCellDefinition>[];

//   constructor() {
//     this.type = 'tableField';
//     this.columns = [];
//     this.rows = [];
//     this.width = [];
//   }
// }
export class FormatOptionValue {
  // name: string;
  label: string;
  value: string;
  constructor(
    // name: string,
    label: string,
    value: string
  ) {
    // this.name = name;
    this.label = label;
    this.value = value;
  }
}
export class InputfieldValue {
  value: string;
  label: string;
  suffix: string;
  prefix: string;
  type: string;
  hint: string;
  mask: string;
  placeholder: string;
  @Type(() => FormatOptionValue)
  format: FormatOptionValue;
  constructor(
    value = '',
    label = '',
    suffix = '',
    prefix = '',
    type = 'text',
    hint = '',
    mask = '',
    placeholder = '',
    format = new FormatOptionValue('', '')
  ) {
    this.value = value;
    this.label = label;
    this.suffix = suffix;
    this.prefix = prefix;
    this.type = type;
    this.hint = hint;
    this.mask = mask;
    this.placeholder = placeholder;
    this.format = format;
  }
}

function replaceAll(str: string, find: string, replace: string) {
  return str.replace(new RegExp(find, 'g'), replace);
}
export class TableCellDefinition {
  input: boolean;
  required: boolean;
  @Type(() => InputfieldValue)
  currentValue: InputfieldValue;
  checkRelevant: boolean;
  correctResult: TableCellAnswerValue;
  constructor() {
    this.input = true;
    this.required = false;
    this.currentValue = new InputfieldValue();
    this.checkRelevant = false;
    this.correctResult = {
      type: new TableCellAnswerTypeValue('Fester Wert', 'fix'),
      start: '',
      end: '',
      linkedCells: [],
    };
  }
  /** Check the cell required requirements */
  requiredTest() {
    if (this.required && this.input) {
      let maskVal;

      if (this.currentValue.mask !== '') {
        // console.log('maskVal', maskVal);
        // console.log('this.currentValue.mask', this.currentValue.mask);

        maskVal = replaceAll(this.currentValue.mask, '#', '_');
        // this.currentValue.mask.replaceAll('#', '_');
        // console.log('maskVal', maskVal);
      }

      if (
        this.currentValue.value === '' ||
        this.currentValue.value === maskVal
      ) {
        // console.log('requiredTest currentValue', this.currentValue.value);
        // console.log('requiredTest maskVal', maskVal);

        return false;
      }
    }
    return true;
  }
  isCorrect() {
    let isCorrect = true;

    switch (this.currentValue.format.value) {
      case '':
      case 'noFormat':
      case 'centigrade': {
        let tmpCurrent: string | number = this.currentValue.value.replace(
          ',',
          '.'
        );
        let tmpCorrect: string | number = this.correctResult.start.replace(
          ',',
          '.'
        );
        let tmpCorrectEnd: string | number = this.correctResult.end.replace(
          ',',
          '.'
        );

        tmpCurrent = parseFloat(tmpCurrent);
        tmpCorrect = parseFloat(tmpCorrect);
        tmpCorrectEnd = parseFloat(tmpCorrectEnd);

        if (this.correctResult.type.value === 'fix') {
          if (tmpCurrent !== tmpCorrect) {
            isCorrect = false;
          }
        } else if (this.correctResult.type.value === 'range') {
          if (!(tmpCurrent >= tmpCorrect && tmpCurrent <= tmpCorrectEnd)) {
            isCorrect = false;
          }
        }
        break;
      }
    }
    return isCorrect;
  }
  // isLinkedCellCorrect() {
  //   let isCorrect = true;

  //   return isCorrect;
  // }
}

export class TableCellAnswerTypeValue {
  label: string;
  value: string;
  constructor(label: string, value: string) {
    this.label = label;
    this.value = value;
  }
}
export interface TableCellAnswerValue {
  type: TableCellAnswerTypeValue; //fix or range
  start: string;
  end: string;
  linkedCells: LinkedCellAnswerValue[];
}

export interface LinkedCellAnswerValue {
  mode: TableCellAnswerTypeValue; //can be equal or minDiff or maxDiff
  rowIdx: number;
  colIdx: number;
  value: string;
}

export interface FieldSettings {
  sectionIdx: number;
  fieldIdx: number;
  payload: PayloadVal;
}
export interface PayloadVal {
  [key: string]: string | number;
  fieldType: string;
}

export class FormFieldType {
  label: string;
  icon: string;
  typeName: string;
  required: boolean;
  @Type(() => FieldOptionValue, {
    discriminator: {
      property: 'type',
      subTypes: [
        { value: RadioFieldValue, name: 'radioField' },
        { value: CheckboxFieldValue, name: 'checkboxField' },
        { value: TableFieldValue, name: 'tableField' },
        { value: TextFieldValue, name: 'textField' },
      ],
    },
    keepDiscriminatorProperty: true,
  })
  optionList: FieldOptionValue;
  fieldImageList: string[];
  description: string;
  constructor(
    label: string,
    icon: string,
    typeName: string,
    required: boolean,
    optionList: FieldOptionValue,
    fieldImageList = [],
    description = ''
  ) {
    this.label = label;
    this.icon = icon;
    this.typeName = typeName;
    this.required = required;
    this.optionList = optionList;
    // this.checkRelevant = checkRelevant;
    this.fieldImageList = fieldImageList;
    this.description = description;
  }
}
