


















































































































































































































































import { defineComponent, ref, onMounted } from '@vue/composition-api';
import store from 'src/store/directStore';
import Vue from 'vue';
//components
//interfaces
import { OrganisationValue } from 'src/interfaces/Settings.Interface';
//configs
//composition
import { useRequestHandling } from 'src/compositions/RequestHandling';
import { OptionValue } from 'src/interfaces/Common.Interface';

export default defineComponent({
  name: 'gwShippingSettingsCard',
  components: {},
  props: {},
  setup(props, context) {
    const { updateDocument, refreshOrganisation } = useRequestHandling();
    const editMode = ref<boolean>(false);
    const localOrganisation = ref<OrganisationValue | undefined>(
      store.state.deviceAdmin.organisation
    );

    onMounted(async () => {
      await refreshOrganisation();
      localOrganisation.value = store.state.deviceAdmin.organisation;
    });

    const startEdit = () => {
      localOrganisation.value = store.state.deviceAdmin.organisation;
      editMode.value = !editMode.value;
      context.emit('start-edit', editMode.value);
    };

    const submit = async () => {
      if (localOrganisation.value) {
        await updateDocument(localOrganisation.value);
        await refreshOrganisation();
        localOrganisation.value = store.state.deviceAdmin.organisation;
      }
      editMode.value = false;
      context.emit('stop-edit');
    };

    const blockViewMode = ref<OptionValue>(
      new OptionValue('DE', 'Deutschland')
    );

    const countryOptions = ref<OptionValue[]>([
      new OptionValue('DE', 'Deutschland'),
      new OptionValue('NL', 'Niederlande'),
      new OptionValue('AT', 'Österreich'),
    ]);

    const weekDayOptions = ref<OptionValue[]>([
      new OptionValue(1, 'Montag'),
      new OptionValue(2, 'Dienstag'),
      new OptionValue(3, 'Mittwoch'),
      new OptionValue(4, 'Donnerstag'),
      new OptionValue(5, 'Freitag'),
      new OptionValue(6, 'Samstag'),
      new OptionValue(0, 'Sonntag'),
    ]);

    const shippingMode = ref<OptionValue>(
      new OptionValue('delivery', 'Lieferung')
    );
    const shippingModes = ref<OptionValue[]>([
      new OptionValue('pickup', 'Abholung'),
      new OptionValue('delivery', 'Lieferung'),
    ]);

    const countrySelect = ref<string>('DE');
    const shippingTypeSelect = ref<string>('delivery');

    const typeOptions = ref<OptionValue[]>([
      new OptionValue('normal', 'Normal'),
      new OptionValue('range', 'Zeitraum'),
    ]);

    const toggleMaxDaysInFuture = (
      country: string,
      type: string,
      val: boolean
    ) => {
      console.log('localOrganisation.value', localOrganisation.value);
      console.log('toggleMaxDaysInFuture', country, type, val);

      if (localOrganisation.value) {
        if (val) {
          // localOrganisation.value.shippingSettings.settings[country][
          //   type
          // ].maxDaysInFuture = 51;
          Vue.set(
            localOrganisation.value.shippingSettings.settings[country][type],
            'maxDaysInFuture',
            51
          );
        } else {
          // localOrganisation.value.shippingSettings.settings[country][
          //   type
          // ].maxDaysInFuture = -1;
          Vue.set(
            localOrganisation.value.shippingSettings.settings[country][type],
            'maxDaysInFuture',
            -1
          );
        }
      }
    };

    return {
      editMode,
      localOrganisation,
      startEdit,
      submit,
      blockViewMode,
      countryOptions,
      weekDayOptions,
      shippingMode,
      shippingModes,
      countrySelect,
      shippingTypeSelect,
      typeOptions,
      toggleMaxDaysInFuture,
    };
  },
});
