
















































































































































































































import {
  defineComponent,
  onMounted,
  ref,
  computed,
} from '@vue/composition-api';
import store from 'src/store/directStore';
import { date } from 'quasar';
import { Notify } from 'quasar';
import { v4 } from 'uuid';
import _ from 'lodash';
// import Vue from 'vue';
//components
import { QCalendar } from '@quasar/quasar-ui-qcalendar';
//interfaces
import { Device } from 'src/interfaces/Device.Interface';
import { CheckDefinition } from 'src/interfaces/Check.Interface';
import { User } from 'src/interfaces/User.Interface';
type CalendarEntryType =
  | 'shipping'
  | 'due-user-check'
  | 'due-system-check'
  | 'due-extern-check'
  | 'device-too-long-on-service-provider'
  | 'overdue-check';

class CalenderEventEntry {
  _id: string;
  title: string;
  details: string;
  date: string;
  bgColor: string;
  icon: string;
  deviceInv: string;
  deviceId: string;
  actionRequestId: string;
  userId: string;
  username: string;
  type: CalendarEntryType;
  tooltip: string;

  constructor(
    title: string,
    details: string,
    date: string,
    bgColor: string,
    icon: string,
    deviceInv: string,
    deviceId: string,
    actionRequestId: string,
    userId: string,
    username: string,
    type: CalendarEntryType,
    tooltip: string
  ) {
    this._id = v4();
    this.title = title;
    this.details = details;
    this.date = date;
    this.bgColor = bgColor;
    this.icon = icon;
    this.deviceInv = deviceInv;
    this.deviceId = deviceId;
    this.actionRequestId = actionRequestId;
    this.userId = userId;
    this.username = username;
    this.type = type;
    this.tooltip = tooltip;
  }
}

import {
  ActionRequest,
  ServiceRequestValue,
} from 'src/interfaces/Action.Interface';
import { OptionValue } from 'src/interfaces/Common.Interface';

//compositions
// import { useRequestHandling } from 'src/compositions/RequestHandling';
// configs
const lampColor: { [key: string]: string } = {
  'due-user-check': 'green',
  shipping: 'yellow',
  'overdue-check': 'red',
};

export default defineComponent({
  name: 'Calendar',
  components: { QCalendar },
  props: {},
  setup(_props, context) {
    // const { getDocumentList, startCheckDevice } = useRequestHandling();

    const selectedCalendarEntry = ref<CalenderEventEntry>();

    const select = (id: string) => {
      if (
        selectedCalendarEntry.value &&
        selectedCalendarEntry.value._id === id
      ) {
        selectedCalendarEntry.value = undefined;
      } else {
        const find = eventList.value.find((entry) => entry._id === id);
        if (find) selectedCalendarEntry.value = _.cloneDeep(find);
      }
    };

    // const actionReqList = ref<ActionRequest[]>([]);
    const filterCalendarEntryType = ref<{
      [key: string]: { color: string; show: boolean };
    }>({
      shipping: {
        color: 'yellow',
        show: true,
      },
      'due-user-check': {
        color: 'green',
        show: true,
      },
      'due-system-check': {
        color: 'green',
        show: true,
      },
      'due-extern-check': {
        color: 'green',
        show: true,
      },
      'device-too-long-on-service-provider': {
        color: 'red',
        show: true,
      },
      'overdue-check': {
        color: 'red',
        show: true,
      },
    });

    onMounted(() => {
      // actionReqList.value = (await getDocumentList(
      //   'actionRequest'
      // )) as ActionRequest[];
    });

    const calenderSelectedDate = ref<string>();

    const filterDeviceType = ref<string>('');

    // const typeDueUserCheck = ref<boolean>(false);
    // const typeShipping = ref<boolean>(false);
    // const typeOverdueCheck = ref<boolean>(false);

    const getEvents = (val: string) => {
      const find = eventList.value.filter((entry) => entry.date === val);
      return find;
    };

    const eventList = computed(() => {
      let list: CalenderEventEntry[] = [];
      const deviceList = store.state.deviceAdmin.deviceList;

      // CHECKS
      for (const device of deviceList) {
        for (const nextCheck of device.nextChecks) {
          const checkDefinition =
            store.getters.deviceAdmin.getCheckDefinitionById(
              nextCheck.checkDefinitionId
            );

          if (checkDefinition instanceof CheckDefinition) {
            let canAdd = true;

            // FILTER CHECKDEFINITION
            if (
              carriedFromFilter.value &&
              carriedFromFilter.value !== checkDefinition.carriedFrom.type
            ) {
              canAdd = false;
            }

            let type: CalendarEntryType = 'due-user-check';
            switch (checkDefinition.carriedFrom.type) {
              case 'intern':
                type = 'due-system-check';
                break;
              case 'extern':
                type = 'due-extern-check';
                break;
            }
            const today = new Date();
            if (today.getTime() > nextCheck.nextCheck.getTime()) {
              type = 'overdue-check';
            }

            if (!filterCalendarEntryType.value[type].show) {
              canAdd = false;
            }

            // FILTER DEVICETYPE
            if (
              filterDeviceType.value &&
              filterDeviceType.value !== device.type
            ) {
              canAdd = false;
            }

            const formattedString = date.formatDate(
              nextCheck.nextCheck,
              'YYYY-MM-DD'
            );

            if (canAdd && checkDefinition._id) {
              const findActionReq =
                store.getters.deviceAdmin.getActionRequestFromCheckDefId(
                  device._id,
                  checkDefinition._id
                );
              list.push(
                new CalenderEventEntry(
                  checkDefinition.name,
                  'details',
                  formattedString,
                  lampColor[type],
                  'fact_check',
                  device.inventoryNumber,
                  device._id,
                  findActionReq instanceof ActionRequest && findActionReq._id
                    ? findActionReq._id
                    : '',
                  device.assignedTo.user,
                  store.getters.deviceAdmin.getUserNameById(
                    device.assignedTo.user
                  ),
                  type,
                  device.inventoryNumber
                )
              );
            }
          }
        }
      }

      // SHIPPING
      if (filterCalendarEntryType.value['shipping'].show) {
        for (const actionReq of store.state.deviceAdmin.actionRequestList) {
          switch (actionReq.status) {
            case 'shipping-date-confirmed':
            case 'notification-shipping-date-confirmed':
            case 'waiting-for-replacement-shipping':
              const device = store.getters.deviceAdmin.getDeviceById(
                actionReq.command.value.deviceId
              );
              if (
                actionReq.command.value instanceof ServiceRequestValue &&
                actionReq.command.value.shippingDate &&
                actionReq.command.value.shippingDate.length > 0 &&
                device instanceof Device
              ) {
                const shippingDate = actionReq.command.value.shippingDate[0];

                let editDate;

                let dateString = '';

                const user = store.getters.deviceAdmin.getUserById(
                  device.assignedTo.user
                );
                if (user instanceof User) {
                  // let address = actionReq.command.value.shippingAddress
                  //   ? actionReq.command.value.shippingAddress
                  //   : user.address;

                  switch (shippingDate.type) {
                    case 'normal':
                      editDate = shippingDate.date;
                      dateString = date.formatDate(
                        shippingDate.date,
                        'YYYY-MM-DD'
                      );
                      break;
                    case 'dateWithTimeRange':
                      editDate = shippingDate.date;
                      dateString = date.formatDate(
                        shippingDate.date,
                        'YYYY-MM-DD'
                      );
                      break;
                    case 'range':
                      console.log('RANGE', shippingDate.date);
                      editDate = date.extractDate(
                        shippingDate.timeRange.from,
                        'YYYY/MM/DD'
                      );
                      dateString = date.formatDate(
                        shippingDate.timeRange.from,
                        'YYYY-MM-DD'
                      );
                      break;
                  }

                  const type = 'shipping';

                  let canAdd = true;

                  // FILTER DEVICETYPE
                  if (
                    filterDeviceType.value &&
                    filterDeviceType.value !== device.type
                  ) {
                    canAdd = false;
                  }

                  // FILTER CHECKDEFINITION
                  const checkDefinition =
                    store.state.deviceAdmin.checkDefinitionList.find(
                      (entry) =>
                        entry._id === actionReq.command.value.checkDefinitionId
                    );
                  if (checkDefinition) {
                    if (
                      carriedFromFilter.value &&
                      carriedFromFilter.value !==
                        checkDefinition.carriedFrom.type
                    ) {
                      canAdd = false;
                    }
                  }

                  if (canAdd)
                    if (editDate) {
                      let dayOffset: Date = new Date();
                      dayOffset.setTime(editDate.getTime());

                      // switch (address.country) {
                      //   case 'DE':
                      //     dayOffset.setTime(dayOffset.getTime() - 1 * 86400000);
                      //     console.log('DE');
                      //     break;
                      //   case 'AT':
                      //     console.log('AT');
                      //     dayOffset.setTime(dayOffset.getTime() - 1 * 86400000);
                      //     break;
                      //   case 'NL':
                      //     console.log('NL');
                      //     dayOffset.setTime(dayOffset.getTime() - 1 * 86400000);
                      //     break;
                      // }

                      // OFFSET ENTRY
                      const orga = store.state.deviceAdmin.organisation;
                      if (
                        orga &&
                        orga.settings.calendarShowShippingRequestDaysBefore &&
                        orga.settings.calendarShippingRequestDaysBefore !==
                          undefined
                      ) {
                        dayOffset.setTime(
                          dayOffset.getTime() -
                            orga.settings.calendarShippingRequestDaysBefore *
                              86400000
                        );

                        if (orga.settings.onlyUseWorkdaysForShippingRequest) {
                          if (dayOffset.getDay() === 0) {
                            dayOffset.setTime(
                              dayOffset.getTime() - 2 * 86400000
                            );
                          } else if (dayOffset.getDay() === 6) {
                            dayOffset.setTime(
                              dayOffset.getTime() - 1 * 86400000
                            );
                          }
                        }

                        list.push(
                          new CalenderEventEntry(
                            'Versand',
                            'details',
                            date.formatDate(dayOffset, 'YYYY-MM-DD'),
                            lampColor[type],
                            'local_shipping',
                            device.inventoryNumber,
                            device._id,
                            actionReq._id ? actionReq._id : '',
                            device.assignedTo.user,
                            store.getters.deviceAdmin.getUserNameById(
                              device.assignedTo.user
                            ),
                            type,
                            device.inventoryNumber
                          )
                        );
                      }

                      if (orga && orga.settings.calendarShowDeliveryDate) {
                        list.push(
                          new CalenderEventEntry(
                            'Liefertermin',
                            'details',
                            dateString,
                            lampColor[type],
                            'local_shipping',
                            device.inventoryNumber,
                            device._id,
                            actionReq._id ? actionReq._id : '',
                            device.assignedTo.user,
                            store.getters.deviceAdmin.getUserNameById(
                              device.assignedTo.user
                            ),
                            type,
                            device.inventoryNumber
                          )
                        );
                      }
                    }
                }
              }

              break;
          }
        }
      }

      return list;
    });

    const calenderNext = () => {
      if (calendar.value) {
        calendar.value.next();
      }
    };
    const calenderPrev = () => {
      if (calendar.value) {
        calendar.value.prev();
      }
    };

    const calendar = ref<QCalendar>();
    const calendarViewMode = ref<string>('month');

    const calendarResources = computed(() => {
      let res: { [key: string]: string }[] = [];

      if (calendar.value) {
      }
      return res;
    });

    const goToEvent = (actionReqId: string) => {
      // void context.root.$router.push('/devices/detail/' + deviceId);

      const find = store.state.deviceAdmin.actionRequestList.find(
        (entry) => entry._id === actionReqId
      );
      if (find) {
        void context.root.$router.push('/checks/edit/' + actionReqId);
      } else {
        Notify.create({
          message: 'Prüfung existiert nicht',
          color: 'negative',
          textColor: 'white',
          icon: 'warning',
          position: 'top',
        });
      }
    };

    const carriedFromFilter = ref<string>('');
    const carriedFromOptions = ref<OptionValue[]>([
      new OptionValue('', 'Alle'),
      new OptionValue(
        'intern',
        context.root.$t('checkDefinition.carriedFrom.intern').toString()
      ),
      new OptionValue(
        'extern',
        context.root.$t('checkDefinition.carriedFrom.extern').toString()
      ),
      new OptionValue(
        'self',
        context.root.$t('checkDefinition.carriedFrom.self').toString()
      ),
    ]);

    const deviceTypeOptions = computed(() => {
      let list: OptionValue[] = [];

      list.push(new OptionValue('', 'Alle'));

      for (const deviceType of store.state.deviceAdmin.deviceTypeList) {
        list.push(new OptionValue(deviceType._id, deviceType.label));
      }
      return list;
    });

    const calendarEntryTypeOptions = ref<OptionValue[]>([
      new OptionValue('', 'Alle'),
      new OptionValue('shipping', 'Versand'),
      new OptionValue('due-user-check', 'Fällige Gerätenutzer-Prüfungen'),
      new OptionValue('due-system-check', 'Fällige Interne-Prüfungen'),
      new OptionValue('due-extern-check', 'Fällige Dienstleister-Prüfungen'),
      new OptionValue(
        'device-too-long-on-service-provider',
        'Geräte die länger als erlaubt beim Dienstleister sind'
      ),
    ]);

    const goToToday = () => {
      if (calendar.value) {
        calendar.value.moveToToday();
      }
    };

    const forceRender = ref<boolean>(false);

    // const test = (name: string, value: boolean) => {
    //   console.log('test', name, value);
    //   filterCalendarEntryType.value[name].show = value;
    //   // Vue.set(filterCalendarEntryType.value[name], 'show', value);
    //   // console.log(
    //   //   'filterCalendarEntryType.value',
    //   //   filterCalendarEntryType.value
    //   // );
    //   forceRender.value = !forceRender.value;
    // };

    const goToDevice = (deviceId: string) => {
      void context.root.$router.push('/devices/detail/' + deviceId);
    };

    return {
      forceRender,
      // test,
      lampColor,
      // filter
      filterDeviceType,
      filterCalendarEntryType,
      calenderSelectedDate,
      getEvents,
      calenderNext,
      calenderPrev,
      calendar,
      calendarViewMode,
      calendarResources,
      goToEvent,
      carriedFromFilter,
      carriedFromOptions,
      goToToday,
      deviceTypeOptions,
      calendarEntryTypeOptions,
      selectedCalendarEntry,
      select,
      goToDevice,
      // typeDueUserCheck,
      // typeShipping,
      // typeOverdueCheck,
    };
  },
});
