import { v4 } from 'uuid';
import { SentReminderValue } from './Action.Interface';
import { CostEntry } from './Costs.Interface';
import { Type } from 'class-transformer';
import 'reflect-metadata';
import {
  BaseRecord,
  CarriedFromValue,
  EntityData,
  IntervallValue,
} from './Common.Interface';
import { CommentValue } from './Device.Interface';
import { Formular } from './Form.Interface';

export class Check {
  id: number | string; //identify check
  checkDefinitionId: string;
  name: string; //name of the checkDefinition (id of checkDefinition)
  @Type(() => Date)
  date: Date; //check date
  inspector: string; //user id
  successful: boolean;
  status: string;
  type: string; //deliveryCheck
  invalid?: boolean;
  @Type(() => Formular)
  formular?: Formular;
  actionRequestId?: string;
  lastUser?: string; //last nurse when check sysUser complete and save the check
  @Type(() => CommentValue)
  comments?: CommentValue[];
  @Type(() => CostEntry)
  costs?: CostEntry[];
  @Type(() => Date)
  timeOfExecution?: Date; //real time of durchführung
  @Type(() => SentReminderValue)
  sentReminders?: SentReminderValue[];
  @Type(() => Date)
  originalCheckDate?: Date;
  manual?: boolean;
  constructor(
    // id = v4(),
    checkDefinitionId: string,
    name = 'new Check',
    date: Date,
    inspector = '',
    status = 'completed',
    successful: boolean,
    type = 'normalCheck',
    invalid = false,
    formular?: Formular,
    actionRequestId?: string, //actionRequestId for save function, can start with actionRequest and lastCheck
    lastUser?: string,
    comments: CommentValue[] = [],
    costs: CostEntry[] = [],
    timeOfExecution?: Date,
    sentReminders: SentReminderValue[] = [],
    originalCheckDate?: Date,
    manual = false
  ) {
    this.id = v4();
    this.checkDefinitionId = checkDefinitionId;
    this.name = name;
    this.date = date;
    this.inspector = inspector;
    this.successful = successful;
    this.status = status;
    this.type = type;
    this.invalid = invalid;
    this.formular = formular;
    this.actionRequestId = actionRequestId;
    this.lastUser = lastUser;
    this.comments = comments;
    this.costs = costs;
    this.timeOfExecution = timeOfExecution;
    this.sentReminders = sentReminders;
    this.originalCheckDate = originalCheckDate;
    this.manual = manual;
  }
}

export interface ReminderValue {
  name: string;
  type: string; //reminder or escalation
  activate: boolean;
  intervall: IntervallValue;
}

export interface NotificationReceiver {
  type: string;
  receiver: string;
}

export class ConditionalNotification {
  id: string;
  timeType: string;
  days: number;
  action: string;
  notificationType: string;
  receiver: NotificationReceiver;
  active: boolean;
  subject: string;
  text: string;
  pauseAfterActivity: boolean;
  constructor(
    timeType: string,
    days: number,
    action: string,
    notificationType: string,
    receiver: NotificationReceiver,
    active: boolean,
    subject: string,
    text: string,
    pauseAfterActivity: boolean
  ) {
    this.id = v4();
    this.timeType = timeType;
    this.days = days;
    this.action = action;
    this.notificationType = notificationType;
    this.receiver = receiver;
    this.active = active;
    this.subject = subject;
    this.text = text;
    this.pauseAfterActivity = pauseAfterActivity;
  }
}

export class CheckDefinition extends BaseRecord {
  formularId: string;
  @Type(() => IntervallValue)
  intervall: IntervallValue;
  name: string;
  resetCheckToo: string[];
  triggerAtAction: string[];
  @Type(() => CarriedFromValue)
  carriedFrom: CarriedFromValue;
  warningTime: IntervallValue;
  type: string; //normal or deliveryCheck
  validityPeriod: IntervallValue;
  resetByDeliveryCheck: boolean;
  reminder: ReminderValue[];
  data: EntityData | undefined;
  onlyOnAssign?: boolean; // actionRequest only created when device is assignedTo carriedFrom value
  invalidOnIncoming?: boolean;
  invalidOnOutgoing?: boolean;
  saveFailedAttempts?: boolean;
  videoLink?: string;
  allowedSubmitCheckWithDifferentDate?: boolean;
  conditionalNotifications?: ConditionalNotification[];
  constructor(
    formularId = '',
    intervall = new IntervallValue(),
    name = '',
    resetCheckToo: string[] = [],
    triggerAtAction: string[] = [],
    carriedFrom = { type: '', user: '', group: '' },
    warningTime = new IntervallValue(30, 'days'),
    type = 'normalCheck',
    validityPeriod = new IntervallValue(60, 'days'),
    resetByDeliveryCheck = false,
    reminder: ReminderValue[] = [],
    data = undefined,
    onlyOnAssign = false,
    invalidOnIncoming = false,
    invalidOnOutgoing = false,
    saveFailedAttempts = false,
    videoLink = '',
    allowedSubmitCheckWithDifferentDate = false,
    conditionalNotifications?: ConditionalNotification[]
  ) {
    super('checkDefinition');
    this.formularId = formularId;
    this.intervall = intervall;
    this.name = name;
    this.resetCheckToo = resetCheckToo;
    this.triggerAtAction = triggerAtAction;
    this.carriedFrom = carriedFrom;
    this.warningTime = warningTime;
    this.type = type;
    this.validityPeriod = validityPeriod;
    this.resetByDeliveryCheck = resetByDeliveryCheck;
    this.reminder = reminder;
    this.data = data;
    this.onlyOnAssign = onlyOnAssign;
    this.invalidOnIncoming = invalidOnIncoming;
    this.invalidOnOutgoing = invalidOnOutgoing;
    this.saveFailedAttempts = saveFailedAttempts;
    this.videoLink = videoLink;
    this.allowedSubmitCheckWithDifferentDate =
      allowedSubmitCheckWithDifferentDate;
    this.conditionalNotifications = conditionalNotifications;
  }
  calculateNextCheck() {
    // let resultNextCheckDate = new Date();
    let today = new Date();
    // resultNextCheckDate = today;
    if (this.intervall.unit === 'years')
      today = new Date(
        today.setFullYear(today.getFullYear() + this.intervall.value)
      );
    else if (this.intervall.unit === 'month')
      today = new Date(today.setMonth(today.getMonth() + this.intervall.value));
    else if (this.intervall.unit == 'days')
      today = new Date(today.setDate(today.getDate() + this.intervall.value));
    return today;
  }
}
