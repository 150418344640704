









import { defineComponent, computed } from '@vue/composition-api';
import store from 'src/store/directStore';
// components
import BasicSentNotificationList from 'src/components/checks/BasicSentNotificationList.vue';
// interfaces
import {
  ActionRequest,
  ActionRequestCustomNotificationEntry,
  ActionRequestNotificationEntry,
} from 'src/interfaces/Action.Interface';
// configs
import { dateFormat } from 'src/config/settingsConfig';

export default defineComponent({
  name: 'SentNotificationsList',
  components: { BasicSentNotificationList },
  props: {
    value: {
      type: Object as () => ActionRequest,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const list = computed(() => {
      let list: ActionRequestNotificationEntry[] = [];

      if (props.value.sentMails) {
        for (const iterator of props.value.sentMails) {
          list.push({
            receiver: iterator.receiver,
            date: iterator.date.toLocaleDateString('de-De', dateFormat),
            name: iterator.name ? iterator.name : '',
            type: iterator.type ? iterator.type : '',
            mailTemplate: iterator.mailTemplate,
            success: iterator.success,
            manual: iterator.manual !== undefined ? iterator.manual : false,
            subject: iterator.subject ? iterator.subject : '',
          });
        }
      }
      return list;
    });

    const customList = computed(() => {
      let list: ActionRequestCustomNotificationEntry[] = [];

      if (props.value.sentConditionalNotifications) {
        for (const iterator of props.value.sentConditionalNotifications) {
          let receiverString = '';
          switch (iterator.receiver.type) {
            case 'systemUser':
              const tempSysUser = store.state.deviceAdmin.systemUserList.find(
                (entry) => entry._id === iterator.receiver.id
              );
              if (tempSysUser)
                receiverString = `${tempSysUser.firstname} ${tempSysUser.lastname}`;
              break;
            case 'user':
              const tempUser = store.state.deviceAdmin.userList.find(
                (entry) => entry._id === iterator.receiver.id
              );
              if (tempUser)
                receiverString = `${tempUser.firstname} ${tempUser.lastname}`;
              break;
          }
          list.push({
            receiver: receiverString,
            date: iterator.date.toLocaleDateString('de-De', dateFormat),
          });
        }
      }
      return list;
    });

    const onClickSentMailAgain = (type: string, name: string) => {
      context.emit('sent-mail', type, name);
    };

    return { list, customList, onClickSentMailAgain };
  },
});
