import { BaseRecord } from './Common.Interface';
import { OutgoingReceiverValue } from 'src/interfaces/Shipping.Interface';
import { CostEntry } from './Costs.Interface';
import {
  AssignedValue,
  EntityData,
  IntervallValue,
} from 'src/interfaces/Common.Interface';
import { Type } from 'class-transformer';
import 'reflect-metadata';

//interfaces
import { Check } from './Check.Interface';

export interface DeviceList {
  id: string;
  doctype: string;
  inventoryNumber: string;
  typeId: string;
  typeLabel: string;
  status: string;
  statusCode: number;
  statusColor: string;
  belongDefinition: string;
}

export class DevicePartnerValue {
  partnerDeviceId: string;
  partnerInventoryNumber: string;
  partnerDeviceTypeLabel: string;
  isMaster: boolean;
  constructor(
    id: string,
    inventoryNumber: string,
    deviceTypeLabel: string,
    isMaster: boolean
  ) {
    this.partnerDeviceId = id;
    this.partnerInventoryNumber = inventoryNumber;
    this.partnerDeviceTypeLabel = deviceTypeLabel;
    this.isMaster = isMaster;
  }
}

export class NextCheckValue {
  checkDefinitionId: string;
  @Type(() => Date)
  nextCheck: Date;
  disableReplacementDevice?: boolean;
  constructor(
    checkDefinitionId: string,
    nextCheck: Date,
    disableReplacementDevice?: boolean
  ) {
    this.checkDefinitionId = checkDefinitionId;
    this.nextCheck = nextCheck;
    this.disableReplacementDevice = disableReplacementDevice;
  }
}

export interface ConvertedPartnerGroupValue {
  id: string;
  masterId: string;
  partner: Device[];
}
export interface PartnerGroupValue {
  id: string;
  masterId: string;
  partner: string[];
}

export class PartnerConfigValue {
  _id: string;
  _rev?: string;
  doctype: string;
  masterId: string;
  partner: string[];
  constructor(masterId: string, partner: string[]) {
    this._id = '';
    this._rev = undefined;
    this.doctype = 'partnerConfig';
    this.masterId = masterId;
    this.partner = partner;
  }
}

export class CommentValue {
  @Type(() => Date)
  date: Date;
  autor: string;
  title: string;
  text: string;
  imported?: boolean;
  constructor(
    date = new Date(),
    autor = '',
    title = '',
    text = '',
    imported = false
  ) {
    this.date = date;
    this.autor = autor;
    this.title = title;
    this.text = text;
    this.imported = imported;
  }
}

export interface PartnerDeviceValue {
  master: boolean;
  partnerList: string[];
}

export class ShippingEntry {
  type: string; //incoming/outgoing
  @Type(() => Date)
  incomingTime: Date | undefined;
  @Type(() => Date)
  outgoingTime: Date | undefined;
  receiver: OutgoingReceiverValue | undefined;
  actionRequestId: string;
  constructor(
    type: string,
    incomingTime: Date | undefined = undefined,
    outgoingTime: Date | undefined = undefined,
    receiver: OutgoingReceiverValue,
    actionRequestId = ''
  ) {
    this.type = type;
    this.incomingTime = incomingTime;
    this.outgoingTime = outgoingTime;
    this.receiver = receiver;
    this.actionRequestId = actionRequestId;
  }
}

export interface DisabledReminder {
  [key: string]: string[];
}

export class Device extends BaseRecord {
  [key: string]:
    | string
    | NextCheckValue
    | NextCheckValue[]
    | Check
    | undefined
    | Check[]
    | AssignedValue
    | SimData[]
    | number
    | CommentValue[]
    | CostEntry[]
    | ShippingEntry[]
    | boolean
    | number
    | DisabledReminder;

  serialNumber: string;
  name: string;
  @Type(() => NextCheckValue)
  nextChecks: NextCheckValue[];
  @Type(() => Check)
  lastChecks: Check[];
  type: string;
  assignedTo: AssignedValue;
  @Type(() => SimData)
  sim: SimData[];
  status: number;
  partnerStatus: number;
  inventoryNumber: string;
  partnerConfigId: string;
  buyDate: string;
  @Type(() => CommentValue)
  comments: CommentValue[];
  @Type(() => CostEntry)
  costs?: CostEntry[];
  belongDefinition?: string;
  @Type(() => ShippingEntry)
  shippingList?: ShippingEntry[];
  archived: boolean;
  archivedCycleLimitation?: number;
  disabledReminder?: DisabledReminder;
  constructor(
    serialNumber = '',
    name = '',
    nextChecks: NextCheckValue[] = [],
    lastChecks: Check[] = [],
    type = '',
    assignedTo = { user: '', userGroup: '', customer: '', type: '' },
    sim: SimData[] = [],
    status = 1,
    partnerStatus = -1,
    inventoryNumber = '',
    partnerConfigId = '',
    buyDate = '',
    comments: CommentValue[] = [],
    costs: CostEntry[] = [],
    belongDefinition = '',
    shippingList: ShippingEntry[] = [],
    archived = false,
    archivedCycleLimitation = undefined,
    disabledReminder?: DisabledReminder
  ) {
    super('device');

    this.name = name;
    this.nextChecks = nextChecks;
    this.lastChecks = lastChecks;
    this.type = type;
    this.assignedTo = assignedTo;
    this.sim = sim;
    this.status = status;
    this.partnerStatus = partnerStatus;
    this.serialNumber = serialNumber;
    this.inventoryNumber = inventoryNumber;
    this.partnerConfigId = partnerConfigId;
    this.buyDate = buyDate;
    this.comments = comments;
    this.costs = costs;
    this.belongDefinition = belongDefinition;
    this.shippingList = shippingList;
    this.archived = archived;
    this.archivedCycleLimitation = archivedCycleLimitation;
    this.disabledReminder = disabledReminder;
  }
}

export interface DeviceTableRowValue {
  [key: string]:
    | string
    | number
    | { name: string; icon: string; style: string; tooltip: string };
}

export class SimData {
  simNumber: string;
  simPin: string;
  simPuk: string;
  customId: string;

  constructor(simNumber = '', simPin = '', simPuk = '', customId = '') {
    this.simNumber = simNumber;
    this.simPin = simPin;
    this.simPuk = simPuk;
    this.customId = customId;
  }
}

export class DeviceTypeShippingSettings {
  checkDefinitionId: string;
  allowPickup: boolean;
  allowSelfShipping: boolean;
  constructor(
    checkDefinitionId = '',
    allowPickup = true,
    allowSelfShipping = true
  ) {
    this.checkDefinitionId = checkDefinitionId;
    this.allowPickup = allowPickup;
    this.allowSelfShipping = allowSelfShipping;
  }
}

export class DeviceType extends BaseRecord {
  // _id: string;
  // _rev?: string;
  // doctype = 'deviceType';
  label: string;
  checkDefinitions: string[];
  identNumber: string | undefined;
  checksBlockedByDeliveryCheck: boolean;
  data: EntityData | undefined;
  @Type(() => LinkedCheckValue)
  linkedChecks?: LinkedCheckValue[];
  @Type(() => DeviceTypeShippingSettings)
  shippingSettings: DeviceTypeShippingSettings[];
  // @ToDo sieht Story GWD-179
  // versand: [
  //   {
  //     checkID: 'djfsdgjsdg';
  //     spedition: false;
  //     selberVersenden: true;
  //   }
  // ];
  constructor(
    label = '',
    checkDefinitions: string[] = [],
    identNumber: string | undefined = undefined,
    checksBlockedByDeliveryCheck = false,
    data = undefined,
    linkedChecks = [],
    shippingSettings = []
  ) {
    super('deviceType');
    // this._id = '';
    // this._rev = undefined;
    this.label = label;
    this.checkDefinitions = checkDefinitions;
    this.identNumber = identNumber;
    this.checksBlockedByDeliveryCheck = checksBlockedByDeliveryCheck;
    this.data = data;
    this.linkedChecks = linkedChecks;
    this.shippingSettings = shippingSettings;
  }
}

export class LinkedCheckValue {
  linkedCheckDefId: string;
  blockedByCheckDefId: string;
  range: IntervallValue;
  resetLinkedCheck: boolean;
  constructor(
    linkedCheckDefId: string,
    blockedByCheckDefId: string,
    range = new IntervallValue(30, 'days'),
    resetLinkedCheck = false
  ) {
    this.linkedCheckDefId = linkedCheckDefId;
    this.blockedByCheckDefId = blockedByCheckDefId;
    this.range = range;
    this.resetLinkedCheck = resetLinkedCheck;
  }
}
