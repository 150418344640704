import { StatusValue } from './../config/notificationConfig';
import { computed } from '@vue/composition-api';
import store from 'src/store/directStore';
//interfaces
import { OptionValue } from './../interfaces/Common.Interface';
import { CheckDefinition } from 'src/interfaces/Check.Interface';
import { Device, DeviceType } from 'src/interfaces/Device.Interface';
import { SetupContext } from '@vue/composition-api';
//interfaces
//configs
import { statusConfig } from 'src/config/statusConfig';

export const useDeviceHandling = (context: SetupContext) => {
  const deviceHaveValidDeliveryCheck = (device: Device) => {
    let deviceHaveValidDeliveryCheck = false;

    const getDeviceType = store.getters.deviceAdmin.getDeviceTypeById(
      device.type
    );

    if (getDeviceType instanceof DeviceType) {
      for (const checkDefinitionId of getDeviceType.checkDefinitions) {
        const checkDefinition =
          store.getters.deviceAdmin.getCheckDefinitionById(checkDefinitionId);

        if (checkDefinition instanceof CheckDefinition) {
          if (checkDefinition.type === 'deliveryCheck') {
            for (const lastCheck of device.lastChecks) {
              if (lastCheck.type === 'deliveryCheck') {
                //
                const today = new Date();
                const differenceTime =
                  new Date(lastCheck.date).getTime() - today.getTime();
                const differenceDays = differenceTime / (1000 * 3600 * 24);
                const lstCheckDeliveryDays = differenceDays;
                //

                if (lstCheckDeliveryDays < 30) {
                  return;
                } else {
                  deviceHaveValidDeliveryCheck = true;
                }
              }
            }
          }
        }
      }
    }
    return deviceHaveValidDeliveryCheck;
  };

  const deviceHaveDeliveryCheck = (device: Device) => {
    let needDeliveryCheck = false;

    const devType = store.getters.deviceAdmin.getDeviceTypeById(device.type);

    if (device.assignedTo.type !== 'user')
      if (devType instanceof DeviceType) {
        const checkList = devType.checkDefinitions;

        checkList.forEach((checkDefId) => {
          const checkDef =
            store.getters.deviceAdmin.getCheckDefinitionById(checkDefId);
          if (checkDef instanceof CheckDefinition) {
            if (checkDef.type === 'deliveryCheck') needDeliveryCheck = true;
          }
        });
      }
    return needDeliveryCheck;
  };

  const assignedTypeOptions: OptionValue[] = [
    new OptionValue(
      'system',
      context.root.$t('device.assigned.toOrganisation').toString()
    ),
    new OptionValue('user', context.root.$t('general.user').toString()),
    new OptionValue(
      'serviceProvider',
      context.root.$t('doctype.serviceProvider').toString()
    ),
  ];

  const advancedAssignedTypeOptions: OptionValue[] = [
    new OptionValue('', 'Jede Zuweisung'),
    ...assignedTypeOptions,
  ];

  const deviceTypeOptions = computed(() => {
    const list: OptionValue[] = [];
    for (const iterator of store.state.deviceAdmin.deviceTypeList) {
      list.push(new OptionValue(iterator._id, iterator.label));
    }
    return list;
  });

  const advancedDeviceTypeOptions = computed(() => {
    const list = [
      new OptionValue('', 'Alle Gerätetypen'),
      ...deviceTypeOptions.value,
    ];
    return list;
  });

  const statusOptions = computed(() => {
    const list: OptionValue[] = [];
    for (const [key, value] of Object.entries(statusConfig)) {
      const val = value as StatusValue;
      list.push(
        new OptionValue(key, context.root.$t('status.' + val.label).toString())
      );
    }
    return list;
  });
  const advancedStatusOptions = computed(() => {
    const list = [new OptionValue('', 'Jeder Status'), ...statusOptions.value];
    return list;
  });

  return {
    deviceHaveValidDeliveryCheck,
    deviceHaveDeliveryCheck,
    assignedTypeOptions,
    advancedAssignedTypeOptions,
    deviceTypeOptions,
    advancedDeviceTypeOptions,
    statusOptions,
    advancedStatusOptions,
  };
};
