
























import { defineComponent } from '@vue/composition-api';
import { Task } from 'src/interfaces/Common.Interface';

export default defineComponent({
  name: 'GWTaskList',
  components: {},
  props: {
    taskList: {
      type: Array as () => Array<Task>,
      //  type: Task[],
      default: [new Task(false, 'asdasd')],
    },
  },
  setup(props, context) {
    const changeDone = (
      indexFromTask: number,
      valueFromCheckboxInputEvent: boolean
    ) => {
      let payload25 = {
        index: indexFromTask,
        val: valueFromCheckboxInputEvent,
      };
      context.emit('change-done', payload25);
    };
    return { changeDone };
  },
});
