import { OutgoingCartValue } from 'src/interfaces/Shipping.Interface';

const state = {
  deviceList: [],
  partnerConfigList: [],
  // deviceCounter: 0,
  deviceTypeList: [],
  // deviceTypesCounter: 0,
  shippingTaskList: [],
  isLoadingDataFromServer: false,
  userList: [],
  systemUserList: [],
  customerList: [],
  reportList: [],
  formularList: [],
  auth: undefined,
  locationList: [],
  currentServiceRequest: undefined,
  currentServiceForm: undefined,
  checkDefinitionList: [],
  notificationList: [],
  actionRequestList: [],
  //File Manager
  filesMetadata: [],
  //Logs
  applicationLogList: [],
  // lockedDocuments: [],
  serverStatus: {
    color: 'red',
    status: 'connection lost',
    message: '',
  },
  organisation: undefined,
  roleDefinition: undefined,

  serviceProviderList: [],
  outgoingCart: new OutgoingCartValue([], {
    name: '',
    assigned: {
      type: 'user',
      id: '',
    },
    address: {
      street: '',
      number: '',
      country: '',
      zip: '',
      city: '',
      state: '',
    },
  }),
  userSettings: { components: {} },
  doctypeList: [],
  roleConfigList: [],
};

export default state;
