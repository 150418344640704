

























































import { defineComponent } from '@vue/composition-api';
//components
//interfaces
//composition
import { OutgoingCartValue } from '../../interfaces/Shipping.Interface';

export default defineComponent({
  name: 'gwAddressCard',
  components: {},
  props: {
    outgoingValue: {
      type: Object as () => OutgoingCartValue,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    return {};
  },
});
