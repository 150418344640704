import { OptionValue } from './../../interfaces/Common.Interface';
import { moduleActionContext } from '../directStore';
import deviceAdmin from './index';
import axios from 'axios';
import { createClass } from 'src/utils/debug';
import { Notify } from 'quasar';
import { i18n } from 'src/boot/i18n';
//interfaces
import { DataResponse } from 'src/interfaces/Request.Interface';
import { RecordTypes } from 'src/interfaces/Common.Interface';
//configs
import { apiServer } from 'src/config/serverConfig';
// import { io } from 'socket.io-client';
import { PublicServiceRequest } from 'src/interfaces/Action.Interface';

const actions = {
  // eslint-disable-next-line
  refreshRecordList(context: any, doctype: string) {
    const { commit } = deviceAdminActionContext(context);
    //
    commit.setIsLoadingState(true);
    axios
      .post<DataResponse>(apiServer + '/get/record/all', {
        doctype: doctype,
      })
      .then((res) => {
        const list: RecordTypes[] = [];

        res.data.rows.forEach((doc) => {
          const newInstance = createClass(doc.value.doctype, doc.value);

          if (newInstance !== undefined) {
            list.push(newInstance);
          }
        });
        commit.setList({ list: list, name: doctype + 'List' });
        commit.setIsLoadingState(false);
      })
      .catch((e) => {
        commit.setIsLoadingState(false);
        throw Error(e);
      });
  },

  // eslint-disable-next-line
  updateActionRequest(context: any, updatedDocument: PublicServiceRequest) {
    // const { state } = deviceAdminActionContext(context);
    axios
      .post<DataResponse>(apiServer + '/update/action-request', {
        document: updatedDocument,
        isSucc: updatedDocument.formular.isSuccessful(),
      })
      .catch((e) => {
        throw new Error(e);
      });
  },
  deleteDocument(
    context: any, // eslint-disable-line
    payload: { id: string; rev: string; doctype: string }
  ) {
    // const { dispatch } = deviceAdminActionContext(context);
    // let succ = false;
    // Notify.create({
    //   spinner: true,
    //   message: 'Working...',
    //   timeout: 2000
    // });
    axios
      .post<DataResponse>(apiServer + '/delete/record', {
        // auth: store.state.deviceAdmin.auth.token,
        record: payload,
      })
      .then(() => {
        // Notify.create({
        //   message: 'Dokument erfolgreich gelöscht',
        //   color: 'positive',
        //   textColor: 'black',
        //   icon: 'delete'
        // });
      })
      .catch((e) => {
        Notify.create({
          message: 'Ein Fehler beim löschen ist aufgetreten',
          color: 'negative',
          textColor: 'white',
          icon: 'warning',
        });
        throw new Error(e);
      });
  },
  // eslint-disable-next-line
  refreshViewListForDoctype(context: any, doctype: string) {
    const { commit } = deviceAdminActionContext(context);
    commit.setList({ list: [], name: doctype + 'List' });
    // void dispatch.refreshViewList(doctype);
  },
  // eslint-disable-next-line
  setDoctypeList(context: any, list: string[]) {
    const { commit } = deviceAdminActionContext(context);
    const doctypeList: OptionValue[] = [];

    for (const entry of list) {
      doctypeList.push(
        new OptionValue(entry, i18n.t('doctype.' + entry).toString())
      );
    }
    commit.setDoctypeList(doctypeList);
  },
};

export default actions;
// eslint-disable-next-line
const deviceAdminActionContext = (context: any) =>
  moduleActionContext(context, deviceAdmin);
