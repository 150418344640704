import { RouteConfig } from 'vue-router';
//component pages
// components
import OrganisationCard from 'src/components/settings/organisation/OrganisationCard.vue';
import gwShippingSettingsCard from 'src/components/settings/shipping/gwShippingSettingsCard.vue';
import ActionRequestCard from 'src/components/checks/ActionRequestCard.vue';

import Formularcenter from 'src/pages/settings/FormularCenter.vue';
import Dashboard from 'src/pages/Dashboard.vue';

const routes: RouteConfig[] = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '/dashboard',
        component: Dashboard,
        meta: { viewName: 'dashboard' },
      },
      {
        path: '/insights',
        component: () => import('pages/Insights.vue'),
        meta: { viewName: 'insights' },
      },
      {
        path: '/reports',
        component: () => import('src/pages/MainPage.vue'),
        meta: { viewName: 'reports' },
        children: [
          {
            path: '',
            component: () => import('components/report/ReportTable.vue'),
            meta: { viewName: 'reports' },
          },
          {
            path: ':baseRecordId',
            component: () => import('src/components/report/ReportCard.vue'),
            props: true,
            meta: { viewName: 'reports' },
          },
        ],
      },
      {
        path: '/devices',
        meta: { viewName: 'devices' },
        component: () => import('src/pages/MainPage.vue'),
        children: [
          {
            path: '',
            component: () => import('src/components/device/DeviceTable.vue'),
            meta: { viewName: 'devices' },
          },
          {
            path: 'detail/:deviceId',
            meta: {
              viewName: 'devices',
            },
            component: () =>
              import('src/components/device/DeviceDetailCard.vue'),
            props: true,
          },
          {
            path: 'edit/:deviceId?',
            meta: {
              viewName: 'devices',
            },
            component: () => import('src/components/device/DeviceCard.vue'),
            props: true,
          },
        ],
      },
      {
        path: '/location',
        meta: { viewName: 'location', requiresAuth: true },
        component: () => import('pages/LocationManagement.vue'),
      },
      {
        path: '/notifications',
        meta: { viewName: 'notifications' },
        component: () => import('components/notification/NotificationList.vue'),
      },
      {
        path: '/shipping',
        component: () => import('src/pages/MainPage.vue'),
        children: [
          {
            path: 'outgoing/:shippingTaskId?',
            component: () => import('components/shipping/OutgoingCard.vue'),
            props: true,
          },
          // {
          //   path: 'outgoing',
          //   component: () => import('components/shipping/OutgoingCard.vue'),
          // },
          {
            path: 'incoming',
            component: () => import('components/shipping/IncomingCard.vue'),
          },
          {
            path: 'planning',
            component: () => import('components/shipping/ShippingPlanning.vue'),
          },
          {
            path: 'shippingTask',
            component: () => import('components/shipping/ShippingTask.vue'),
          },
        ],
      },
      {
        path: '/settings',
        component: () => import('components/settings/SettingsCard.vue'),
        children: [
          {
            path: 'formularcenter',
            component: Formularcenter,
            meta: { viewName: 'formularcenter' },
          },
          {
            path: 'deviceTypes',
            component: () =>
              import('src/components/deviceType/DeviceTypeTable.vue'),
            meta: { viewName: 'deviceTypes' },
          },
          {
            path: 'deviceTypes/create',
            component: () =>
              import('src/components/deviceType/DeviceTypeCard.vue'),
            meta: { viewName: 'deviceTypes' },
          },
          {
            path: 'deviceTypes/edit/:baseRecordId',
            component: () =>
              import('src/components/deviceType/DeviceTypeCard.vue'),
            props: true,
            meta: { viewName: 'deviceTypes' },
          },
          {
            path: 'checkDefinitions',
            component: () =>
              import('src/components/checkDefinition/CheckDefinitionTable.vue'),
            meta: { viewName: 'checkDefinitions' },
          },
          {
            path: 'checkDefinition/create',
            meta: { viewName: 'checkDefinitions' },
            component: () =>
              import('src/components/checkDefinition/CheckDefinitionCard.vue'),
          },
          {
            path: 'checkDefinition/edit/:baseRecordId',
            meta: { viewName: 'checkDefinitions' },
            component: () =>
              import('src/components/checkDefinition/CheckDefinitionCard.vue'),
            props: true,
          },
          {
            path: 'systemUser',
            meta: { viewName: 'systemUser' },
            component: () => import('src/components/user/SystemUserTable.vue'),
          },
          {
            path: 'systemUser/create',
            meta: { viewName: 'systemUser' },
            component: () => import('src/components/user/SystemUserCard.vue'),
          },
          {
            path: 'systemUser/edit/:baseRecordId',
            meta: { viewName: 'systemUser' },
            component: () => import('src/components/user/SystemUserCard.vue'),
            props: true,
          },
          {
            path: 'organisation',
            meta: { viewName: 'organisation' },
            component: OrganisationCard,
          },
          {
            path: 'shipping',
            meta: { viewName: 'organisation' },
            component: gwShippingSettingsCard,
          },
          {
            path: 'roleConfig',
            meta: { viewName: 'roleConfigs' },
            component: () =>
              import('src/components/roleConfig/RoleConfigTable.vue'),
          },
          {
            path: 'roleConfig/create',
            meta: { viewName: 'roleConfigs' },
            component: () =>
              import('src/components/roleConfig/RoleConfigCard.vue'),
          },
          {
            path: 'roleConfig/edit/:baseRecordId',
            meta: { viewName: 'roleConfigs' },
            component: () =>
              import('src/components/roleConfig/RoleConfigCard.vue'),
            props: true,
          },
          {
            path: 'import',
            meta: { viewName: 'import' },
            component: () => import('src/components/importer/Importer.vue'),
          },
          {
            path: 'audit',
            component: () => import('src/components/audit/AuditList.vue'),
          },
          {
            path: 'jobs',
            component: () =>
              import('src/components/scheduledJob/ScheduledJobList.vue'),
          },
        ],
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: '/checks',
        meta: { viewName: 'checks' },
        component: () => import('pages/MainPage.vue'),
        children: [
          {
            path: '',
            meta: { viewName: 'checks' },
            component: () => import('components/checks/ChecksOverview.vue'),
            props: true,
          },
          {
            path: 'edit/:actionRequestId',
            meta: { viewName: 'checks' },
            component: ActionRequestCard,
            props: true,
          },
        ],
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/qrcodeview',
    component: () => import('pages/QRCodeView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/user',
    meta: { viewName: 'user', requiresAuth: true },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        meta: { viewName: 'user' },
        component: () => import('src/pages/user/User.vue'),
      },
      {
        path: 'edit/:baseRecordId',
        meta: { viewName: 'user' },
        component: () => import('src/components/user/UserCard.vue'),
        props: true,
      },
      {
        path: 'create',
        meta: { viewName: 'user' },
        component: () => import('src/components/user/UserCard.vue'),
      },
    ],
  },
  {
    path: '/customers',
    meta: { viewName: 'customers', requiresAuth: true },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        meta: { viewName: 'customers' },
        component: () => import('src/pages/user/Customers.vue'),
      },
      {
        path: 'edit/:customerId',
        meta: { viewName: 'customers' },
        component: () => import('src/components/customer/CustomerCard.vue'),
        props: true,
      },
      {
        path: 'create',
        meta: { viewName: 'customers' },
        component: () => import('src/components/customer/CustomerCard.vue'),
      },
    ],
  },
  {
    path: '/service-provider',
    meta: { viewName: 'serviceProvider', requiresAuth: true },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        meta: { viewName: 'serviceProvider' },
        component: () => import('src/pages/user/ServiceProvider.vue'),
      },
      {
        path: 'edit/:baseRecordId',
        meta: { viewName: 'serviceProvider' },
        component: () =>
          import('src/components/serviceProvider/ServiceProviderCard.vue'),
        props: true,
      },
      {
        path: 'create',
        meta: { viewName: 'serviceProvider' },
        component: () =>
          import('src/components/serviceProvider/ServiceProviderCard.vue'),
      },
    ],
  },
  {
    path: '/qr-code-generator',
    component: () => import('src/components/tools/QrCodeGenerator.vue'),
  },
  {
    path: '/quick-action',
    component: () => import('layouts/FormularLayout.vue'),
    children: [
      {
        path: '',
        meta: { viewName: 'quickAction' },
        component: () => import('pages/QuickAction.vue'),
      },
    ],
    meta: {
      requiresAuth: false,
    },
  },
  //AUTH
  {
    path: '/login',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      { path: '', component: () => import('pages/authentication/Login.vue') },
    ],
  },
  {
    path: '/registration',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/authentication/Registration.vue'),
      },
    ],
  },
  {
    path: '/password-reset',
    component: () => import('layouts/AuthLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('pages/authentication/PasswordReset.vue'),
      },
    ],
  },
  {
    path: '/service-request',
    component: () => import('layouts/FormularLayout.vue'),
    children: [
      { path: '', component: () => import('pages/ServiceRequest.vue') },
    ],
    meta: {
      requiresAuth: false,
    },
  },

  {
    path: '/formular-creator',
    meta: { viewName: 'formularcenter', requiresAuth: true },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        meta: { viewName: 'formularcenter' },
        component: () => import('pages/FormularCreator.vue'),
      },
    ],
  },
  {
    path: '/formular-center',
    meta: { viewName: 'formularcenter', requiresAuth: true },
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        meta: { viewName: 'formularcenter' },
        component: () => import('src/pages/settings/FormularCenter.vue'),
      },
    ],
  },

  {
    path: 'error',
    component: () => import('src/pages/info/Error404.vue'),
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    component: () => import('src/pages/info/Error404.vue'),
  },
];

export default routes;
