import { render, staticRenderFns } from "./BasicSentNotificationList.vue?vue&type=template&id=4d3aff08&scoped=true&"
import script from "./BasicSentNotificationList.vue?vue&type=script&lang=ts&"
export * from "./BasicSentNotificationList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d3aff08",
  null
  
)

export default component.exports
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QTabs,QTab,QScrollArea,QTabPanels,QTabPanel,QList,QItem,QItemSection,QItemLabel,QIcon,QBadge,QBtn});
