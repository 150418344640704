


































































































































import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from '@vue/composition-api';
import _ from 'lodash';
import store from 'src/store/directStore';
//components
import FormShower from 'components/formular/FormShower.vue';
import { Formular } from 'src/interfaces/Form.Interface';
//compositions
// import useFileCreationHandling from 'src/compositions/FileCreationHandling';
import { useRequestHandling } from 'src/compositions/RequestHandling';
import { usePrintHandling } from 'src/compositions/PrintHandling';
import { OptionValue } from 'src/interfaces/Common.Interface';
export default defineComponent({
  name: 'FormularCenter',
  components: {
    FormShower,
  },
  setup(props, context) {
    const { refreshRecordList, deleteDocument } = useRequestHandling();
    const { printFormular } = usePrintHandling();
    onMounted(async () => {
      await refreshRecordList('formular');
    });

    const showFormCreator = ref<boolean>(false);
    const showWrongAnswers = ref<boolean>(false);

    const previewFormular = ref<Formular>();

    // const newBlankoForm = () => {
    //   context.root.$router
    //     .push('/formular-center/formular-creator')
    //     .catch(e => {
    //       throw new Error(e);
    //     });
    // };

    const simulatedUserType = ref<string>('self');

    const simulateUserOptions = ref<OptionValue[]>([
      new OptionValue('self', 'Gerätenutzer'),
      new OptionValue('intern', 'Intern/Organisation'),
    ]);

    const newBlankoForm = () => {
      context.root.$router.push('/formular-creator').catch((e) => {
        throw new Error(e);
      });
    };

    const formList = computed(() => {
      return context.root.$store.direct.state.deviceAdmin.formularList;
    });

    const onClickEditForm = (formId: string) => {
      context.root.$router
        .push('/formular-creator?form=' + formId)
        .catch((e) => {
          throw new Error(e);
        });
    };
    const previewFormMode = ref<boolean>(false);

    const previewForm = (formId: string) => {
      const find = store.getters.deviceAdmin.getFormularById(formId);
      if (find instanceof Formular) {
        previewFormular.value = _.cloneDeep(find);
      }
      previewFormMode.value = true;
    };

    const downloadJSON = (formId: string) => {
      let tmpForm = store.getters.deviceAdmin.getFormularById(formId);
      if (tmpForm instanceof Formular) {
        var dataStr =
          'data:text/json;charset=utf-8,' +
          encodeURIComponent(JSON.stringify(tmpForm));
        var dlAnchorElem = document.createElement('a');
        dlAnchorElem.setAttribute('href', dataStr);
        dlAnchorElem.setAttribute('download', tmpForm.label + '.json');
        dlAnchorElem.click();
      }
    };

    const showDeleteFormDialog = ref<boolean>(false);
    const deleteFormId = ref<string>();

    const onClickDeleteForm = (val: string) => {
      deleteFormId.value = val;
      showDeleteFormDialog.value = true;
    };

    const deleteFormular = async () => {
      if (deleteFormId.value) {
        await deleteDocument(deleteFormId.value, 'formular');
      }
    };

    const submitPreviewForm = () => {
      previewFormMode.value = false;
    };

    const printForm = (id: string) => {
      let getForm = store.getters.deviceAdmin.getFormularById(id);
      if (getForm instanceof Formular) {
        printFormular(getForm);
      }
    };

    return {
      newBlankoForm,
      showFormCreator,
      showWrongAnswers,
      formList,
      onClickEditForm,
      previewFormMode,
      previewForm,
      downloadJSON,
      showDeleteFormDialog,
      onClickDeleteForm,
      deleteFormular,
      submitPreviewForm,
      printForm,
      previewFormular,
      simulatedUserType,
      simulateUserOptions,
    };
  },
});
