export const allViews: string[] = [
  'dashboard',
  'insights',
  'reports',
  'devices',
  'checks',
  'location',
  'user',
  'customers',
  'serviceProvider',
  'shippingPlanning',
  'shippingTask',
  'incoming',
  'outgoing',
  'settings',
  'formularcenter',
  'deviceTypes',
  'checkDefinitions',
  'organisation',
  'roleConfigs',
  'systemUser',
  'import',
  'account',
  'notifications',
];

export const mapViewToRoute = (viewName: string) => {
  switch (viewName) {
    case 'shippingPlanning':
    case 'shippingTask':
    case 'incoming':
    case 'outgoing':
      return `/shipping/${viewName}`;
      break;

    case 'formularcenter':
    case 'deviceTypes':
    case 'checkDefinitions':
    case 'organisation':
    case 'roleConfigs':
    case 'systemUser':
    case 'import':
    case 'account':
      return `/settings/${viewName}`;
      break;

    default:
      return `/${viewName}`;
      break;
  }
};
