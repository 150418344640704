
































import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
} from '@vue/composition-api';
import store from 'src/store/directStore';
//components
import VueQrcode from 'vue-qrcode';
//interfaces
import { Device } from 'src/interfaces/Device.Interface';
//configs
import { frontend } from 'src/config/serverConfig';
//compositions
import { usePrintHandling } from 'src/compositions/PrintHandling';
import { useRequestHandling } from 'src/compositions/RequestHandling';

export default defineComponent({
  name: 'DeviceQrCode',
  components: {
    VueQrcode,
  },
  props: {
    deviceId: {
      type: String,
      required: true,
    },
    showQrCode: {
      type: Boolean,
      default: true,
    },
    showPrintButton: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { printQRCode } = usePrintHandling();
    const { getDocument } = useRequestHandling();

    const isLoading = ref<boolean>(false);
    const showQRCodeForPrint = ref<boolean>(false);
    const device = ref<Device>();

    watch(
      () => props.deviceId,
      async () => {
        isLoading.value = true;
        const tmp = await getDocument(props.deviceId, 'device');
        console.log('tmp', tmp);

        if (tmp instanceof Device) {
          device.value = tmp;
          isLoading.value = false;
        }
      }
    );

    onMounted(async () => {
      isLoading.value = true;
      const tmp = await getDocument(props.deviceId, 'device');
      console.log('tmp', tmp);

      if (tmp instanceof Device) {
        device.value = tmp;
        isLoading.value = false;
      }
    });

    const onClickPrint = () => {
      if (device.value && device.value._id) {
        if (!props.showQrCode) {
          showQRCodeForPrint.value = true;
          printQRCode(device.value._id, device.value?.inventoryNumber);
          showQRCodeForPrint.value = false;
        } else {
          printQRCode(device.value._id, device.value?.inventoryNumber);
        }
      }
    };

    const qrCodeUrl = computed(() => {
      if (device.value && device.value._id && store.state.deviceAdmin.auth)
        return `${frontend}quick-action/?id=${device.value._id}&orga=${store.state.deviceAdmin.auth.organisation}`;
    });

    return {
      isLoading,
      showQRCodeForPrint,
      device,
      onClickPrint,
      qrCodeUrl,
    };
  },
});
