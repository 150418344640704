


















import { defineComponent, ref } from '@vue/composition-api';
import axios from 'axios';
//configs
import { apiServer } from 'src/config/serverConfig';
import { UserContentLink } from 'src/interfaces/Request.Interface';
// import { VueEditor } from 'vue2-editor';
//interfaces
// interface TextEditorToolbarConfig {
//   [key: string]: string | string[] | boolean;
// }
export default defineComponent({
  name: 'gwTextEditor',
  components: {
    // VueEditor
  },
  props: {
    value: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(probs, context) {
    const change = async (val: string) => {
      function getAttrFromString(str: string, node: string, attr: string) {
        var regex = new RegExp('<' + node + ' .*?' + attr + '="(.*?)"', 'gi'),
          result,
          res = [];
        while ((result = regex.exec(str))) {
          res.push(result[1]);
        }
        return res;
      }
      function isValidHttpUrl(value: string) {
        let url;
        try {
          url = new URL(value);
        } catch (_) {
          return false;
        }
        return url.protocol === 'http:' || url.protocol === 'https:';
      }

      var arrayOfImageSrcs = getAttrFromString(val, 'img', 'src');

      for (const imgString of arrayOfImageSrcs) {
        if (!isValidHttpUrl(imgString)) {
          isLoading.value = true;

          let linkData = await axios.post<UserContentLink>(
            apiServer + '/usc/generate/public/uscLink',
            {
              base64Image: imgString,
            },
            {
              withCredentials: true,
            }
          );

          const link = linkData.data.link;
          if (typeof link === 'string') {
            val = val.replace(imgString, link);
          }

          isLoading.value = false;
        }
      }

      context.emit('input', val);
    };

    const isLoading = ref<boolean>(false);

    const toolbarConfig = ref([
      ['left', 'center', 'right', 'justify'],
      [
        //   'quote',
        'unordered',
        'ordered',
        // 'outdent',
        // 'indent'
      ],
      ['undo', 'redo'],
      ['viewsource'],
      // ['insertImage']
      //   [
      //     {
      //       label: context.root.$q.lang.editor.align,
      //       icon: context.root.$q.iconSet.editor.align,
      //       fixedLabel: true,
      //       list: 'only-icons',
      //       options: ['left', 'center', 'right', 'justify']
      //     },
      //     {
      //       label: context.root.$q.lang.editor.align,
      //       icon: context.root.$q.iconSet.editor.align,
      //       fixedLabel: true,
      //       options: ['left', 'center', 'right', 'justify']
      //     }
      //   ],
      //   ['bold', 'italic', 'strike', 'underline', 'subscript', 'superscript'],
      //   ['token', 'hr', 'link', 'custom_btn'],
      //   ['print', 'fullscreen'],
      //   [
      //     {
      //       label: context.root.$q.lang.editor.formatting,
      //       icon: context.root.$q.iconSet.editor.formatting,
      //       list: 'no-icons',
      //       options: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'code']
      //     },
      //     {
      //       label: context.root.$q.lang.editor.fontSize,
      //       icon: context.root.$q.iconSet.editor.fontSize,
      //       fixedLabel: true,
      //       fixedIcon: true,
      //       list: 'no-icons',
      //       options: [
      //         'size-1',
      //         'size-2',
      //         'size-3',
      //         'size-4',
      //         'size-5',
      //         'size-6',
      //         'size-7'
      //       ]
      //     },
      //     {
      //       label: context.root.$q.lang.editor.defaultFont,
      //       icon: context.root.$q.iconSet.editor.font,
      //       fixedIcon: true,
      //       list: 'no-icons',
      //       options: [
      //         'default_font',
      //         'arial',
      //         'arial_black',
      //         'comic_sans',
      //         'courier_new',
      //         'impact',
      //         'lucida_grande',
      //         'times_new_roman',
      //         'verdana'
      //       ]
      //     },
      //     'removeFormat'
      //   ],
    ]);
    return { change, isLoading, toolbarConfig };
  },
});
