import { BaseRecord } from './Common.Interface';

// NEW

export class RoleConfig extends BaseRecord {
  name: string;
  views: string[];
  startPage: string;
  constructor(name: string, views: string[] = [], startPage: string) {
    super('roleConfig');
    this.name = name;
    this.views = views;
    this.startPage = startPage;
  }
}

//

export class RoleDefinition {
  _id?: string;
  _rev?: string;
  doctype: 'roleDefinition';
  definition: RoleConfigValue;
  version?: number;
  constructor(version?: number) {
    this.doctype = 'roleDefinition';
    this.definition = new RoleConfigValue();
    this.version = version;
  }
}
export class RoleConfigValue {
  [key: string]: RoleDef;

  admin: RoleDef;

  constructor() {
    this.admin = new RoleDef();
  }
}

export class RoleDef {
  [key: string]: RoleRecordConfigValue;
  create: RoleRecordConfigValue;
  // create: boolean;
  read: RoleRecordConfigValue;
  update: RoleRecordConfigValue;
  delete: RoleRecordConfigValue;
  // delete: boolean;
  constructor() {
    this.create = {};
    this.read = {};
    this.update = {};
    this.delete = {};
  }
}

export interface RoleRecordConfigValue {
  [key: string]: RoleRecordPropertyConfig;
}

export interface RoleRecordPropertyConfig {
  [key: string]: boolean;
}
