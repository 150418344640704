








































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import store from 'src/store/directStore';
import _ from 'lodash';
//components
//interfaces
import { Device, DeviceType } from 'src/interfaces/Device.Interface';
import { OutgoingCartValue } from '../../interfaces/Shipping.Interface';
//composition

export default defineComponent({
  name: 'gwShippingTable',
  components: {},
  props: {
    outgoingValue: {
      type: Object as () => OutgoingCartValue,
      required: true,
    },
  },
  setup(props, context) {
    console.log('ShippingTable outgoingValue', props.outgoingValue);

    const search = ref<string>();

    const selectIdx = ref<number>(-1);

    const onChange = (newVal: OutgoingCartValue) => {
      context.emit('change', newVal);
    };

    const replacementDeviceOptions = computed(() => {
      let list: { value: string; label: string; type: string }[] = [];
      for (const device of store.state.deviceAdmin.deviceList) {
        if (
          device.status === 1 &&
          (device.assignedTo.type === '' || device.assignedTo.type === 'system')
        ) {
          const deviceType = store.getters.deviceAdmin.getDeviceTypeById(
            device.type
          );
          if (deviceType instanceof DeviceType) {
            if (search.value) {
              if (
                device.inventoryNumber
                  .toLocaleLowerCase()
                  .includes(search.value.toLocaleLowerCase())
              ) {
                list.push({
                  value: device._id,
                  label: device.inventoryNumber,
                  type: deviceType.label,
                });
              }
            } else {
              list.push({
                value: device._id,
                label: device.inventoryNumber,
                type: deviceType.label,
              });
            }
          }
        }
      }
      return list;
    });

    const changeReplacementDevice = (idx: number, deviceId: string) => {
      let editOutgoingCard = _.cloneDeep(props.outgoingValue);
      let getDevice = store.getters.deviceAdmin.getDeviceById(deviceId);
      if (getDevice instanceof Device) {
        editOutgoingCard.shippingList[idx].newDevice = getDevice;
      }
      onChange(editOutgoingCard);
      selectIdx.value = -1;
    };

    const addInitalNewDevice = (idx: number) => {
      selectIdx.value = idx;
    };

    return {
      search,
      selectIdx,
      replacementDeviceOptions,
      changeReplacementDevice,
      addInitalNewDevice,
    };
  },
});
