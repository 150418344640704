import store from 'src/store/directStore';

export default function () {
  const logout = () => {
    return;
  };

  const can = (
    operation: 'create' | 'read' | 'update' | 'delete',
    doctype: string,
    property?: string
  ) => {
    if (
      store.state.deviceAdmin.roleDefinition &&
      store.state.deviceAdmin.auth
    ) {
      const role = store.state.deviceAdmin.auth.role;
      const roleDefinition =
        store.state.deviceAdmin.roleDefinition.definition[role];

      console.log('store', store);

      switch (operation) {
        case 'create':
        case 'delete': {
          for (const value of Object.values(
            roleDefinition[operation][doctype]
          )) {
            if (!value) return false;
          }
          return true;
          break;
        }
        case 'update':
        case 'read': {
          if (property) {
            const result = roleDefinition[operation][doctype][property];
            return result;
          } else {
            for (const value of Object.values(
              roleDefinition[operation][doctype]
            )) {
              if (!value) return false;
            }
            return true;
          }
          break;
        }
      }
    } else return false;
  };

  // const canView = (view: string) => {
  //   if (store.state.deviceAdmin.auth) {
  //     const auth = store.state.deviceAdmin.auth;
  //     const role = store.state.deviceAdmin.roleConfigList.find(
  //       (entry) => entry.name === auth.role
  //     );
  //     if (role) {
  //       if (role.views.includes(view)) return true;
  //     }
  //   }
  //   return false;
  // };
  const canView = (viewName: string) => {
    if (store.state.deviceAdmin.auth) {
      const auth = store.state.deviceAdmin.auth;
      const role = store.state.deviceAdmin.roleConfigList.find(
        (entry) => entry.name === auth.role
      );
      if (role) {
        // for (const viewName of role.views) {
        //   if (viewPath.search(viewName) !== -1) return true;
        // }
        if (role.views.includes(viewName)) return true;
      }
    }
    return false;
  };

  return { logout, can, canView };
}
