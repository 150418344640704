

























































































































































































































































































































































































































































































































































































import {
  defineComponent,
  onMounted,
  ref,
  computed,
} from '@vue/composition-api';
import store from 'src/store/directStore';
import axios from 'axios';
import _ from 'lodash';
import Vue from 'vue';
//components
import IntervalPicker from 'src/components/basic/IntervalPicker.vue';
//interfaces
import {
  ExtendInterval,
  OrganisationValue,
} from 'src/interfaces/Settings.Interface';
//configs
import { apiServer } from 'src/config/serverConfig';
//composition
import { useRequestHandling } from 'src/compositions/RequestHandling';
import { OptionValue, RecordTypes } from 'src/interfaces/Common.Interface';

export default defineComponent({
  name: 'OrganisationCard',
  components: { IntervalPicker },
  props: {},
  setup(props, context) {
    const { updateDocument } = useRequestHandling();

    const localOrga = ref<OrganisationValue | undefined>(
      _.cloneDeep(store.state.deviceAdmin.organisation)
    );

    const loading = ref<boolean>(false);

    const testMailReceiver = ref<string>();

    const selectedTestMailTemplate = ref<string>('test');
    const mailTemplateList = computed(() => {
      let list: OptionValue[] = [];
      list.push(new OptionValue('test', 'Testmail'));
      list.push(new OptionValue('replacement', 'Ersatzgeräte'));
      list.push(
        new OptionValue('replacementFeedback', 'Bestätigung Ersatzgeräte')
      );
      list.push(new OptionValue('informShipping', 'Versandinformation'));
      list.push(new OptionValue('fillCheckForm', 'Prüfungsformular ausfüllen'));
      list.push(new OptionValue('reminder', 'Erinnerung'));
      list.push(new OptionValue('escalation', 'Eskalation'));
      return list;
    });

    onMounted(() => {
      if (localOrga.value) {
        console.log('organisation', localOrga.value);

        testMailReceiver.value = localOrga.value.mailServer.user;

        if (!localOrga.value.settings.escalationReportIntervall) {
          const interval: ExtendInterval = {
            type: 'weekly',
            repeatOn: 1,
            options: [],
          };
          Vue.set(
            localOrga.value.settings,
            'escalationReportIntervall',
            interval
          );
        } else {
          // temporary fix [GWD-1746]
          if (localOrga.value.settings.escalationReportIntervall) {
            if (
              typeof localOrga.value.settings.escalationReportIntervall
                .options === 'boolean'
            ) {
              const interval: ExtendInterval = {
                type: 'weekly',
                repeatOn: 1,
                options: [],
              };
              Vue.set(
                localOrga.value.settings,
                'escalationReportIntervall',
                interval
              );
            }
          }
        }
      }
    });

    const editMode = ref<boolean>(false);

    const startEdit = () => {
      editMode.value = !editMode.value;
    };

    const changeProp = (name: string, val: string) => {
      if (localOrga.value) {
        localOrga.value[name] = val;
      }
    };

    const changeOrganisationAddress = (name: string, val: string) => {
      if (localOrga.value) localOrga.value.adress[name] = val;
    };

    const submit = async () => {
      loading.value = true;
      if (localOrga.value) {
        await updateDocument(localOrga.value);
      }
      loading.value = false;
    };

    const toggleImport = async () => {
      await axios.get<RecordTypes[]>(apiServer + '/control/import', {
        withCredentials: true,
      });
    };

    const toggleBackup = async () => {
      await axios.get<RecordTypes[]>(apiServer + '/control/create/backup', {
        withCredentials: true,
      });
    };

    // const restoreBackup = async () => {
    //   await axios.get<RecordTypes[]>(apiServer + '/control/restore/backup', {
    //     withCredentials: true,
    //   });
    // };

    const changeSetting = (
      propName: string,
      value: boolean | string | number
    ) => {
      if (localOrga.value) {
        Vue.set(localOrga.value.settings, propName, value);
      }
    };

    const addReportReceiver = () => {
      if (localOrga.value) {
        if (!localOrga.value.settings.reportReceiver) {
          let newVal: string[] = [];
          newVal.push('');
          // localOrga.value.settings.reportReceiver = newVal;
          Vue.set(localOrga.value.settings, 'reportReceiver', newVal);
        } else {
          // localOrga.value.settings.reportReceiver.push('');
          let newVal: string[] = localOrga.value.settings.reportReceiver;
          newVal.push('');
        }
      }
    };

    const deleteReportReceiver = (idx: number) => {
      if (localOrga.value && localOrga.value.settings.reportReceiver) {
        let newVal: string[] = localOrga.value.settings.reportReceiver;
        newVal.splice(idx, 1);
        Vue.set(localOrga.value.settings, 'reportReceiver', newVal);
      }
    };

    const sendTestMail = async () => {
      loading.value = true;
      await axios
        .post(
          apiServer + '/mail/testmail',
          {
            template: selectedTestMailTemplate.value,
            receiver: testMailReceiver.value,
          },
          {
            withCredentials: true,
          }
        )
        .then(() => {
          context.root.$q.notify({
            type: 'positive',
            message: 'Erfolgreich',
            position: 'top',
          });
        })
        .catch((e) => {
          context.root.$q.notify({
            type: 'negative',
            message: 'Fehler',
            position: 'top',
          });
          loading.value = false;
          throw new Error(e);
        });

      loading.value = false;
    };

    return {
      testMailReceiver,
      localOrga,
      editMode,
      startEdit,
      changeProp,
      changeOrganisationAddress,
      submit,
      toggleImport,
      toggleBackup,
      // restoreBackup,
      changeSetting,
      addReportReceiver,
      deleteReportReceiver,
      loading,
      sendTestMail,
      selectedTestMailTemplate,
      mailTemplateList,
    };
  },
});
