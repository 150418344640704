





import { defineComponent } from '@vue/composition-api';

import Vue from 'vue';
// import { gwDebug } from 'src/utils/customDebugger';

import { gwDebugger, log } from 'src/utils/customDebugger';

const debuggerFunctions = { log };

window.my = debuggerFunctions;

// declare global {
//   interface Window {
//     my: MyDebugger;
//   }
// }

declare global {
  interface Window {
    my: gwDebugger;
  }
}

import VueApexCharts from 'vue-apexcharts';
Vue.use(VueApexCharts);
Vue.component('apexchart', VueApexCharts);

import VueTour from 'vue-tour';
require('vue-tour/dist/vue-tour.css');
Vue.use(VueTour);

// import VueI18n from 'vue-i18n';
// Vue.use(VueI18n);

// import ElementUI from 'element-ui';
// import ElTiptap from 'element-tiptap';
// // const { ElementTiptapPlugin } = require('element-tiptap');
// // import ElementUI's styles
// import 'element-ui/lib/theme-chalk/index.css';
// // import this package's styles
// import 'element-tiptap/lib/index.css';

// use ElementUI's plugin
// Vue.use(ElementUI);
// // use this package's plugin
// Vue.use(ElementTiptapPlugin, {
//   /* plugin options */
// });
// Now you register `'el-tiptap'` component globally.

// window.my = { customDebugLog };
export default defineComponent({
  name: 'App',
});
