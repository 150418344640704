























































































































import {
  defineComponent,
  ref,
  computed,
  onMounted,
} from '@vue/composition-api';
import store from 'src/store/directStore';
import Vue from 'vue';
import _ from 'lodash';
//components
import gwTabCardHorizontal from 'components/basic/tabCards/gwTabCardHorizontal.vue';
import gwFormFieldCreator from 'src/components/formular/gwFormFieldCreator.vue';
import FormShower from 'components/formular/FormShower.vue';
import DeviceQrCode from 'components/device/DeviceQrCode.vue';
import ShippingDeviceSelect from 'src/components/shipping/ShippingDeviceSelect.vue';
import ShippingDeviceTable from 'src/components/shipping/ShippingDeviceTable.vue';
//interfaces
import { Formular } from 'src/interfaces/Form.Interface';
import {
  Device,
  DeviceList,
  DeviceType,
  ShippingEntry,
} from 'src/interfaces/Device.Interface';
//compositions
import { usePrintHandling } from 'src/compositions/PrintHandling';
import { useDeviceHandling } from 'src/compositions/DeviceHandling';
import { useRequestHandling } from 'src/compositions/RequestHandling';
import { useBarcodeHandling } from 'src/compositions/BarcodeHandling';
import { CheckDefinition } from 'src/interfaces/Check.Interface';
//confígs
import { statusConfig } from 'src/config/statusConfig';
import ShippingReceiverSelect from './ShippingReceiverSelect.vue';
import { OutgoingReceiverValue } from 'src/interfaces/Shipping.Interface';

export default defineComponent({
  name: 'OutgoingCard',
  components: {
    gwFormFieldCreator,
    gwTabCardHorizontal,
    FormShower,
    DeviceQrCode,
    ShippingDeviceSelect,
    ShippingDeviceTable,
    ShippingReceiverSelect,
  },
  props: {
    shippingTaskId: {
      type: String,
    },
  },
  setup(props, context) {
    const { printQRCode } = usePrintHandling();
    const { deviceHaveValidDeliveryCheck, deviceHaveDeliveryCheck } =
      useDeviceHandling(context);

    const { updateDocument, getOldDevice } = useRequestHandling();

    const { parseQrCode } = useBarcodeHandling();

    onMounted(() => {
      if (props.shippingTaskId) {
        const shippingTask = store.state.deviceAdmin.shippingTaskList.find(
          (entry) => entry._id === props.shippingTaskId
        );
        if (shippingTask) {
          for (const shippingEntry of shippingTask.task.shippingList) {
            if (shippingEntry.newDevice) {
              selectedDeviceList.value.push(shippingEntry.newDevice);
            }
          }
          receiver.value = shippingTask.task.receiver;
        }
      }
    });

    const selectedDeviceList = ref<Device[]>([]);

    const receiver = ref<OutgoingReceiverValue>(new OutgoingReceiverValue());

    const shippToCheck = ref<boolean>(true);

    const checkedDeviceIds = ref<string[]>([]);
    const currentCheckDevice = ref<Device>(new Device(''));

    const isAssignedTo = ref<string>('user');

    const devicesArePartner = ref<boolean>(false);

    const deviceList = computed(() => {
      let list: DeviceList[] = [];
      store.state.deviceAdmin.deviceList.forEach((device) => {
        let isDeviceAlreadySelected = false;
        if (
          device.assignedTo.type !== 'user' &&
          device.assignedTo.type !== 'serviceProvider'
        ) {
          selectedDeviceList.value.forEach((selectedDev) => {
            if (selectedDev._id === device._id) isDeviceAlreadySelected = true;
          });
          if (device._id && !isDeviceAlreadySelected) {
            let canAdd = true;
            if (search.value) {
              if (
                !device.inventoryNumber
                  .toLocaleLowerCase()
                  .includes(search.value.toLocaleLowerCase())
              ) {
                canAdd = false;
              }
            }
            if (canAdd)
              list.push({
                id: device._id,
                inventoryNumber: device.inventoryNumber,
                typeId: device.type,
                typeLabel: store.getters.deviceAdmin.getDeviceTypeLabelById(
                  device.type
                ),
                doctype: device.doctype,
                statusCode: device.status,
                status: context.root
                  .$t('status.' + statusConfig[device.status].label)
                  .toString(),
                statusColor: statusConfig[device.status].color,
                belongDefinition: device.belongDefinition
                  ? device.belongDefinition
                  : '',
              });
          }
        }
      });

      return list;
    });

    const loading = ref<boolean>(false);

    const submitShipping = async () => {
      loading.value = true;

      for (const outgoingDevice of selectedDeviceList.value) {
        if (outgoingDevice._id) {
          let editDevice = store.getters.deviceAdmin.getDeviceById(
            outgoingDevice._id
          );
          if (editDevice instanceof Device) {
            editDevice.status = 2;

            // MARK CHECKS AND INVALID
            const deviceType = store.getters.deviceAdmin.getDeviceTypeById(
              editDevice.type
            );
            if (deviceType instanceof DeviceType) {
              for (const checkDefId of deviceType.checkDefinitions) {
                const checkDef =
                  store.getters.deviceAdmin.getCheckDefinitionById(checkDefId);
                if (checkDef instanceof CheckDefinition) {
                  if (
                    checkDef.invalidOnOutgoing !== undefined &&
                    checkDef.invalidOnOutgoing === true
                  ) {
                    for (const lastCheck of editDevice.lastChecks) {
                      if (lastCheck.checkDefinitionId === checkDef._id) {
                        lastCheck['invalid'] = true;
                      }
                    }
                  }
                }
              }
            }

            let actionRequestId = '';

            editDevice.assignedTo.user = receiver.value.assigned.id;
            editDevice.assignedTo.type = receiver.value.assigned.type;

            editDevice.assignedTo.customer = receiver.value.assigned.customerId
              ? receiver.value.assigned.customerId
              : '';

            switch (editDevice.assignedTo.type) {
              case 'value':
                break;
              case 'serviceProvider': {
                editDevice.status = 4;

                const actionRequestList =
                  store.state.deviceAdmin.actionRequestList;
                for (const actionRequest of actionRequestList) {
                  if (actionRequest.command.value.deviceId === editDevice._id) {
                    const checkDef =
                      store.getters.deviceAdmin.getCheckDefinitionById(
                        actionRequest.command.value.checkDefinitionId
                      );
                    if (checkDef instanceof CheckDefinition) {
                      if (checkDef.carriedFrom.type === 'extern') {
                        actionRequestId = actionRequest._id
                          ? actionRequest._id
                          : '';
                        const editActionRequest = _.cloneDeep(actionRequest);
                        editActionRequest.status =
                          'waiting-for-service-provider';
                        await updateDocument(editActionRequest);
                      }
                    }
                  }
                }

                if (outgoingDevice.belongDefinition) {
                  if (outgoingDevice.belongDefinition === 'borrowDevice') {
                    if (
                      outgoingDevice.archivedCycleLimitation !== -1 &&
                      outgoingDevice.shippingList
                    ) {
                      const shippingCounter =
                        outgoingDevice.shippingList.length / 2;
                      if (shippingCounter <= 0) {
                        outgoingDevice.archived = true;
                      }
                    }
                  }
                }

                break;
              }
            }

            const shippingEntry = new ShippingEntry(
              'outgoing',
              undefined,
              new Date(),
              receiver.value,
              actionRequestId
            );
            if (editDevice.shippingList) {
              editDevice.shippingList.push(shippingEntry);
            } else {
              editDevice['shippingList'] = [shippingEntry];
            }
            await updateDocument(editDevice);
            //@ToDo add Dialog for sucess
            void context.root.$router.push('/devices');
          }
        }
      }

      loading.value = false;
      close();
    };

    const close = () => {
      saveShippingList();
      context.emit('close');
    };

    const saveShippingList = () => {
      return;
    };

    const canSubmitShipping = computed(() => {
      let canSubmit = true;

      console.log('Receiver', receiver.value);
      console.log('Receiver type', receiver.value.assigned.type);
      console.log('Receiver id', receiver.value.assigned.id);

      if (!receiver.value.assigned.type || !receiver.value.assigned.id) {
        canSubmit = false;
      }

      return canSubmit;
    });

    const submitDeliveryCheck = () => {
      if (currentCheckDevice.value && currentCheckDevice.value._id)
        checkedDeviceIds.value.push(currentCheckDevice.value._id);

      if (currentCheckForm.value instanceof Formular)
        currentCheckForm.value.resetResults();
    };

    const currentCheckForm = ref<Formular>();

    const deviceNeedDeliveryCheck = (id: string) => {
      let needCheck = false;
      let getDevice = store.getters.deviceAdmin.getDeviceById(id);

      if (getDevice instanceof Device) {
        if (deviceHaveDeliveryCheck(getDevice)) {
          if (!deviceHaveValidDeliveryCheck(getDevice)) needCheck = true;
        }
      }
      return needCheck;
    };

    const selectedMaster = ref<string>();

    const addDevice = (deviceId: string) => {
      const addDevice = store.getters.deviceAdmin.getDeviceById(deviceId);
      if (addDevice instanceof Device) {
        selectedDeviceList.value.push(addDevice);
      }
    };

    const deleteDevice = (deviceIndex: number) => {
      selectedDeviceList.value.splice(deviceIndex, 1);
    };

    const partnerDeviceList = ref<string[]>([]);
    const changePartnerDevices = (deviceId: string, isPartner: boolean) => {
      if (isPartner === true) {
        partnerDeviceList.value.push(deviceId);
      } else {
        let deleteIndex;
        partnerDeviceList.value.forEach((partnerDev, idx) => {
          if (partnerDev === deviceId) deleteIndex = idx;
        });
        if (deleteIndex !== undefined)
          partnerDeviceList.value.splice(deleteIndex, 1);
      }
    };

    const isInPartnerList = (deviceId: string) => {
      const found = partnerDeviceList.value.find(
        (element) => element === deviceId
      );

      if (found !== undefined) return true;
      else return false;
    };

    const onClickPrintQRCode = (deviceId: string) => {
      let getDevice = store.getters.deviceAdmin.getDeviceById(deviceId);
      if (getDevice instanceof Device) {
        printQRCode(deviceId, getDevice.inventoryNumber);
      }
    };

    const scanInput = ref<string>();
    const search = ref<string>();

    const changeScanInput = async (val: string) => {
      loading.value = true;
      scanInput.value = val;

      if (scanInput.value) {
        const parsed = parseQrCode(scanInput.value);
        if (parsed.valid) {
          const getDevice = store.getters.deviceAdmin.getDeviceById(
            parsed.deviceId
          );
          if (getDevice instanceof Device) {
            const find = selectedDeviceList.value.find(
              (entry) => entry._id === getDevice._id
            );
            if (!find) selectedDeviceList.value.push(getDevice);
          } else {
            const oldInventoryNumber = await getOldDevice(parsed.deviceId);
            const getDevice =
              store.getters.deviceAdmin.getDeviceByInventoryNumber(
                oldInventoryNumber
              );
            if (getDevice instanceof Device) {
              const find = selectedDeviceList.value.find(
                (entry) => entry._id === getDevice._id
              );
              if (!find) selectedDeviceList.value.push(getDevice);
            } else {
              const getDevice =
                store.getters.deviceAdmin.getDeviceByInventoryNumber(
                  oldInventoryNumber.replace(' ', '')
                );
              if (getDevice instanceof Device) {
                const find = selectedDeviceList.value.find(
                  (entry) => entry._id === getDevice._id
                );
                if (!find) selectedDeviceList.value.push(getDevice);
              }
            }
          }
        }
      }

      Vue.nextTick(() => {
        scanInput.value = undefined;
      });
      loading.value = false;
    };

    // const minForShippingWarning = computed(() => {
    //   let tmp: { type: string; minForShipping: number }[] = [];
    //   if (
    //     selectedServiceProvider.value &&
    //     isAssignedTo.value === 'externalServiceProvider'
    //   ) {
    //     const serviceProvider =
    //       store.state.deviceAdmin.serviceProviderList.find(
    //         (entry) => entry._id === selectedServiceProvider.value
    //       );
    //     if (serviceProvider) {
    //       const shippingDeviceTypes: string[] = [];
    //       for (const device of selectedDeviceList.value) {
    //         const find = shippingDeviceTypes.find(
    //           (entry) => entry === device.type
    //         );
    //         if (!find) {
    //           shippingDeviceTypes.push(device.type);
    //         }
    //       }
    //       for (const warningDefinition of serviceProvider.warningTime) {
    //         const filtered = selectedDeviceList.value.filter(
    //           (entry) => entry.type === warningDefinition.deviceType
    //         );
    //         const diff = warningDefinition.minForShipping - filtered.length;

    //         const help = {
    //           type: store.getters.deviceAdmin.getDeviceTypeLabelById(
    //             warningDefinition.deviceType
    //           ),
    //           minForShipping: diff,
    //         };
    //         tmp.push(help);
    //       }
    //     }
    //   }
    //   return tmp;
    // });

    return {
      // configs
      statusConfig,

      selectedDeviceList,
      receiver,
      isAssignedTo,
      devicesArePartner,
      // userList,
      // customerList,
      deviceList,
      // serviceProviderList,
      loading,
      submitShipping,
      canSubmitShipping,
      shippToCheck,
      checkedDeviceIds,
      currentCheckDevice,
      submitDeliveryCheck,
      currentCheckForm,
      deviceNeedDeliveryCheck,
      selectedMaster,
      addDevice,
      // changeReceiver,
      deleteDevice,
      partnerDeviceList,
      changePartnerDevices,
      isInPartnerList,
      onClickPrintQRCode,
      changeScanInput,
      scanInput,
      search,
      // getCustomerSelectBelongColor,
      // getCustomerBelongDefinition,
      // getCustomerIdentificationNumber,
      // minForShippingWarning,
    };
  },
});
