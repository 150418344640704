interface ParsedQrCodeValue {
  valid: boolean;
  deviceId: string;
  organisation: string;
}

export const useBarcodeHandling = () => {
  const parseQrCode = (code: string) => {
    const result: ParsedQrCodeValue = {
      valid: false,
      deviceId: '',
      organisation: '',
    };

    const newQR = code.includes('orga');

    const deviceId = code.slice(
      code.indexOf('?id=') + 4,
      newQR ? code.indexOf('&') : code.length
    );

    const orga = code.slice(code.indexOf('&orga=') + 6, code.length);

    if (deviceId) result.deviceId = deviceId;

    if (orga) result.organisation = orga;
    else result.organisation = 'upp';

    if (result.deviceId && result.organisation) result.valid = true;

    return result;
  };

  return {
    parseQrCode,
  };
};
