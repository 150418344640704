let api = 'error';
let frt = 'error';

export const publicSharedContentURL = 'https://psc.utenso.de';

if (process.env.DEV) {
  console.log('DEV', process.env.DEV);
  api = 'http://localhost:8070';
  frt = 'http://localhost:8080/#/';
} else if (process.env.DEBUGGING) {
  console.log('DEBUGGING', process.env.DEBUGGING);
  api = 'https://test.api.devices.get-web.online';
  frt = 'http://test.devices.get-web.online/#/';
} else if (process.env.PROD) {
  console.log('PROD', process.env.PROD);
  frt = 'https://devices.get-web.online/#/';
  api = 'https://api.devices.get-web.online';
}

export const apiServer = api;
export const frontend = frt;
