import { Type } from 'class-transformer';
import 'reflect-metadata';
// interfaces
import { Device } from './Device.Interface';
import { AddressValue } from './User.Interface';

export interface ReceiverAssigned {
  type: string;
  id: string;
  customerId?: string;
}
export class OutgoingReceiverValue {
  name: string;
  assigned: ReceiverAssigned;
  address: AddressValue;
  constructor(
    name = '',
    assigned: ReceiverAssigned = { type: '', id: '', customerId: '' },
    address: AddressValue = {
      street: '',
      number: '',
      country: '',
      zip: '',
      city: '',
      state: '',
    }
  ) {
    this.name = name;
    this.assigned = assigned;
    this.address = address;
  }
}

export class OutgoingCartValue {
  shippingList: ShippingListValue[];
  receiver: OutgoingReceiverValue;
  constructor(
    shippingList = [],
    receiver: OutgoingReceiverValue = {
      name: '',
      assigned: {
        type: '',
        id: '',
      },
      address: {
        street: '',
        zip: '',
        number: '',
        city: '',
        country: '',
        state: '',
      },
    }
  ) {
    this.shippingList = shippingList;
    this.receiver = receiver;
  }
}

export class ShippingTask {
  _id?: string;
  _rev?: string;
  doctype: string;
  @Type(() => OutgoingCartValue)
  task: OutgoingCartValue;
  constructor(task: OutgoingCartValue) {
    this.doctype = 'shippingTask';
    this.task = task;
  }
}

export interface ShippingListValue {
  oldDevice: Device | undefined;
  newDevice: Device | undefined;
}

export interface IncomingDeviceValue {
  deviceId: string;
}

export class ShippingOptions {
  deviceTypeId: string;
  allowPickup: boolean;
  allowSelfShipping: boolean;
  constructor(
    deviceTypeId: string,
    allowPickup = true,
    allowSelfShipping = true
  ) {
    this.allowPickup = allowPickup;
    this.allowSelfShipping = allowSelfShipping;
    this.deviceTypeId = deviceTypeId;
  }
}

export interface ServiceProviderMinCounter {
  [key: string]: {
    // serviceProviderID
    [key: string]: number; // deviceTypeID
  };
}
export interface ServiceProvidedWarningTimeCounter {
  [key: string]: number;
}
