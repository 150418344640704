import Vue from 'vue';
import Vuex from 'vuex';
import { createDirectStore } from 'direct-vuex';
import deviceAdmin from './deviceAdmin';

Vue.use(Vuex);

// eslint-disable-next-line @typescript-eslint/unbound-method
const {
  store,
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext
} = createDirectStore({
  namespaced: true,
  modules: {
    deviceAdmin
  }
});

//Export the direct-store instead of the classic Vuex store
export default store;

export {
  rootActionContext,
  moduleActionContext,
  rootGetterContext,
  moduleGetterContext
};

export type AppStore = typeof store;
declare module 'vuex' {
  interface Store<S> {
    direct: AppStore;
  }
}
