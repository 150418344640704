






























import { defineComponent, computed } from '@vue/composition-api';

export default defineComponent({
  name: 'DisplayCardNumber',
  components: {},
  props: {
    label: {
      type: String
    },
    caption: {
      type: String
    },
    icon: {
      type: String
    },
    disableActionButton: {
      type: Boolean,
      default: true
    },
    activateCardLink: {
      type: Boolean,
      default: false
    },
    cardLink: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    }
  },
  setup(props, context) {
    const onClickActionButton = (evt: Event) => {
      evt.stopPropagation();
      context.emit('click-action');
    };
    const onClickCard = () => {
      if (props.activateCardLink)
        void context.root.$router.push(props.cardLink);
    };
    const cardStyle = computed(() => {
      return { cursor: props.activateCardLink ? 'pointer' : '' };
    });
    return { onClickActionButton, onClickCard, cardStyle };
  }
});
