


































































































































































































































































































































































































































import {
  defineComponent,
  ref,
  computed,
  onMounted,
  watch,
} from '@vue/composition-api';
import store from 'src/store/directStore';
import { plainToClass } from 'class-transformer';
import _ from 'lodash';
import { date } from 'quasar';
import { copyToClipboard } from 'quasar';
import { Notify } from 'quasar';
//components
import gwTabCardHorizontal from '../basic/tabCards/gwTabCardHorizontal.vue';
import FormShower from 'src/components/formular/FormShower.vue';
import OutgoingCard from 'src/components/shipping/OutgoingCard.vue';
import gwShippingTable from 'components/shipping/gwShippingTable.vue';
import gwAddressCard from 'components/shipping/gwAddressCard.vue';
import gwSelectShippingList from 'components/checks/gwSelectShippingList.vue';
import CommentList from 'src/components/basic/CommentList.vue';
import CostList from 'src/components/basic/CostList.vue';
import ActionRequestOverview from 'src/components/checks/ActionRequestOverview.vue';
import SentNotificationsList from './SentNotificationsList.vue';
import ReplacementView from './ReplacementView.vue';
//interfaces
import {
  ActionRequest,
  ActionRequestActivity,
  DeliveryCheckValue,
  SelectedShippingDates,
  SentReminderValue,
  ServiceRequestValue,
} from 'src/interfaces/Action.Interface';
import {
  CommentValue,
  Device,
  DeviceType,
} from 'src/interfaces/Device.Interface';
import { Formular } from 'src/interfaces/Form.Interface';
import { Check, CheckDefinition } from 'src/interfaces/Check.Interface';

//composition
import { useRequestHandling } from 'src/compositions/RequestHandling';
import { useShippingHandling } from 'src/compositions/ShippingHandling';
import { OutgoingCartValue } from '../../interfaces/Shipping.Interface';
//configs
import { RangeTimeValue } from 'src/interfaces/Settings.Interface';
import { User } from 'src/interfaces/User.Interface';
import { dateFormat } from 'src/config/settingsConfig';
import { CostEntry } from 'src/interfaces/Costs.Interface';

export default defineComponent({
  name: 'ActionRequestCard',
  components: {
    gwTabCardHorizontal,
    FormShower,
    OutgoingCard,
    gwShippingTable,
    gwAddressCard,
    gwSelectShippingList,
    CommentList,
    CostList,
    ActionRequestOverview,
    SentNotificationsList,
    ReplacementView,
  },
  props: {
    actionRequestId: {
      type: String,
      required: true,
    },
    shippingIncomingMode: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const {
      updateDocument,
      deleteDocument,
      startHandleActionRequest,
      getPartnerWithCheckInSamePeriod,
      getDocument,
      getServiceRequestLink,
      sentMailAgain,
    } = useRequestHandling();
    const { handleOutgoingCartValue } = useShippingHandling();

    const editActionRequest = ref<ActionRequest>();

    const localDevice = ref<Device>();
    const localFormular = ref<Formular>();

    const outgoingCardVal = ref<OutgoingCartValue>(new OutgoingCartValue([]));

    const selectedShippingDate = ref<SelectedShippingDates>();

    watch(
      () => props.actionRequestId,
      async () => {
        await init();
      }
    );

    const init = async () => {
      const tmp = await getDocument(props.actionRequestId, 'actionRequest');

      if (tmp instanceof ActionRequest) {
        editActionRequest.value = tmp;
      }

      if (editActionRequest.value) {
        await createInitOutgoingValue();

        const device = store.getters.deviceAdmin.getDeviceById(
          editActionRequest.value.command.value.deviceId
        );
        if (device instanceof Device) {
          localDevice.value = _.cloneDeep(device);

          // activeView.value = editActionRequest.value.status;
          if (
            editActionRequest.value.command.value instanceof ServiceRequestValue
          ) {
            let getFormular = store.getters.deviceAdmin.getFormularById(
              editActionRequest.value.command.value.formularId
            );
            console.log('getFormular', getFormular);

            if (getFormular instanceof Formular) {
              localFormular.value = getFormular;
              if (editActionRequest.value.command.value.filledFormular) {
                let tmp = plainToClass(
                  Formular,
                  editActionRequest.value.command.value.filledFormular
                );
                if (tmp) localFormular.value = tmp;
              }
            } else {
              Notify.create({
                message: 'Es ist ein Fehler aufgetreten #ARC003',
                color: 'negative',
                icon: 'warning',
                position: 'top',
                textColor: 'white',
              });
              console.log(
                'formularId',
                editActionRequest.value.command.value.formularId
              );
            }
          } else if (
            editActionRequest.value.command.value instanceof DeliveryCheckValue
          ) {
            // for fix old actionRequest with
            // if (editActionRequest.value.status === 'created')
            //   activeView.value = 'readyToCheck';

            const checkDef = store.getters.deviceAdmin.getCheckDefinitionById(
              editActionRequest.value.command.value.checkDefinitionId
            );

            if (checkDef instanceof CheckDefinition) {
              let getFormular = store.getters.deviceAdmin.getFormularById(
                checkDef.formularId
              );
              if (getFormular instanceof Formular)
                localFormular.value = getFormular;
              //check if device already have a check in progress
              for (const lastCheck of localDevice.value.lastChecks) {
                if (lastCheck.checkDefinitionId === checkDef._id) {
                  if (lastCheck.status === 'inProgress') {
                    localFormular.value = lastCheck.formular;
                  }
                }
              }
            }
          } else {
            Notify.create({
              message: 'Es ist ein Fehler aufgetreten #ARC002',
              color: 'negative',
              icon: 'warning',
              position: 'top',
              textColor: 'white',
            });
          }
        } else {
          Notify.create({
            message: 'Es ist ein Fehler aufgetreten #ARC001',
            color: 'negative',
            icon: 'warning',
            position: 'top',
            textColor: 'white',
          });
        }
      }
    };

    onMounted(async () => {
      await init();
    });

    const shippingDateList = computed(() => {
      const list: SelectedShippingDates[] = [];

      if (editActionRequest.value) {
        const cmdValue = editActionRequest.value.command.value;

        if (cmdValue instanceof ServiceRequestValue && cmdValue.shippingDate) {
          for (const entry of cmdValue.shippingDate) {
            if (entry) {
              if (!(entry.date instanceof Date)) {
              } else {
                list.push(entry);
              }
            }
          }
        }
      }

      return list;
    });

    const possibleShippingList = computed(() => {
      let list: SelectedShippingDates[] = [];
      if (editActionRequest.value) {
        const cmdValue = editActionRequest.value.command.value;

        if (cmdValue instanceof ServiceRequestValue) {
          if (cmdValue.shippingDate) {
            return cmdValue.shippingDate;
          }
        }
      }

      return list;
    });

    const createInitOutgoingValue = async () => {
      if (editActionRequest.value) {
        const cmdValue = editActionRequest.value.command.value;
        const device = store.getters.deviceAdmin.getDeviceById(
          cmdValue.deviceId
        );
        if (
          cmdValue instanceof ServiceRequestValue &&
          device instanceof Device
        ) {
          const tmp = store.getters.deviceAdmin.getReceiverValueByAssignedValue(
            device.assignedTo,
            // @ToDo delete?
            // cmdValue.shippingAddress ? true : false
            false
          );
          if (cmdValue.shippingAddress) {
            tmp.address = cmdValue.shippingAddress;
          }

          outgoingCardVal.value.receiver = tmp;

          outgoingCardVal.value.shippingList.push({
            oldDevice: device,
            newDevice: undefined,
          });

          if (device.partnerConfigId !== '') {
            const affectedPartner = await getPartnerWithCheckInSamePeriod(
              device._id,
              cmdValue.checkDefinitionId
            );
            if (affectedPartner) {
              for (const partnerEntry of affectedPartner.partnerList) {
                const partnerDevice = await getDocument(
                  partnerEntry.partnerId,
                  'device'
                );
                if (partnerDevice instanceof Device)
                  outgoingCardVal.value.shippingList.push({
                    oldDevice: partnerDevice,
                    newDevice: undefined,
                  });
              }
            }
          }
        }
      }
    };

    const checkDefinition = computed(() => {
      if (editActionRequest.value) {
        const tmp = store.getters.deviceAdmin.getCheckDefinitionById(
          editActionRequest.value.command.value.checkDefinitionId
        );
        if (tmp instanceof CheckDefinition) return tmp;
      }
      return undefined;
    });

    const formular = computed(() => {
      if (checkDefinition.value instanceof CheckDefinition) {
        let tmp = store.getters.deviceAdmin.getFormularById(
          checkDefinition.value?.formularId
        );
        if (tmp instanceof Formular) return tmp;
      }
    });

    const device = computed(() => {
      if (editActionRequest.value) {
        const tmp = store.getters.deviceAdmin.getDeviceById(
          editActionRequest.value.command.value.deviceId
        );
        if (tmp instanceof Device) return tmp;
      }
      return undefined;
    });

    const deviceType = computed(() => {
      if (device.value) {
        let tmp = store.getters.deviceAdmin.getDeviceTypeById(
          device.value.type
        );
        if (tmp instanceof DeviceType) return tmp;
      }
    });

    const close = () => {
      context.emit('close');
    };

    const checkFailedAction = (action: string) => {
      return action;
    };

    const viewTabButtonText = computed(() => {
      if (editActionRequest.value) {
        switch (editActionRequest.value.status) {
          case 'form-filled-out':
          case 'check-not-passed':
            return 'view check';
            break;
          case 'shipping-form-filled-out':
            return 'view shipping request';
            break;
        }
      } else return '';
    });

    const showViewCheckButton = computed(() => {
      if (editActionRequest.value) {
        let status = editActionRequest.value.status;
        if (
          status === 'readyToCheck' ||
          status === 'form-filled-out' ||
          status === 'shipping-form-filled-out' ||
          status === 'check-not-passed'
        ) {
          return true;
        }
      }
      return false;
    });

    // SUBMIT CHECK / readyToCheck
    const submit = async () => {
      loading.value = true;

      const formular = localFormular.value;
      let differentCheckDate: Date | undefined = undefined;

      if (activateDifferentDate && differentDate.value && differentTime.value) {
        differentCheckDate = date.extractDate(
          `${differentDate.value} ${differentTime.value}`,
          'DD.MM.YYYY HH:mm'
        );
      }

      if (formular) {
        if (!formular.isCompleteFilledOut()) {
          context.root.$q.notify('Nicht alles ausgefüllt');
        } else {
          if (store.state.deviceAdmin.auth && editActionRequest.value) {
            localFormular.value = formular;

            if (
              device.value &&
              editActionRequest.value &&
              editActionRequest.value._id &&
              editActionRequest.value._rev &&
              checkDefinition.value &&
              checkDefinition.value._id
            ) {
              const actionRequestId: string = editActionRequest.value._id;

              const isSuccessful = formular.isSuccessful();

              const updateDevice = _.cloneDeep(device.value);

              const checkDate = differentCheckDate
                ? differentCheckDate
                : new Date();

              const newCheck = new Check(
                checkDefinition.value._id,
                checkDefinition.value.name,
                checkDate,
                store.state.deviceAdmin.auth.firstname +
                  ' ' +
                  store.state.deviceAdmin.auth.lastname,
                'completed',
                isSuccessful,
                checkDefinition.value.type,
                false,
                formular,
                undefined,
                undefined,
                editActionRequest.value.comments,
                editActionRequest.value.costs,
                new Date(),
                [],
                editActionRequest.value.created
              );

              if (
                editActionRequest.value.command.value instanceof
                ServiceRequestValue
              ) {
                newCheck.sentReminders =
                  editActionRequest.value.command.value.sentReminders;
              }

              if (updateDevice.assignedTo.type === 'user') {
                newCheck.lastUser = updateDevice.assignedTo.user;
              }

              // IF SAVED CHECK EXIST
              const haveOpenLastCheck = updateDevice.lastChecks.findIndex(
                (entry) => entry.actionRequestId === actionRequestId
              );
              if (haveOpenLastCheck !== -1) {
                newCheck.id = updateDevice.lastChecks[haveOpenLastCheck].id;
                updateDevice.lastChecks[haveOpenLastCheck] = newCheck;
              }

              if (isSuccessful) {
                updateDevice.status =
                  updateDevice.assignedTo.type === 'user' ? 2 : 1;

                if (haveOpenLastCheck === -1) {
                  updateDevice.lastChecks.push(newCheck);
                }

                // UPDATE NEXTCHECKS ON DELIVERY CHECK
                if (checkDefinition.value.type === 'deliveryCheck') {
                  for (const nextCheck of updateDevice.nextChecks) {
                    const getCheckDef =
                      store.getters.deviceAdmin.getCheckDefinitionById(
                        nextCheck.checkDefinitionId
                      );
                    if (getCheckDef instanceof CheckDefinition) {
                      if (getCheckDef.resetByDeliveryCheck) {
                        const newNextCheckDate = new Date();
                        const time =
                          checkDate.getTime() +
                          getCheckDef.intervall.value * 86400000;
                        newNextCheckDate.setTime(time);
                        nextCheck.nextCheck = newNextCheckDate;
                      }
                    }
                  }
                }

                // CALC NEXTCHECKS FOR LINKED CHECKS
                const deviceType = store.state.deviceAdmin.deviceTypeList.find(
                  (entry) => entry._id === updateDevice.type
                );
                if (
                  deviceType instanceof DeviceType &&
                  deviceType.linkedChecks
                ) {
                  const find = deviceType.linkedChecks.find(
                    (entry) =>
                      checkDefinition.value &&
                      entry.blockedByCheckDefId === checkDefinition.value._id
                  );
                  if (find && find.resetLinkedCheck) {
                    const linkedCheckDef =
                      store.state.deviceAdmin.checkDefinitionList.find(
                        (entry) => entry._id === find.linkedCheckDefId
                      );

                    if (linkedCheckDef instanceof CheckDefinition) {
                      for (const nextCheck of updateDevice.nextChecks) {
                        let newNextCheckDate = new Date();
                        const today = new Date().getTime();
                        if (
                          nextCheck.checkDefinitionId === linkedCheckDef._id
                        ) {
                          const time =
                            today + linkedCheckDef.intervall.value * 86400000;
                          newNextCheckDate.setTime(time);
                          nextCheck.nextCheck = newNextCheckDate;
                        }
                      }
                    }
                  }
                }

                // CALC NORMAL NEXTCHECK
                if (deviceType) {
                  const deleteNextCheckIds: string[] = [];

                  for (const nextCheck of updateDevice.nextChecks) {
                    // Check if checkDefinition is still in deviceType
                    if (
                      nextCheck.checkDefinitionId === checkDefinition.value._id
                    ) {
                      const find = deviceType.checkDefinitions.find(
                        (entry) => entry === nextCheck.checkDefinitionId
                      );

                      if (find) {
                        const time =
                          checkDate.getTime() +
                          checkDefinition.value.intervall.value * 86400000;

                        nextCheck.nextCheck = new Date(time);
                      } else {
                        deleteNextCheckIds.push(nextCheck.checkDefinitionId);
                        // @ToDo [GWD-1718]
                      }
                    }
                  }
                  // delete nextChecks when not in deviceType
                  for (const deleteId of deleteNextCheckIds) {
                    const findIdx = updateDevice.nextChecks.findIndex(
                      (entry) => entry.checkDefinitionId === deleteId
                    );
                    if (findIdx !== -1) {
                      updateDevice.nextChecks.splice(findIdx, 1);
                    }
                  }
                }

                await deleteDocument(
                  editActionRequest.value._id,
                  editActionRequest.value.doctype,
                  false
                );
              } else {
                if (checkDefinition.value.saveFailedAttempts) {
                  if (haveOpenLastCheck === -1) {
                    updateDevice.lastChecks.push(newCheck);
                  }
                }

                if (
                  editActionRequest.value.command.value instanceof
                  ServiceRequestValue
                ) {
                  editActionRequest.value.command.value.filledFormular =
                    formular;
                }

                editActionRequest.value.status = 'check-not-passed';

                addActivity();
                await updateDocument(editActionRequest.value);
              }

              await updateDocument(updateDevice);

              context.emit('submit');

              if (!props.shippingIncomingMode) {
                void context.root.$router.push('/checks');
              }
            }
          } else {
            context.root.$q.notify('Es ist ein Fehler aufgetreten');
          }
        }
      } else {
        context.root.$q.notify('Es ist ein Fehler aufgetreten');
      }

      loading.value = false;
    };

    const differentDate = ref<string>();
    const differentTime = ref<string>();
    const activateDifferentDate = ref<boolean>(false);

    const isDeliveryCheck = computed(() => {
      if (editActionRequest.value) {
        if (editActionRequest.value.command.value instanceof DeliveryCheckValue)
          return true;
        else return false;
      } else return undefined;
    });

    const submitCheckNotPassed = async () => {
      const updateDevice = _.cloneDeep(device.value);
      if (
        device.value &&
        checkDefinition.value?._id &&
        updateDevice &&
        editActionRequest.value &&
        editActionRequest.value._id &&
        localFormular.value
      ) {
        const newCheck = new Check(
          // device.value.lastChecks.length + 1,
          checkDefinition.value._id,
          checkDefinition.value.name,
          new Date(),
          store.getters.deviceAdmin.getCurrentUserName(),
          'completed',
          localFormular.value.isSuccessful(),
          checkDefinition.value.type,
          false,
          localFormular.value,
          undefined,
          undefined,
          editActionRequest.value.comments,
          editActionRequest.value.costs,
          new Date(),
          [],
          editActionRequest.value.created
        );

        if (
          editActionRequest.value.command.value instanceof ServiceRequestValue
        ) {
          newCheck.sentReminders =
            editActionRequest.value.command.value.sentReminders;
        }

        // DELETE ACTIONREQUEST
        await deleteDocument(
          editActionRequest.value._id,
          editActionRequest.value.doctype,
          false
        );

        // UPDATE DEVICE
        updateDevice.lastChecks.push(newCheck);
        updateDevice.status = localFormular.value.isSuccessful() ? 1 : 6;

        await updateDocument(updateDevice);

        close();
      }
    };

    const onSaveActionRequest = async () => {
      loading.value = true;

      if (
        device.value &&
        checkDefinition.value &&
        checkDefinition.value._id &&
        editActionRequest.value &&
        localFormular.value
      ) {
        const updateDevice = _.cloneDeep(device.value);

        const sentReminders =
          editActionRequest.value.command.value instanceof ServiceRequestValue
            ? editActionRequest.value.command.value.sentReminders
            : [];

        let haveSavedCheck = updateDevice.lastChecks.find(
          (entry) =>
            editActionRequest.value &&
            editActionRequest.value.savedCheckId &&
            entry.id === editActionRequest.value.savedCheckId &&
            entry.status === 'inProgress'
        );

        const newCheck = new Check(
          checkDefinition.value._id,
          checkDefinition.value.name,
          new Date(),
          store.getters.deviceAdmin.getCurrentUserName(),
          'inProgress',
          localFormular.value.isSuccessful(),
          checkDefinition.value.type,
          false,
          localFormular.value,
          editActionRequest.value._id,
          device.value.assignedTo.user,
          editActionRequest.value.comments,
          editActionRequest.value.costs,
          new Date(),
          sentReminders,
          editActionRequest.value.created
        );

        if (haveSavedCheck) {
          Object.assign(haveSavedCheck, newCheck);
        } else {
          updateDevice.lastChecks.push(newCheck);
        }

        await updateDocument(updateDevice);

        editActionRequest.value.status = 'inProgress';
        if (typeof newCheck.id === 'string') {
          editActionRequest.value.savedCheckId = newCheck.id;
        }

        addActivity();
        await updateDocument(editActionRequest.value);

        close();
      }
      loading.value = false;
    };

    const formReadonly = ref<boolean>(false);
    const startEditing = () => {
      localFormular.value = formular.value;
      formReadonly.value = false;
    };

    const currentUser = computed(() => {
      if (device.value) {
        const getUser = store.getters.deviceAdmin.getUserById(
          device.value.assignedTo.user
        );
        if (getUser instanceof User) {
          return getUser;
        }
      }
    });

    const submitReplacementShipping = async () => {
      loading.value = true;
      if (editActionRequest.value) {
        await handleOutgoingCartValue(outgoingCardVal.value);
        editActionRequest.value.status = 'sent-replacement';
        editActionRequest.value.outgoing = outgoingCardVal.value;

        addActivity();
        await updateDocument(editActionRequest.value);
        close();
      }
      loading.value = false;
    };

    const submitReplacementRangeDate = async (val: string) => {
      if (editActionRequest.value) {
        editActionRequest.value.status = 'sent-replacement';
        if (
          editActionRequest.value.command.value instanceof
            ServiceRequestValue &&
          editActionRequest.value.command.value.shippingDate
        ) {
          let split = val.split('-');
          let tmp = new SelectedShippingDates(
            new Date(),
            new RangeTimeValue(split[0], split[1])
          );
          editActionRequest.value.command.value.shippingDate = [tmp];

          addActivity();
          await updateDocument(editActionRequest.value);
          close();
        }
      }
    };

    const changeOutgoingCard = (val: OutgoingCartValue) => {
      outgoingCardVal.value = val;
    };

    const replacementShippingDate = computed(() => {
      if (editActionRequest.value) {
        const cmdVal = editActionRequest.value.command.value;

        if (cmdVal instanceof ServiceRequestValue) {
          if (cmdVal.shippingDate && cmdVal.shippingDate.length > 0)
            return cmdVal.shippingDate[0].toLocaleString();
        }
      } else return '';
    });

    const confirmShippingDate = async (date: SelectedShippingDates) => {
      if (editActionRequest.value) {
        selectedShippingDate.value = date;
        if (
          editActionRequest.value.command.value instanceof
            ServiceRequestValue &&
          editActionRequest.value.command.value.shippingDate
        ) {
          editActionRequest.value.status = 'shipping-date-confirmed';
          editActionRequest.value.command.value.shippingDate = [date];

          addActivity();
          await updateDocument(editActionRequest.value);
          close();
        }
      }
    };

    const submitShipping = async (date: SelectedShippingDates) => {
      selectedShippingDate.value = date;
      if (editActionRequest.value && editActionRequest.value._id) {
        await handleOutgoingCartValue(outgoingCardVal.value);

        editActionRequest.value.outgoing = outgoingCardVal.value;
        editActionRequest.value.status = 'waiting-for-shipping';
        if (
          editActionRequest.value.command.value instanceof
            ServiceRequestValue &&
          editActionRequest.value.command.value.shippingDate
        ) {
          editActionRequest.value.command.value.shippingDate = [
            selectedShippingDate.value,
          ];
        }

        addActivity();
        await updateDocument(editActionRequest.value);
        close();
      }
    };

    const getFormattedFromToShippingVal = (val: RangeTimeValue) => {
      if (val.from === '00:00') {
        const edit = `${val.from.replace('00:00', 'bis ')} ${val.to}`;

        return edit;
      } else {
        const edit = `${val.from} - ${val.to}`;
        return edit;
      }
    };

    const resetActionRequest = async () => {
      loading.value = true;
      if (editActionRequest.value && editActionRequest.value._id) {
        if (isDeliveryCheck.value) {
          editActionRequest.value.status = 'readyToCheck';
        } else {
          editActionRequest.value.status = 'created';
        }

        addActivity();
        await updateDocument(editActionRequest.value);
        // await startHandleActionRequest(editActionRequest.value._id);

        const tmp = await getDocument(props.actionRequestId, 'actionRequest');

        if (tmp instanceof ActionRequest) {
          editActionRequest.value = tmp;
        }
        loading.value = false;
      }
    };

    const setSentEtikett = () => {
      close();
    };

    const submitStartPickup = async () => {
      if (editActionRequest.value) {
        editActionRequest.value.status = 'waiting-for-pickup';

        addActivity();
        await updateDocument(editActionRequest.value);
        close();
      }
    };

    const getCurrentCountryCode = computed(() => {
      if (editActionRequest.value) {
        const deviceId = editActionRequest.value.command.value.deviceId;
        const getDevice = store.getters.deviceAdmin.getDeviceById(deviceId);
        let countryCode = 'DE';
        if (getDevice instanceof Device) {
          const getUser = store.getters.deviceAdmin.getUserById(
            getDevice.assignedTo.user
          );
          if (getUser instanceof User) {
            countryCode = getUser.address.country;
          }
        }
        return countryCode;
      } else {
        return undefined;
      }
    });

    const getRangeDate = computed(() => {
      if (
        editActionRequest.value &&
        editActionRequest.value.command.value instanceof ServiceRequestValue
      ) {
        const shippingDate = editActionRequest.value.command.value.shippingDate;
        if (shippingDate) {
          return (
            shippingDate[0].timeRange.from +
            ' - ' +
            shippingDate[0].timeRange.to
          );
        }
      }
    });

    const sentReminderList = computed(() => {
      let list: SentReminderValue[] = [];

      if (
        editActionRequest.value &&
        editActionRequest.value.command.value instanceof ServiceRequestValue &&
        editActionRequest.value.command.value.sentReminders &&
        editActionRequest.value.command.value.sentReminders.length > 0
      ) {
        list = editActionRequest.value.command.value.sentReminders;
      }

      return list;
    });

    const updateActionRequest = async () => {
      loading.value = true;
      if (editActionRequest.value) {
        addActivity();
        await updateDocument(editActionRequest.value);

        const tmp = await getDocument(props.actionRequestId, 'actionRequest');

        if (tmp instanceof ActionRequest) {
          editActionRequest.value = tmp;
        }
      }
      loading.value = false;
    };

    const changeCommentList = async (commentList: CommentValue[]) => {
      if (editActionRequest.value) {
        editActionRequest.value['comments'] = commentList;
        await updateActionRequest();
      }
    };

    const changeCosts = async (commentList: CostEntry[]) => {
      if (editActionRequest.value) {
        editActionRequest.value['costs'] = commentList;

        await updateDocument(editActionRequest.value);
      }
    };

    const activeView = computed(() => {
      let view = 'general';

      if (editActionRequest.value) {
        if (props.shippingIncomingMode) {
          view = 'readyToCheck';
        } else {
          view = editActionRequest.value.status;
        }

        if (
          editActionRequest.value.command.value instanceof DeliveryCheckValue
        ) {
          // for fix old actionRequest with
          if (editActionRequest.value.status === 'created') {
            view = 'readyToCheck';
          }
        }
      }

      return view;
    });

    const copyServiceRequestLink = async () => {
      if (editActionRequest.value && editActionRequest.value._id) {
        const link = await getServiceRequestLink(editActionRequest.value._id);
        // let link = frontend + 'service';
        copyToClipboard(link)
          .then(() => {
            // success!
            context.root.$q.notify('Link in Zwischenablage kopiert');
          })
          .catch(() => {
            // fail
            context.root.$q.notify('Fehler');
          });
      }
    };

    // const onClickSentMailAgain = async (mailTemplate: string) => {
    //   loading.value = true;
    //   if (editActionRequest.value && editActionRequest.value._id) {
    //     await sentMailAgain(editActionRequest.value._id, mailTemplate);
    //     const tmp = await getDocument(props.actionRequestId, 'actionRequest');
    //     if (tmp instanceof ActionRequest) {
    //       editActionRequest.value = tmp;
    //     }
    //   }
    //   loading.value = false;
    // };

    const onClickSentMailAgain = async (type: string, name: string) => {
      loading.value = true;
      if (editActionRequest.value && editActionRequest.value._id) {
        await sentMailAgain(editActionRequest.value._id, type, name);
        const tmp = await getDocument(props.actionRequestId, 'actionRequest');
        if (tmp instanceof ActionRequest) {
          editActionRequest.value = tmp;
        }
      }
      loading.value = false;
    };

    const loading = ref<boolean>(false);

    const checkActionRequest = async () => {
      loading.value = true;
      await startHandleActionRequest(props.actionRequestId);

      const tmp = await getDocument(props.actionRequestId, 'actionRequest');

      if (tmp instanceof ActionRequest) {
        editActionRequest.value = tmp;
      }
      loading.value = false;
    };

    const restartDeliveryCheck = async () => {
      loading.value = true;
      if (
        editActionRequest.value &&
        editActionRequest.value.command.value instanceof DeliveryCheckValue
      ) {
        editActionRequest.value.command.value.formular = undefined;
        editActionRequest.value.status = 'readyToCheck';

        addActivity();
        await updateDocument(editActionRequest.value);
        // await startHandleActionRequest(props.actionRequestId);

        const tmp = await getDocument(props.actionRequestId, 'actionRequest');

        if (tmp instanceof ActionRequest) {
          editActionRequest.value = tmp;
        }
      }
      loading.value = false;
    };

    const addActivity = () => {
      if (editActionRequest.value) {
        if (editActionRequest.value.activity) {
          editActionRequest.value.activity.push(
            new ActionRequestActivity(new Date(), 'sysUser')
          );
        } else {
          editActionRequest.value.activity = [
            new ActionRequestActivity(new Date(), 'sysUser'),
          ];
        }
      }
    };

    return {
      possibleShippingList,
      dateFormat,
      activeView,
      outgoingCardVal,
      selectedShippingDate,
      shippingDateList,
      localDevice,
      // localCheckDefinition,
      checkDefinition,
      formular,
      device,
      deviceType,
      localFormular,
      close,
      checkFailedAction,
      viewTabButtonText,
      showViewCheckButton,
      submit,
      differentDate,
      differentTime,
      activateDifferentDate,
      // submitWithDifferentDate,
      // submitDeliveryCheck,
      isDeliveryCheck,
      submitCheckNotPassed,
      onSaveActionRequest,
      formReadonly,
      startEditing,
      currentUser,
      submitReplacementShipping,
      submitReplacementRangeDate,
      changeOutgoingCard,
      replacementShippingDate,
      confirmShippingDate,
      submitShipping,
      getFormattedFromToShippingVal,
      resetActionRequest,
      setSentEtikett,
      submitStartPickup,
      getCurrentCountryCode,
      getRangeDate,
      sentReminderList,
      changeCommentList,
      editActionRequest,
      changeCosts,
      copyServiceRequestLink,
      onClickSentMailAgain,
      loading,
      checkActionRequest,
      restartDeliveryCheck,
      addActivity,
    };
  },
});
