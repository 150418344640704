

















































































































































































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import _ from 'lodash';
import { OptionValue } from 'src/interfaces/Common.Interface';
import {
  FormatOptionValue,
  TableCellAnswerTypeValue,
  TableCellDefinition,
  TableFieldValue,
} from 'src/interfaces/Form.Interface';
//components
//interfaces

export default defineComponent({
  name: 'gwTableCellSettingsCard',
  components: {},
  props: {
    tableCell: {
      type: Object as () => TableCellDefinition,
      required: true,
    },
    showCloseButton: {
      type: Boolean,
      default: true,
    },
    fieldConfig: {
      type: Object as () => TableFieldValue,
      required: true,
    },
    rowIdx: {
      type: Number,
      required: true,
    },
    colIdx: {
      type: Number,
      required: true,
    },
  },
  setup(props, context) {
    const settingsDialogTab = ref<string>('format');
    const typeOptionList = computed(() => {
      let list = [];
      list.push(new OptionValue('range', 'von bis'));
      list.push(new OptionValue('fix', 'Fester Wert'));
      return list;
    });

    const changeCorrectAnswerType = (newValue: TableCellAnswerTypeValue) => {
      let editCell = _.cloneDeep(props.tableCell);
      if (typeof newValue.value === 'string')
        editCell.correctResult.type = newValue;

      context.emit('change-settings', editCell);
    };

    const changeLinkedCellMode = (newVal: TableCellAnswerTypeValue) => {
      let editCell = _.cloneDeep(props.tableCell);
      if (typeof newVal.value === 'string')
        editCell.correctResult.linkedCells[0].mode = newVal;

      context.emit('change-settings', editCell);
    };

    const changeLinkedCellValue = (newVal: string) => {
      let editCell = _.cloneDeep(props.tableCell);

      editCell.correctResult.linkedCells[0].value = newVal;
      context.emit('change-settings', editCell);
    };

    const changeStart = (newValue: string) => {
      let editCell = _.cloneDeep(props.tableCell);
      editCell.correctResult.start = newValue;
      context.emit('change-settings', editCell);
    };

    const changeEnd = (newValue: string) => {
      let editCell = _.cloneDeep(props.tableCell);
      editCell.correctResult.end = newValue;
      context.emit('change-settings', editCell);
    };

    const changeCellMask = (newMask: FormatOptionValue) => {
      let editCell = _.cloneDeep(props.tableCell);

      editCell.currentValue.format = newMask;

      switch (newMask.value) {
        case 'centigrade':
          editCell.currentValue.suffix = '°C';
          editCell.currentValue.mask = '##,##';
          break;
        case 'time':
          editCell.currentValue.suffix = '';
          editCell.currentValue.mask = '##:##';
          break;
        case 'dateTime':
          editCell.currentValue.suffix = '';
          editCell.currentValue.mask = '##/##/#### ##:##';
          break;
        case 'noFormat':
          editCell.currentValue.suffix = '';
          editCell.currentValue.mask = '';
          break;
      }

      context.emit('change-settings', editCell);
    };

    // noFormat;
    // time;
    // dateTime;
    // centigrade;

    const changePlaceholder = (newPlaceholder: string) => {
      let editCell = _.cloneDeep(props.tableCell);
      editCell.currentValue.placeholder = newPlaceholder;
      context.emit('change-settings', editCell);
    };

    const finishSettings = () => {
      context.emit('finish');
    };

    // @ToDo refactor use only one function
    const changeAllowUserInput = (newValue: boolean) => {
      let editCell = _.cloneDeep(props.tableCell);
      editCell.input = newValue;
      if (!newValue) {
        editCell.checkRelevant = newValue;
      }
      context.emit('change-settings', editCell);
    };

    const changeRequired = (newValue: boolean) => {
      let editCell = _.cloneDeep(props.tableCell);
      editCell.required = newValue;
      if (newValue) {
        editCell.input = newValue;
      }
      context.emit('change-settings', editCell);
    };

    const changeCheckRelevant = (newValue: boolean) => {
      let editCell = _.cloneDeep(props.tableCell);

      editCell.checkRelevant = newValue;
      if (newValue) {
        editCell.input = newValue;
      }
      context.emit('change-settings', editCell);
    };

    const linkedCellRowOptions = computed(() => {
      let list = [];

      for (let i = 1; i < props.fieldConfig.rows.length + 1; i++) {
        list.push(i);
      }
      return list;
    });

    const linkedCellColOptions = computed(() => {
      let list: number[] = [];
      for (
        let i = 1;
        i <
        props.fieldConfig.rows[
          props.tableCell.correctResult.linkedCells[0].rowIdx
        ].length +
          1;
        i++
      ) {
        list.push(i);
      }

      return list;
    });

    const changeLinkedCell = (newIdx: number, mode: string) => {
      let editCell = _.cloneDeep(props.tableCell);

      switch (mode) {
        case 'row':
          editCell.correctResult.linkedCells[0].rowIdx = newIdx - 1;
          break;
        case 'column':
          editCell.correctResult.linkedCells[0].colIdx = newIdx - 1;
          break;
      }

      context.emit('change-settings', editCell);
      // checkLinkedFormatSettings();
    };

    const showChangeLinkedCellFormatInfo = ref<boolean>(false);

    const addLinkedCell = () => {
      let editCell = _.cloneDeep(props.tableCell);
      editCell.correctResult.linkedCells = [];
      editCell.correctResult.linkedCells = [
        {
          rowIdx: 0,
          colIdx: 0,
          mode: new TableCellAnswerTypeValue('Gleicher Wert', 'equal'),
          value: '',
        },
      ];

      context.emit('change-settings', editCell);
      // checkLinkedFormatSettings();
    };

    // const checkLinkedFormatSettings = () => {
    //   if (
    //     props.fieldConfig.rows[0][0].currentValue.format.value !==
    //     props.tableCell.currentValue.format.value
    //   ) {
    //     showChangeLinkedCellFormatInfo.value = true;
    //     let localField = _.cloneDeep(props.fieldConfig);
    //     localField.rows[0][0].currentValue.format =
    //       props.tableCell.currentValue.format;
    //     localField.rows[0][0].currentValue.mask =
    //       props.tableCell.currentValue.mask;
    //     localField.rows[0][0].currentValue.suffix =
    //       props.tableCell.currentValue.suffix;
    //

    //     context.emit('change-field', localField);
    //   }
    // };

    const deleteLinkedCell = () => {
      let editCell = _.cloneDeep(props.tableCell);
      editCell.correctResult.linkedCells = [];
      context.emit('change-settings', editCell);
    };

    const tableCellDiffValue = computed(() => {
      let tmp = 0;
      if (props.tableCell.correctResult.linkedCells[0].value) {
        tmp = parseFloat(props.tableCell.correctResult.linkedCells[0].value);
      }
      return tmp;
    });
    const tableCellDiffUnit = computed(() => {
      let tmp = props.tableCell.correctResult.linkedCells[0].value.substring(
        props.tableCell.correctResult.linkedCells[0].value.length - 1
      );

      switch (tmp) {
        case 'd':
          return new OptionValue(tmp, 'Tage');
          break;
        case 'm':
          return new OptionValue(tmp, 'Minuten');
          break;
        case 'h':
          return new OptionValue(tmp, 'Stunden');
          break;
        default:
          return new OptionValue('d', 'Tage');
          break;
      }
    });

    const changeTableCellDiffValue = (val: string) => {
      let editCell = _.cloneDeep(props.tableCell);
      if (tableCellDiffUnit.value instanceof OptionValue)
        if (typeof tableCellDiffUnit.value.value === 'string')
          editCell.correctResult.linkedCells[0].value =
            val + tableCellDiffUnit.value.value;

      context.emit('change-settings', editCell);
    };

    const changeTableCellDiffUnit = (val: OptionValue) => {
      let editCell = _.cloneDeep(props.tableCell);

      if (typeof val.value === 'string')
        // if (typeof tableCellDiffUnit.value.value === 'string')
        editCell.correctResult.linkedCells[0].value =
          tableCellDiffValue.value.toString() + val.value;
      context.emit('change-settings', editCell);
    };

    const unitOptions = ref<OptionValue[]>([
      new OptionValue('d', 'Tage'),
      new OptionValue('h', 'Stunden'),
      new OptionValue('m', 'Minuten'),
    ]);

    const cellFormatOptions = ref<OptionValue[]>([
      new FormatOptionValue(
        context.root.$t('general.formatOptions.noFormat') as string,
        'noFormat'
        // ''
      ),
      new FormatOptionValue(
        context.root.$t('general.formatOptions.time') as string,
        'time'
        // '##:##'
      ),
      new FormatOptionValue(
        context.root.$t('general.formatOptions.dateTime') as string,
        'dateTime'
        // '##/##/#### ##:##'
      ),
      new FormatOptionValue(
        context.root.$t('general.formatOptions.centigrade') as string,
        'centigrade'
        // '##,##C'
      ),
    ]);

    const changeColumnWidth = (colIdx: number, val: string) => {
      let editFieldValue = _.cloneDeep(props.fieldConfig);
      editFieldValue.width[colIdx] = val;
      context.emit('change-width', colIdx, val);
    };

    const linkedCellDateTimeValue = computed(() => {
      let tmp;
      if (props.tableCell.correctResult.linkedCells[0].value) {
        tmp = parseFloat(props.tableCell.correctResult.linkedCells[0].value);
      }
      return tmp;
    });
    const linkedCellDateTimeUnitValue = computed(() => {
      let tmp = '';
      tmp =
        props.tableCell.correctResult.linkedCells[0].value[
          props.tableCell.correctResult.linkedCells[0].value.length - 1
        ];

      switch (tmp) {
        case 'd':
          return new OptionValue('d', 'Tage');
        // break;
        case 'h':
          return new OptionValue('h', 'Stunden');
        // break;
        case 'm':
          return new OptionValue('m', 'Minuten');
        // break;
      }
      return tmp;
    });

    const changeLinkedCellDateTimeValue = (val: string) => {
      let editCell = _.cloneDeep(props.tableCell);
      if (linkedCellDateTimeUnitValue.value instanceof OptionValue)
        if (typeof linkedCellDateTimeUnitValue.value.value === 'string')
          editCell.correctResult.linkedCells[0].value =
            val + linkedCellDateTimeUnitValue.value.value;

      context.emit('change-settings', editCell);
    };

    const changeLinkedCellDateTimeUnitValue = (val: OptionValue) => {
      let editCell = _.cloneDeep(props.tableCell);

      if (typeof val.value === 'string')
        editCell.correctResult.linkedCells[0].value =
          tableCellDiffValue.value.toString() + val.value;
      context.emit('change-settings', editCell);
    };

    return {
      OptionValue,
      settingsDialogTab,
      typeOptionList,
      changeCorrectAnswerType,
      changeLinkedCellMode,
      changeLinkedCellValue,
      changeStart,
      changeEnd,
      changeCellMask,
      changePlaceholder,
      finishSettings,
      changeAllowUserInput,
      changeRequired,
      changeCheckRelevant,
      linkedCellRowOptions,
      linkedCellColOptions,
      changeLinkedCell,
      showChangeLinkedCellFormatInfo,
      addLinkedCell,
      deleteLinkedCell,
      tableCellDiffValue,
      tableCellDiffUnit,
      changeTableCellDiffValue,
      changeTableCellDiffUnit,
      unitOptions,
      cellFormatOptions,
      changeColumnWidth,
      // currentFormatValue,
      linkedCellDateTimeValue,
      linkedCellDateTimeUnitValue,
      changeLinkedCellDateTimeValue,
      changeLinkedCellDateTimeUnitValue,
    };
  },
});
