





































import { defineComponent, computed } from '@vue/composition-api';
import _ from 'lodash';
//components
//interfaces
import { CheckboxFieldValue, PayloadVal } from 'src/interfaces/Form.Interface';

export default defineComponent({
  name: 'gwCheckboxField',
  components: {},
  props: {
    fieldValue: {
      type: Object as () => CheckboxFieldValue,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    setAnswerMode: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showWrongAnswers: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, context) {
    const changeValue = (idx: number, newValue: boolean) => {
      let editFieldValue = _.cloneDeep(props.fieldValue);
      if (props.setAnswerMode)
        editFieldValue.options[idx].correctResult = newValue;
      else editFieldValue.options[idx].result = newValue;

      context.emit('input', editFieldValue);
    };
    const changeLabel = (newLabel: string, idx: number) => {
      let editFieldValue = _.cloneDeep(props.fieldValue);
      editFieldValue.options[idx].label = newLabel;
      context.emit('input', editFieldValue);
    };

    const onDelete = (idx: number) => {
      let editFieldValue = _.cloneDeep(props.fieldValue);
      editFieldValue.options.splice(idx, 1);
      context.emit('input', editFieldValue);
    };

    const onClickInput = (optIdx: number, evt: Event) => {
      let payload: PayloadVal = {
        fieldType: 'checkboxField',
        optIdx: optIdx,
      };
      evt.stopPropagation();

      context.emit('click-option', payload);
    };

    const color = computed(() => {
      const haveWrong = props.fieldValue.options.find(
        (entry) => entry.correctResult !== entry.result
      );

      if (
        props.showWrongAnswers &&
        props.fieldValue.checkRelevant &&
        haveWrong
      ) {
        return 'negative';
      } else {
        return 'primary';
      }
    });

    return { changeValue, changeLabel, onDelete, onClickInput, color };
  },
});
