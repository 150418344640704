






















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'gwKnobCard',
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    captionText: {
      type: String,
      default: ''
    },
    counter: {
      type: String,
      default: ''
    },
    chipCounter: {
      type: String,
      default: ''
    },
    linkText: {
      type: String,
      default: 'Details'
    },
    knobCounter: {
      type: Number,
      default: -1
    },
    knobCaption: {
      type: String,
      default: ''
    },
    colorIcon: {
      type: String,
      default: '#fff'
    },
    showIcon: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    return {};
  }
});
