




























































































import { defineComponent, ref, computed } from '@vue/composition-api';
import _ from 'lodash';
//components
import gwRadioField from 'src/components/formular/fieldComponents/gwRadioField/gwRadioField.vue';
import gwCheckboxField from 'src/components/formular/fieldComponents/gwCheckboxField/gwCheckboxField.vue';
import gwValueTable from 'src/components/formular/fieldComponents/gwValueTable/gwValueTable.vue';
import gwTextField from 'src/components/formular/fieldComponents/gwTextField/gwTextField.vue';
import gwFieldAnswerCard from 'src/components/formular/answerComponents/gwFieldAnswerCard.vue';
import gwTextEditor from 'components/basic/gwTextEditor/gwTextEditor.vue';

//interfaces
import { OptionValue } from 'src/interfaces/Common.Interface';
import {
  CheckboxFieldValue,
  FieldOptionValue,
  FormFieldType,
  PayloadVal,
  RadioFieldValue,
  TableFieldValue,
  TextFieldValue,
} from 'src/interfaces/Form.Interface';

export default defineComponent({
  name: 'gwFormFieldCreator',
  components: {
    gwRadioField,
    gwCheckboxField,
    gwValueTable,
    gwTextField,
    gwFieldAnswerCard,
    gwTextEditor,
  },
  props: {
    formField: {
      type: Object as () => FormFieldType,
      required: true,
    },
    formtype: {
      type: String,
      required: true,
    },
  },

  setup(props, context) {
    const changeFieldType = (type: OptionValue) => {
      let editField = _.cloneDeep(props.formField);
      if (typeof type.value === 'string') editField.typeName = type.value;

      switch (editField.typeName) {
        case 'gwRadioField':
          editField.optionList = new RadioFieldValue();
          break;
        case 'gwCheckboxField':
          editField.optionList = new CheckboxFieldValue();
          break;
        case 'gwTextField':
          editField.optionList = new TextFieldValue();
          break;
        case 'gwValueTable':
          editField.optionList = new TableFieldValue();
          break;
      }

      context.emit('change-field', editField);
    };

    const addOption = () => {
      let editField = _.cloneDeep(props.formField);

      if (editField.optionList instanceof RadioFieldValue) {
        editField.optionList.options.push('');
      } else if (editField.optionList instanceof CheckboxFieldValue) {
        editField.optionList.options.push({
          label: '',
          result: false,
          correctResult: false,
        });
      }

      context.emit('change-field', editField);
    };

    const toggleRequired = (val: boolean) => {
      let editField = _.cloneDeep(props.formField);
      editField.required = val;

      if (editField.optionList instanceof TableFieldValue) {
        editField.optionList.setAllCellsRequired(val);
      } else if (editField.optionList instanceof RadioFieldValue) {
        editField.optionList.required = val;
      }

      context.emit('change-field', editField);
    };
    const clickDelete = () => {
      context.emit('delete');
    };
    const clickCopy = () => {
      context.emit('copy');
    };

    const changeFieldLabel = (val: string) => {
      context.emit('change-field-label', val);
    };
    const changeFieldDescription = (val: string) => {
      let editField = _.cloneDeep(props.formField);
      editField.description = val;
      context.emit('change-field', editField);
    };

    const changeFieldValue = (value: FieldOptionValue) => {
      let editField = _.cloneDeep(props.formField);

      editField.optionList = value;

      context.emit('change-field', editField);
    };

    const answerView = ref<{ value: boolean; type: string }>({
      value: false,
      type: '',
    });

    const toggleAnswerView = (fieldType: string) => {
      answerView.value.type = fieldType;
      answerView.value.value = true;
    };

    const fieldOptionList = computed(() => {
      let list = [];
      list.push(new OptionValue('gwTextField', 'Textfeld'));
      list.push(new OptionValue('gwRadioField', 'Radio'));
      list.push(new OptionValue('gwCheckboxField', 'Checkbox'));
      list.push(new OptionValue('gwValueTable', 'Tabelle'));
      return list;
    });

    const currentFieldtypeName = computed(() => {
      let obj = fieldOptionList.value.find(
        (o) => o.value === props.formField.typeName
      );
      return obj?.label;
    });

    const showAddOptionButton = computed(() => {
      switch (props.formField.typeName) {
        case 'gwValueTable':
        case 'gwTextField':
          return false;
        default:
          return true;
      }
    });

    const fieldHaveAnswer = computed(() => {
      switch (props.formField.typeName) {
        case 'gwTextField':
          return false;

        default:
          return true;
      }
    });

    const onClickField = () => {
      context.emit('click-field');
    };

    const onClickOption = (payload: PayloadVal) => {
      context.emit('click-option', payload);
    };

    return {
      changeFieldType,
      addOption,
      toggleRequired,
      clickDelete,
      clickCopy,
      changeFieldLabel,
      changeFieldDescription,
      changeFieldValue,
      answerView,
      toggleAnswerView,
      fieldOptionList,
      currentFieldtypeName,
      showAddOptionButton,
      fieldHaveAnswer,
      onClickField,
      onClickOption,
    };
  },
});
