import { AddressValue } from './User.Interface';

export interface LocationManagerValue {
  type: string;
  id: string;
}

export class OrganisationLocalLocation {
  _id?: string;
  _rev?: string;
  doctype = 'location';
  name: string;
  userIds: string[];
  deviceIds: string[];
  address: AddressValue;
  locationManager: LocationManagerValue;
  constructor(
    name = '',
    userIds: string[] = [],
    deviceIds: string[] = [],
    address: AddressValue = {
      street: '',
      number: '',
      country: '',
      zip: '',
      city: '',
      state: '',
    },
    locationManager: LocationManagerValue = {
      type: 'string',
      id: 'string',
    }
  ) {
    this.name = name;
    this.userIds = userIds;
    this.deviceIds = deviceIds;
    this.address = address;
    this.locationManager = locationManager;
  }
}
