




























































import { defineComponent, computed } from '@vue/composition-api';
import store from 'src/store/directStore';
// components
import DeviceQrCode from 'src/components/device/DeviceQrCode.vue';
// interfaces
import { ActionRequest } from 'src/interfaces/Action.Interface';
import { CheckDefinition } from 'src/interfaces/Check.Interface';
import { Device, DeviceType } from 'src/interfaces/Device.Interface';
import { Formular } from 'src/interfaces/Form.Interface';
import { dateFormat } from 'src/config/settingsConfig';

export default defineComponent({
  name: 'ActionRequestOverview',
  components: { DeviceQrCode },
  props: {
    actionRequest: {
      type: Object as () => ActionRequest,
      required: true,
    },
    device: {
      type: Object as () => Device,
      required: true,
    },
    checkDefinition: {
      type: Object as () => CheckDefinition,
      required: true,
    },
    deviceType: {
      type: Object as () => DeviceType,
      required: true,
    },
    formular: {
      type: Object as () => Formular,
    },
  },
  setup(props, context) {
    const inspector = computed(() => {
      let inspector = '';
      if (props.device) {
        if (props.device.assignedTo.type === 'user') {
          inspector = store.getters.deviceAdmin.getUserNameById(
            props.device.assignedTo.user
          );
        } else {
          inspector = store.getters.deviceAdmin.getCurrentUserName();
        }
      }
      return inspector;
    });

    const onClickInspector = () => {
      switch (props.device.assignedTo.type) {
        case 'user':
          void context.root.$router.push(
            '/user/edit/' + props.device.assignedTo.user
          );
          break;
        case 'serviceProvider':
          void context.root.$router.push(
            '/service-provider/edit/' + props.device.assignedTo.user
          );
          break;
        case 'system':
        case 'intern':
          void context.root.$router.push('/settings/organisation/');
          break;
      }
    };

    const onClickDevice = () => {
      void context.root.$router.push('/devices/detail/' + props.device._id);
    };

    return { inspector, onClickInspector, onClickDevice, dateFormat };
  },
});
