import { Type } from 'class-transformer';
// import 'reflect-metadata';
//interfaces
import { MailServerValue } from './Common.Interface';
import { AddressValue } from './User.Interface';

export interface ReportIntervall {
  type: string;
}

// interface WeeklyInterval {
//   [key: string]: boolean;
//   monday: boolean;
//   tuesday: boolean;
//   wednesday: boolean;
//   thursday: boolean;
//   friday: boolean;
//   saturday: boolean;
//   sunday: boolean;
// }
export interface ExtendInterval {
  type: string;
  repeatOn: number;
  // options: WeeklyInterval;
  options: number[];
}

export interface OrganisationSettings {
  partnerDeviceSettings: PartnerDeviceSettings;
  canAssignToCustomerStandalone?: boolean;
  safeMode?: boolean;
  safeModeMail?: string;

  calendarShowShippingRequestDaysBefore?: boolean;
  calendarShippingRequestDaysBefore?: number;
  onlyUseWorkdaysForShippingRequest?: boolean;

  calendarShowDeliveryDate?: boolean;

  sendEscalationReport?: boolean;
  reportReceiver?: string[];
  sendReportToSupervisor?: boolean;
  escalationReportIntervall?: ExtendInterval;

  showCheckDateOnServiceRequest?: boolean;
}

export interface PublicOrganisationSettings {
  showCheckDateOnServiceRequest?: boolean;
}

export interface PartnerDeviceSettings {
  sendMailForAllPartners: boolean;
  unlinkPartnerOnIncoming: boolean;
}
export class RangeTimeValue {
  from: string;
  to: string;
  constructor(from = '', to = '') {
    this.from = from;
    this.to = to;
  }
}
export class BlockedDates {
  holidays: boolean;
  weekend: boolean;
  weekDays: number[];
  dates: string[];
  @Type(() => RangeTimeValue)
  blockedTimeRange: RangeTimeValue[];
  blockNextWeekday: boolean;
  blockNextWorkday: boolean;
  blockToday: boolean;
  blockDaysInPast: boolean;
  constructor(
    holidays = false,
    weekend = false,
    weekDays: number[] = [],
    dates: string[] = [],
    blockedTimeRange: RangeTimeValue[] = [],
    blockNextWeekday = false,
    blockNextWorkday = true,
    blockToday = true,
    blockDaysInPast = false
  ) {
    this.holidays = holidays;
    this.weekend = weekend;
    this.weekDays = weekDays;
    this.dates = dates;
    this.blockedTimeRange = blockedTimeRange;
    this.blockNextWeekday = blockNextWeekday;
    this.blockNextWorkday = blockNextWorkday;
    this.blockToday = blockToday;
    this.blockDaysInPast = blockDaysInPast;
  }
}

export class ShippingSetting {
  type: string; //range or normal
  counter: number;
  maxDaysInFuture?: number; //on -1 or undefined no dates are blocked
  @Type(() => BlockedDates)
  blockedDates: BlockedDates;
  constructor(
    type = 'normal',
    counter = 3,
    maxDaysInFuture = 51,
    blockedDates = new BlockedDates()
  ) {
    this.type = type;
    this.counter = counter;
    this.maxDaysInFuture = maxDaysInFuture;
    this.blockedDates = blockedDates;
  }
}
export class ShippingDatesTyp {
  [key: string]: ShippingSetting;
  @Type(() => ShippingSetting)
  pickup: ShippingSetting;
  @Type(() => ShippingSetting)
  delivery: ShippingSetting;
  constructor(
    pickup = new ShippingSetting(),
    delivery = new ShippingSetting()
  ) {
    this.pickup = pickup;
    this.delivery = delivery;
  }
}

export class SelectShippingDatesSettingsValue {
  [key: string]: ShippingDatesTyp; //countryCode:...
  @Type(() => ShippingDatesTyp)
  DE: ShippingDatesTyp;
  @Type(() => ShippingDatesTyp)
  NL: ShippingDatesTyp;
  @Type(() => ShippingDatesTyp)
  AT: ShippingDatesTyp;
  constructor() {
    this.DE = new ShippingDatesTyp();
    this.NL = new ShippingDatesTyp();
    this.AT = new ShippingDatesTyp();
  }
}

export class ShippingSettingValue {
  [key: string]:
    | UPSShippingSetting
    | BlockedDates
    | SelectShippingDatesSettingsValue;
  ups: UPSShippingSetting;
  @Type(() => BlockedDates)
  blockedPickupDE: BlockedDates;
  @Type(() => BlockedDates)
  blockedPickupNL: BlockedDates;
  @Type(() => BlockedDates)
  blockedPickupAT: BlockedDates;
  @Type(() => BlockedDates)
  blockedDeliveryDE: BlockedDates;
  @Type(() => BlockedDates)
  blockedDeliveryNL: BlockedDates;
  @Type(() => BlockedDates)
  blockedDeliveryAT: BlockedDates;
  @Type(() => SelectShippingDatesSettingsValue)
  settings: SelectShippingDatesSettingsValue;
  constructor(
    ups: UPSShippingSetting,
    blockedPickupDE = new BlockedDates(),
    blockedPickupNL = new BlockedDates(),
    blockedPickupAT = new BlockedDates(),
    blockedDeliveryDE = new BlockedDates(),
    blockedDeliveryNL = new BlockedDates(),
    blockedDeliveryAT = new BlockedDates(),
    settings = new SelectShippingDatesSettingsValue()
    // settings = {}
  ) {
    this.ups = ups;
    this.blockedPickupDE = blockedPickupDE;
    this.blockedPickupNL = blockedPickupNL;
    this.blockedPickupAT = blockedPickupAT;

    this.blockedDeliveryDE = blockedDeliveryDE;
    this.blockedDeliveryNL = blockedDeliveryNL;
    this.blockedDeliveryAT = blockedDeliveryAT;

    this.settings = settings;
  }
}
export interface UPSShippingSetting {
  username: string;
  password: string;
  AccessLicenseNumber: string;
}

export class OrganisationValue {
  [key: string]:
    | string
    | AddressValue
    | MailServerValue
    | OrganisationSettings
    | ShippingSettingValue
    | undefined;
  _id?: string;
  _rev?: string;
  doctype: string;
  name: string;
  adress: AddressValue;
  mailServer: MailServerValue;
  defaultMailAddress: string;
  settings: OrganisationSettings;
  @Type(() => ShippingSettingValue)
  shippingSettings: ShippingSettingValue;
  constructor(
    name: string,
    adress: AddressValue,
    mailServer: MailServerValue,
    defaultMailAddress: string,
    settings: OrganisationSettings,
    shippingSettings: ShippingSettingValue
  ) {
    this.doctype = 'organisation';
    this.name = name;
    this.adress = adress;
    this.mailServer = mailServer;
    this.defaultMailAddress = defaultMailAddress;
    this.settings = settings;
    this.shippingSettings = shippingSettings;
  }
}
