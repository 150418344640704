





























import { defineComponent, computed } from '@vue/composition-api';
import { date } from 'quasar';
//components
import gwTabCard from 'src/components/basic/tabCards/gwTabCard.vue';
//interfaces
interface ShippingListEntry {
  title: string;
  subtitle: string;
  originalIdx: number;
}
//composition
import { SelectedShippingDates } from 'src/interfaces/Action.Interface';
import { dateFormat } from 'src/config/settingsConfig';

export default defineComponent({
  name: 'gwCustomerCard',
  components: { gwTabCard },
  props: {
    shippingDates: {
      type: Array as () => SelectedShippingDates[],
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const formattedList = computed(() => {
      let parsedDateList: {
        date: Date;
        originalIdx: number;
        title: string;
        subtitle: string;
      }[] = [];

      props.shippingDates.forEach((entry, idx) => {
        switch (entry.type) {
          case 'normal': {
            parsedDateList.push({
              date: entry.date,
              originalIdx: idx,
              title: entry.date.toLocaleDateString('de', dateFormat),
              subtitle: '',
            });
            break;
          }
          case 'dateWithTimeRange': {
            parsedDateList.push({
              date: entry.date,
              originalIdx: idx,
              title: entry.date.toLocaleDateString('de', dateFormat),
              subtitle: `${entry.timeRange.from} - ${entry.timeRange.to}`,
            });

            break;
          }
          case 'range': {
            const fromDate = date.extractDate(
              entry.timeRange.from,
              'YYYY/MM/DD'
            );
            const toDate = date.extractDate(entry.timeRange.to, 'YYYY/MM/DD');

            parsedDateList.push({
              date: fromDate,
              originalIdx: idx,
              title: `${fromDate.toLocaleDateString(
                'de',
                dateFormat
              )} - ${toDate.toLocaleDateString('de', dateFormat)}`,
              subtitle: '',
            });

            break;
          }
        }
      });

      // @ToDo enable sorting / refactoring
      parsedDateList.sort((a, b) => {
        return a.date.getTime() - b.date.getTime();
      });

      return parsedDateList;
    });

    const select = (idx: number) => {
      context.emit('select-date', props.shippingDates[idx]);
    };
    return { formattedList, select };
  },
});
