import { render, staticRenderFns } from "./gwShippingSettingsCard.vue?vue&type=template&id=9fcecb4a&scoped=true&"
import script from "./gwShippingSettingsCard.vue?vue&type=script&lang=ts&"
export * from "./gwShippingSettingsCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9fcecb4a",
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QBtn,QInput,QCardActions,QSelect,QCheckbox,QOptionGroup,QList,QItem});
