



































import { defineComponent } from '@vue/composition-api';
// components
// interfaces
import { ShippingListValue } from 'src/interfaces/Shipping.Interface';
// compositions

export default defineComponent({
  name: 'ReplacementView',
  props: {
    shippingList: {
      type: Array as () => ShippingListValue[],
      required: true,
    },
  },
  setup() {
    return {
      // ===== interfaces =========
      // ===== stores =============
      // ===== compositions =======
      // ===== self ===============
    };
  },
});
