import { render, staticRenderFns } from "./ShippingReceiverSelect.vue?vue&type=template&id=bcc7cb98&"
import script from "./ShippingReceiverSelect.vue?vue&type=script&lang=ts&"
export * from "./ShippingReceiverSelect.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtnToggle from 'quasar/src/components/btn-toggle/QBtnToggle.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QMarkupTable from 'quasar/src/components/markup-table/QMarkupTable.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QCardSection,QBtnToggle,QTabPanels,QTabPanel,QSelect,QItem,QItemSection,QItemLabel,QIcon,QMarkupTable});
