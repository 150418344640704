import { render, staticRenderFns } from "./gwTabCardHorizontal.vue?vue&type=template&id=0caf0dd9&scoped=true&"
import script from "./gwTabCardHorizontal.vue?vue&type=script&lang=ts&"
export * from "./gwTabCardHorizontal.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0caf0dd9",
  null
  
)

export default component.exports
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QToolbar,QBtn,QSpace,QTooltip,QTabPanels,QTabPanel});
