// import {
//   // SetupContext,
//   ref
// } from '@vue/composition-api';

// import { AcroFormCheckBox, jsPDF } from 'jspdf';
import { jsPDF, AcroFormCheckBox, AcroFormRadioButton } from 'jspdf';
// import * as jsPDF from 'jspdf';
import { Formular } from 'src/interfaces/Form.Interface';

export const usePrintHandling = () => {
  const printQRCode = (deviceId: string, deviceInvNumber: string) => {
    console.log('printQRCode', deviceId, deviceInvNumber);
    // printJS('testqr', 'html');
    const doc = new jsPDF({
      // orientation: 'landscape',
      // unit: 'in',
      format: [25, 25],
      orientation: 'landscape',
      unit: 'mm'
      // format: [84, 41]
      // format: [41, 41]
    });
    // doc.setFontSize(15);
    // doc.text('Crave Cookie', 43, 20);

    doc.setFontSize(9);
    // let inv = props.device.inventoryNumber;
    // const inv = deviceInvNumber;
    // doc.text('Inventarnummer', 5, 22);
    doc.text(deviceInvNumber, 2, 23);

    const imageElement = document.getElementById('qr-code') as HTMLImageElement;
    console.log('imageElement', imageElement);

    // if (imageElement) await doc.html(imageElement);
    let source = '';

    if (imageElement) source = imageElement.src;

    // let base64Image = $('#qr_code img').attr('src');
    doc.addImage(source, 'png', 2.5, 0, 20, 20);

    doc.autoPrint(); //print automatic qrcode
    doc.output('dataurlnewwindow');
    // doc.output('pdfobjectnewwindow');
    // doc.save('a4.pdf');

    // if (props.device._id)
    //   window.open(
    //     '/#/qrcodeview?id=' +
    //       props.device._id +
    //       '&inv=' +
    //       props.device.inventoryNumber,
    //     '_blank'
    //   );
    // window.print();
    // context.root.$router.push('/qrcodeview?content=' + formId).catch(e => {
    //   throw new Error(e);
    // });
  };

  const printFormular = (formular: Formular) => {
    const doc = new jsPDF({
      // orientation: 'landscape',
      // unit: 'in',
      // format: [25, 25],
      // orientation: 'landscape',
      // unit: 'mm'
      // format: [84, 41]
      // format: [41, 41]
    });

    doc.setFontSize(12);

    const pdfConfig = {
      headerTextSize: 20,
      labelTextSize: 12,
      fieldTextSize: 10,
      lineHeight: 6,
      subLineHeight: 4
    };
    doc.setFontSize(pdfConfig.headerTextSize);

    doc.text(formular.label, 105, 10);
    doc.text(formular.description, 105, 22);

    for (const section of formular.sections) {
      doc.text(section.name, 105, 22);
    }
    doc.text('CheckBox:', 10, 100);
    const checkbox = new AcroFormCheckBox();

    checkbox.value = 'YES';
    checkbox.showWhenPrinted = true;
    checkbox.fieldName = 'fieldCheckbox';
    checkbox.caption = 'checkboxCaption';
    checkbox.width = 5;
    checkbox.height = 5;
    checkbox.color = 'red';
    checkbox.y = 100;
    checkbox.x = 30;
    checkbox.caption = 'caption';
    checkbox.appearanceState = 'Off';
    checkbox.noToggleToOff = true;

    doc.addField(checkbox);

    doc.text('RadioGroup:', 50, 165);
    const radioGroup = new AcroFormRadioButton();
    radioGroup.value = 'Test';
    doc.addField(radioGroup);

    console.log('PDF', doc);

    doc.text('fill text', 2, 23);
    // doc.autoPrint(); //print automatic qrcode
    doc.output('dataurlnewwindow');
  };

  return { printQRCode, printFormular };
};
