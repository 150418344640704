export const dateFormat: { [key: string]: string } = {
  weekday: 'short',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
};
export const dateFormatTime: Intl.DateTimeFormatOptions = {
  weekday: 'short',
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
};
// prettier-ignore
export const config = [
  {
    section1: [{}]
  },
  {
    section2: [{}]
  }
];
