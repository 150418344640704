













































































































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import store from 'src/store/directStore';
import _ from 'lodash';
//components
import gwTabCardHorizontal from 'components/basic/tabCards/gwTabCardHorizontal.vue';
import gwFormFieldCreator from 'src/components/formular/gwFormFieldCreator.vue'; //git fehler case sensitive
import { Device, DeviceType } from 'src/interfaces/Device.Interface';
import { CheckDefinition } from 'src/interfaces/Check.Interface';
import ActionRequestCard from 'src/components/checks/ActionRequestCard.vue';

//interfaces
//composition
import { useRequestHandling } from 'src/compositions/RequestHandling';
import { useBarcodeHandling } from 'src/compositions/BarcodeHandling';
//configs
import { statusConfig } from 'src/config/statusConfig';
import { AssignedValue } from 'src/interfaces/Common.Interface';
import { dateFormat } from 'src/config/settingsConfig';

export default defineComponent({
  name: 'IncomingCard',
  components: { gwFormFieldCreator, gwTabCardHorizontal, ActionRequestCard },
  setup(props, context) {
    const { updateDocument, getDocument, getOldDevice } = useRequestHandling();
    const { parseQrCode } = useBarcodeHandling();

    const incomingDeviceList = ref<Device[]>([]);

    const activeTab = ref<string>('');
    const incomingAssetId = ref<string>();

    const inputLoading = ref<boolean>(false);
    const showSubmitIncomingDialog = ref<boolean>(false);
    const currentPage = ref<number>(1);
    const maxPage = ref<number>(1);

    const device = ref<Device>();

    const loading = ref<boolean>(false);
    const showAddManuellAddButton = ref<boolean>(false);

    const onClickManuellAddButton = () => {
      if (inputDevice.value instanceof Device) {
        incomingDeviceList.value.push(inputDevice.value);
        inputDevice.value = undefined;
        incomingAssetId.value = '';
        showAddManuellAddButton.value = false;
      }
    };

    const onClose = () => {
      context.emit('close');
    };

    const submitIncoming = async () => {
      if (device.value) {
        device.value.assignedTo = {
          user: '',
          userGroup: '',
          customer: '',
          type: 'system',
        };

        if (deviceHaveDeliveryCheck.value) device.value.status = 10;
        else device.value.status = 1;

        await updateDocument(device.value);
      }
    };

    const deviceHaveDeliveryCheck = computed(() => {
      if (device.value) {
        let needDeliveryCheck = false;

        let devType = store.getters.deviceAdmin.getDeviceTypeById(
          device.value.type
        );

        if (device.value.assignedTo.type !== 'user')
          if (devType instanceof DeviceType) {
            let checkList = devType.checkDefinitions;

            checkList.forEach((checkDefId) => {
              let checkDef =
                store.getters.deviceAdmin.getCheckDefinitionById(checkDefId);
              if (checkDef instanceof CheckDefinition) {
                if (checkDef.type === 'deliveryCheck') needDeliveryCheck = true;
              }
            });
          }

        return needDeliveryCheck;
      }
    });

    const inputDevice = ref<Device>();

    const changeInput = async () => {
      console.log('changeInput', incomingAssetId.value);
      inputLoading.value = true;
      if (incomingAssetId.value) {
        if (incomingAssetId.value.includes('http')) {
          //isURL

          const parsedCode = parseQrCode(incomingAssetId.value);
          if (parsedCode.valid) {
            const extractDeviceId = parsedCode.deviceId;
            const getDevice =
              store.getters.deviceAdmin.getDeviceById(extractDeviceId);

            if (getDevice instanceof Device) {
              const find = incomingDeviceList.value.find(
                (entry) => entry._id === extractDeviceId
              );
              if (!find) {
                incomingDeviceList.value.push(getDevice);
              }
            } else {
              const oldInventoryNumber = await getOldDevice(
                parsedCode.deviceId
              );
              const getDevice =
                store.getters.deviceAdmin.getDeviceByInventoryNumber(
                  oldInventoryNumber
                );
              if (getDevice instanceof Device) {
                const find = incomingDeviceList.value.find(
                  (entry) => entry._id === getDevice._id
                );
                if (!find) incomingDeviceList.value.push(getDevice);
              } else {
                const getDevice =
                  store.getters.deviceAdmin.getDeviceByInventoryNumber(
                    oldInventoryNumber.replace(' ', '')
                  );
                if (getDevice instanceof Device) {
                  const find = incomingDeviceList.value.find(
                    (entry) => entry._id === getDevice._id
                  );
                  if (!find) incomingDeviceList.value.push(getDevice);
                }
              }
            }

            incomingAssetId.value = '';
            showAddManuellAddButton.value = false;
            inputLoading.value = false;
            error.value = false;
          }
        } else {
          //noURL
          const device = store.getters.deviceAdmin.getDeviceByInventoryNumber(
            incomingAssetId.value
          );
          if (device instanceof Device) {
            inputDevice.value = device;
            showAddManuellAddButton.value = true;
            inputLoading.value = false;
            error.value = false;
          } else {
            showAddManuellAddButton.value = false;
            inputDevice.value = undefined;
            inputLoading.value = false;
            error.value = true;
          }
        }
      }
    };

    const onClickClear = () => {
      showAddManuellAddButton.value = false;
      inputDevice.value = undefined;
      inputLoading.value = false;
      error.value = false;
    };

    const onClickSubmit = () => {
      showSubmitIncomingDialog.value = true;
      maxPage.value = incomingDeviceList.value.length;
    };

    const onSubmit = async () => {
      loading.value = true;
      for (const device of incomingDeviceList.value) {
        const actionRequests = store.state.deviceAdmin.actionRequestList.filter(
          (entry) => entry.command.value.deviceId === device._id
        );
        if (actionRequests.length > 0) {
          for (const actionRequest of actionRequests) {
            const checkDefId = actionRequest.command.value.checkDefinitionId;
            const checkDefinition =
              store.state.deviceAdmin.checkDefinitionList.find(
                (entry) => entry._id === checkDefId
              );
            if (checkDefinition instanceof CheckDefinition) {
              const editActionRequest = _.cloneDeep(actionRequest);
              if (device.assignedTo.type === 'user') {
                switch (checkDefinition.carriedFrom.type) {
                  case 'extern':
                    editActionRequest.status =
                      'waiting-for-shipping-to-service-provider';
                    break;
                  case 'intern':
                    editActionRequest.status = 'readyToCheck';
                    break;
                }
              } else if (device.assignedTo.type === 'serviceProvider') {
                switch (checkDefinition.carriedFrom.type) {
                  case 'extern':
                    editActionRequest.status = 'readyToCheck';
                    break;
                  case 'intern':
                    editActionRequest.status = 'readyToCheck';
                    break;
                }
              }
              await updateDocument(editActionRequest);
            }
          }
        } else {
        }

        device.assignedTo = new AssignedValue('', '', '', 'system');
        // MARK CHECKS AND INVALID
        const deviceType = store.getters.deviceAdmin.getDeviceTypeById(
          device.type
        );
        if (deviceType instanceof DeviceType) {
          for (const checkDefId of deviceType.checkDefinitions) {
            const checkDef =
              store.getters.deviceAdmin.getCheckDefinitionById(checkDefId);
            if (checkDef instanceof CheckDefinition) {
              if (
                checkDef.invalidOnIncoming !== undefined &&
                checkDef.invalidOnIncoming === true
              ) {
                for (const lastCheck of device.lastChecks) {
                  if (lastCheck.checkDefinitionId === checkDef._id) {
                    lastCheck['invalid'] = true;
                  }
                }
              }
            }
          }
        }

        if (device.shippingList) {
          const find = device.shippingList.find(
            (entry) => entry.incomingTime === undefined
          );
          if (find) {
            find.incomingTime = new Date();
          }
        }

        await updateDocument(device);
      }

      incomingDeviceList.value = [];
      incomingAssetId.value = undefined;
      selectedActionRequestId.value = undefined;
      selectedDevice.value = undefined;

      loading.value = false;
    };

    const getNewStatus = (val: Device) => {
      let res = 10;

      switch (val.status) {
        case 3:
        case 7:
          res = val.status;
          break;
        case 1:
          break;

        default:
          break;
      }
      return res;
    };

    const removeDevice = (idx: number) => {
      incomingDeviceList.value.splice(idx, 1);
    };

    const error = ref<boolean>(false);
    // const errorMessage = ref<string>();
    const errorMessage = computed(() => {
      if (error.value) {
        return 'Inventarnummer nicht gefunden';
      } else {
        return '';
      }
    });

    const selectedDevice = ref<Device>();

    const selectedDeviceOpenChecks = computed(() => {
      let list: {
        actionRequestId: string;
        checkDefinitionName: string;
        checkDate: string;
      }[] = [];

      if (selectedDevice.value) {
        const actionRequestList =
          store.state.deviceAdmin.actionRequestList.filter(
            (entry) =>
              selectedDevice.value &&
              entry.command.value.deviceId === selectedDevice.value._id
          );
        for (const actionRequest of actionRequestList) {
          const checkDefinition =
            store.getters.deviceAdmin.getCheckDefinitionById(
              actionRequest.command.value.checkDefinitionId
            );
          if (checkDefinition instanceof CheckDefinition) {
            list.push({
              actionRequestId: actionRequest._id,
              checkDefinitionName: checkDefinition.name,
              checkDate: actionRequest.created.toLocaleDateString(
                'de-De',
                dateFormat
              ),
            });
          }
        }
        return list;
      } else {
        return list;
      }
    });

    const selectedActionRequestId = ref<string>();
    const showActionRequestCard = ref<boolean>(false);

    const openActionRequest = (idx: number) => {
      selectedActionRequestId.value =
        selectedDeviceOpenChecks.value[idx].actionRequestId;
      showActionRequestCard.value = true;
    };

    const selectDevice = (idx: number) => {
      if (
        selectedDevice.value &&
        selectedDevice.value._id === incomingDeviceList.value[idx]._id
      ) {
        selectedDevice.value = undefined;
      } else {
        selectedDevice.value = incomingDeviceList.value[idx];
      }
    };

    const onSubmitActionRequest = async () => {
      loading.value = true;

      if (selectedDevice.value) {
        const updatedDevice = await getDocument(
          selectedDevice.value._id,
          'device'
        );
        if (updatedDevice instanceof Device) {
          let findIndex = incomingDeviceList.value.findIndex(
            (entry) => entry._id === updatedDevice._id
          );

          if (findIndex !== -1) {
            incomingDeviceList.value[findIndex] = updatedDevice;

            showActionRequestCard.value = false;
            selectedActionRequestId.value = undefined;
            selectedDevice.value = undefined;
          }

          loading.value = false;
        }
      }
    };

    const markDeviceDefect = () => {
      if (selectedDevice.value) {
        selectedDevice.value.status = 6;
      }
    };

    const canPerformCheck = (idx: number) => {
      const actionRequestId =
        selectedDeviceOpenChecks.value[idx].actionRequestId;

      const actionRequest = store.state.deviceAdmin.actionRequestList.find(
        (entry) => entry._id === actionRequestId
      );
      if (actionRequest) {
        const checkDefinitionId = actionRequest.command.value.checkDefinitionId;
        const checkDefinition =
          store.state.deviceAdmin.checkDefinitionList.find(
            (entry) => entry._id === checkDefinitionId
          );
        if (checkDefinition) {
          if (
            checkDefinition.carriedFrom.type === 'extern' &&
            selectedDevice.value?.assignedTo.type === 'user'
          ) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      statusConfig,
      activeTab,
      incomingAssetId,
      device,
      // submit,
      inputLoading,
      onClickManuellAddButton,
      onClose,
      submitIncoming,
      // changeInvNumber,
      changeInput,
      incomingDeviceList,
      onClickClear,
      onSubmit,
      loading,
      showSubmitIncomingDialog,
      showAddManuellAddButton,
      getNewStatus,
      removeDevice,
      error,
      errorMessage,
      onClickSubmit,
      currentPage,
      maxPage,
      selectedDeviceOpenChecks,
      openActionRequest,
      selectedDevice,
      selectDevice,
      showActionRequestCard,
      selectedActionRequestId,
      onSubmitActionRequest,
      markDeviceDefect,
      canPerformCheck,
    };
  },
});
