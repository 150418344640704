import { v4 } from 'uuid';
import { Type } from 'class-transformer';
import 'reflect-metadata';

export class UnitNumber {
  value: number;
  unit: string;
  constructor(value: number, unit: string) {
    this.value = value;
    this.unit = unit;
  }
  cssValue() {
    return `${this.value} ${this.unit}`;
  }
}

export class CostEntry {
  _id: string;
  label: string;
  @Type(() => UnitNumber)
  cost: UnitNumber;
  @Type(() => Date)
  date: Date;
  comment: string;
  serviceProviderId: string;
  deviceId?: string;

  constructor(
    label = '',
    cost: UnitNumber = new UnitNumber(0, '€'),
    date = new Date(),
    comment = '',
    serviceProviderId = '',
    deviceId = ''
  ) {
    this._id = v4();
    this.label = label;
    this.cost = cost;
    this.date = date;
    this.comment = comment;
    this.serviceProviderId = serviceProviderId;
    this.deviceId = deviceId;
  }
}
