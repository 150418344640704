



















































































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import store from 'src/store/directStore';
import _ from 'lodash';
// components
// interfaces
import {
  ExtendOptionValue,
  OptionValue,
} from 'src/interfaces/Common.Interface';
import { Customer, ServiceProvider, User } from 'src/interfaces/User.Interface';
import { OutgoingReceiverValue } from 'src/interfaces/Shipping.Interface';
import { Device } from 'src/interfaces/Device.Interface';

export default defineComponent({
  name: 'ShippingReceiverSelect',
  components: {},
  props: {
    value: {
      type: Object as () => OutgoingReceiverValue,
      required: true,
    },
    selectedDeviceList: {
      type: Array as () => Device[],
      required: true,
    },
  },
  setup(props, context) {
    const userFilter = ref<string>();
    const changeUserFilter = (val: string) => {
      userFilter.value = val;
    };

    const userList = computed(() => {
      let list: OptionValue[] = [];
      for (const usr of store.state.deviceAdmin.userList) {
        if (usr._id) {
          if (userFilter.value) {
            if (
              usr.firstname
                .toLowerCase()
                .includes(userFilter.value.toLowerCase()) ||
              usr.lastname
                .toLowerCase()
                .includes(userFilter.value.toLowerCase())
            ) {
              list.push({
                label: usr.firstname + ' ' + usr.lastname,
                value: usr._id,
              });
            }
          } else {
            list.push({
              label: usr.firstname + ' ' + usr.lastname,
              value: usr._id,
            });
          }
        }
      }

      return list;
    });

    const customerList = computed(() => {
      const list: ExtendOptionValue[] = [];
      list.push(new ExtendOptionValue('', 'Keine Auswahl', ''));
      for (const customer of store.state.deviceAdmin.customerList) {
        if (customer._id) {
          list.push(
            new ExtendOptionValue(
              customer._id,
              customer.firstname + ' ' + customer.lastname,
              customer.limitedToBelongDefinition
            )
          );
        }
      }
      return list;
    });

    const serviceProviderList = computed(() => {
      let list: OptionValue[] = [];
      store.state.deviceAdmin.serviceProviderList.forEach((usr) => {
        if (usr._id)
          list.push({
            label: usr.name,
            value: usr._id,
          });
      });
      return list;
    });

    const getCustomerSelectBelongColor = (belongDefinition: string) => {
      const deviceWithBelongDefinition = props.selectedDeviceList.find(
        (entry) => entry.belongDefinition
      );
      if (deviceWithBelongDefinition) {
        switch (belongDefinition) {
          case 'borrowDevice':
            const findBuy = props.selectedDeviceList.find(
              (entry) => entry.belongDefinition === 'buyDevice'
            );
            if (findBuy) return 'negative';
            else return 'positive';
            break;
          case 'buyDevice':
            const findBorrow = props.selectedDeviceList.find(
              (entry) => entry.belongDefinition === 'borrowDevice'
            );
            if (findBorrow) return 'negative';
            else return 'positive';

            break;
        }
      } else {
        return 'positive';
      }
    };

    const getCustomerIdentificationNumber = (customerId: string) => {
      const customer = store.getters.deviceAdmin.getCustomerById(customerId);
      if (customer instanceof Customer) {
        return customer.identificationNumber;
      } else {
        return '';
      }
    };

    const getCustomerBelongDefinition = (customerId: string) => {
      const customer = store.getters.deviceAdmin.getCustomerById(customerId);
      if (customer instanceof Customer) {
        return customer.limitedToBelongDefinition;
      } else {
        return '';
      }
    };

    const changeReceiverType = (type: string) => {
      const editReceiver = new OutgoingReceiverValue();
      editReceiver.assigned.type = type;
      context.emit('input', editReceiver);
    };

    const changeCustomer = (customerId: string) => {
      const editReceiver = _.cloneDeep(props.value);
      editReceiver.assigned.customerId = customerId;
      context.emit('input', editReceiver);
    };

    const changeReceiver = (userId: string, type: string) => {
      const editReceiver = _.cloneDeep(props.value);
      editReceiver.assigned.id = userId;
      editReceiver.assigned.type = type;
      console.log('TYPE', type);
      console.log('changeReceiver', userId);

      switch (editReceiver.assigned.type) {
        case 'user': {
          editReceiver.name = store.getters.deviceAdmin.getUserNameById(userId);
          const usr = store.getters.deviceAdmin.getUserById(userId);

          if (usr instanceof User) {
            editReceiver.name = usr.firstname + ' ' + usr.lastname;
            editReceiver.address.street = usr.address.street;
            editReceiver.address.number = usr.address.number;
            editReceiver.address.country = usr.address.country;
            editReceiver.address.zip = usr.address.zip;
            editReceiver.address.city = usr.address.city;
          }
          break;
        }
        case 'customer': {
          editReceiver.name =
            store.getters.deviceAdmin.getCustomerNameById(userId);
          const usr = store.getters.deviceAdmin.getCustomerById(userId);

          if (usr instanceof Customer) {
            editReceiver.name = usr.firstname + ' ' + usr.lastname;
            editReceiver.address.street = usr.address.street;
            editReceiver.address.number = usr.address.number;
            editReceiver.address.country = usr.address.country;
            editReceiver.address.zip = usr.address.zip;
            editReceiver.address.city = usr.address.city;
          }

          break;
        }
        case 'serviceProvider':
          const find = store.state.deviceAdmin.serviceProviderList.find(
            (entry) => entry._id === editReceiver.assigned.id
          );
          if (find instanceof ServiceProvider) {
            editReceiver.name = find.name;
            editReceiver.address.street = find.address.street;
            editReceiver.address.number = find.address.number;
            editReceiver.address.country = find.address.country;
            editReceiver.address.zip = find.address.zip;
            editReceiver.address.city = find.address.city;
          }
          break;
      }
      context.emit('input', editReceiver);
    };

    const changeAssignedType = (type: string) => {
      console.log('changeAssignedType', type);
    };

    const assignOptions = computed(() => {
      const list: { label: string; value: string }[] = [];
      list.push({
        label: 'Externer Dienstleister',
        value: 'serviceProvider',
      });
      list.push({
        label: context.root.$t('general.user').toString(),
        value: 'user',
      });
      if (store.state.deviceAdmin.organisation) {
        const orga = store.state.deviceAdmin.organisation;
        const settings = orga.settings;
        if (settings.canAssignToCustomerStandalone) {
          list.push({
            label: 'Kunde',
            value: 'customer',
          });
        }
      }
      return list;
    });

    return {
      changeUserFilter,
      userList,
      customerList,
      serviceProviderList,
      getCustomerSelectBelongColor,
      getCustomerIdentificationNumber,
      getCustomerBelongDefinition,
      changeReceiverType,
      changeCustomer,
      changeReceiver,
      changeAssignedType,
      assignOptions,
    };
  },
});
