import { Type } from 'class-transformer';
import 'reflect-metadata';

export class ScheduledJob {
  name: string;
  @Type(() => Date)
  date: Date | null;
  constructor(name: string, date: Date) {
    this.name = name;
    this.date = date;
  }
}
