import axios from 'axios';

//interfaces
import { AssignedValue, RecordTypes } from 'src/interfaces/Common.Interface';
//configs
import { apiServer } from 'src/config/serverConfig';
import { OutgoingCartValue } from 'src/interfaces/Shipping.Interface';

import { useRequestHandling } from './RequestHandling';

export const useShippingHandling = () => {
  const { updateDocument } = useRequestHandling();
  const getShippingLabel = async () => {
    const res = await axios.post<RecordTypes>(
      apiServer + '/get/shipping/label'
      // {
      //   auth: store.state.deviceAdmin.auth.token
      // }
    );
    return res.data;
  };

  const handleOutgoingCartValue = async (cart: OutgoingCartValue) => {
    for (const shippingEntry of cart.shippingList) {
      const device = shippingEntry.newDevice;
      if (device) {
        device.status = 2;
        device.assignedTo = new AssignedValue(
          cart.receiver.assigned.id,
          '',
          '',
          cart.receiver.assigned.type
        );
        await updateDocument(device);
      }
    }
  };

  const parseQrCode = (qrCodeLink: string) => {
    const extractDeviceId = qrCodeLink.slice(
      qrCodeLink.indexOf('?id=') + 4,
      qrCodeLink.length
    );
    return extractDeviceId;
  };

  return {
    getShippingLabel,
    handleOutgoingCartValue,
    parseQrCode,
  };
};
