























































































import { defineComponent, ref, computed } from '@vue/composition-api';
import _ from 'lodash';
//components
import gwTableCellSettingsCard from 'components/formular/fieldComponents/gwValueTable/gwTableCellSettingsCard.vue';
//interfaces
import { OptionValue } from 'src/interfaces/Common.Interface';
import {
  PayloadVal,
  TableCellDefinition,
  TableFieldValue,
} from 'src/interfaces/Form.Interface';

export default defineComponent({
  name: 'gwValueTable',
  components: { gwTableCellSettingsCard },
  props: {
    fieldValue: {
      type: Object as () => TableFieldValue,
      required: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    highlightWrongFields: {
      type: Boolean,
      default: false,
    },
    showWrongAnswers: {
      type: Boolean,
      required: true,
    },
    // setAnswerMode: {
    //   type: Boolean,
    //   default: false
    // }
  },
  setup(props, context) {
    const linkCellRow = ref<number>();
    const linkCellColumn = ref<number>();

    const changeValue = (newValue: string) => {
      context.emit('input', newValue);
    };

    const changeLabel = (newLabel: string) => {
      context.emit('change-label', newLabel);
    };

    const onDelete = () => {
      context.emit('delete');
    };

    const changeRows = () => {
      let editFieldValue = _.cloneDeep(props.fieldValue);
      context.emit('input', editFieldValue);
    };

    const changeInput = (colIdx: number, rowIdx: number, val: string) => {
      let editFieldValue = _.cloneDeep(props.fieldValue);
      editFieldValue.rows[rowIdx][colIdx].currentValue.value = val;
      context.emit('input', editFieldValue);
    };

    const tableAddColumn = (rowIdx: number) => {
      let editFieldValue = _.cloneDeep(props.fieldValue);
      editFieldValue.rows[rowIdx].push(new TableCellDefinition());
      // editFieldValue.rows[rowIdx].push({
      //   input: true,
      //   currentValue: {
      //     label: '',
      //     value: '',
      //     suffix: '',
      //     prefix: '',
      //     type: '',
      //     hint: '',
      //     mask: {
      //       value: '',
      //       label: 'Keine Formatvorgabe'
      //     },
      //     placeholder: ''
      //   },
      //   checkRelevant: false,
      //   correctResult: {
      //     type: 'range',
      //     start: '',
      //     end: '',
      //     linkedCells: []
      //   }
      // });

      if (editFieldValue.columns.length < editFieldValue.rows[rowIdx].length) {
        editFieldValue.columns.push('');
      }
      if (editFieldValue.width.length < editFieldValue.rows[rowIdx].length) {
        editFieldValue.width.push('215');
      }

      context.emit('input', editFieldValue);
    };
    const tableAddRow = () => {
      let editFieldValue = _.cloneDeep(props.fieldValue);

      editFieldValue.rows.push([]);
      context.emit('input', editFieldValue);
    };

    const changeColumnTitle = (idx: number, newValue: string) => {
      let editFieldValue = _.cloneDeep(props.fieldValue);

      editFieldValue.columns[idx] = newValue;
      context.emit('input', editFieldValue);
    };

    const deleteCell = (rowIdx: number, colIdx: number) => {
      let editFieldValue = _.cloneDeep(props.fieldValue);

      editFieldValue.rows[rowIdx].splice(colIdx, 1);

      if (colIdx >= columnOptionList.value) {
        editFieldValue.width.splice(colIdx, 1);
        editFieldValue.columns.splice(colIdx, 1);
      }

      context.emit('input', editFieldValue);
    };

    // const showMoreSettings = ref<boolean>(false);

    const currentSelectedCell = ref<TableCellDefinition>(
      new TableCellDefinition()
    );
    // const currentSelectedCell = ref<TableCellDefinition>({
    //   input: true,
    //   currentValue: {
    //     label: '',
    //     value: '',
    //     suffix: '',
    //     prefix: '',
    //     type: '',
    //     hint: '',
    //     mask: {
    //       value: '',
    //       label: 'Keine Formatvorgabe'
    //     },
    //     placeholder: ''
    //   },
    //   checkRelevant: false,
    //   correctResult: {
    //     type: 'range',
    //     start: '',
    //     end: '',
    //     linkedCells: [
    //       // {
    //       //   rowIdx: 0,
    //       //   colIdx: 0,
    //       //   mussBeEqual: false,
    //       //   mode: '',
    //       //   value: '',
    //       //   maxDiff: '',
    //       //   minDiff: '',
    //       //   format: ''
    //       // }
    //     ]
    //   }
    //   // format: {
    //   //   mask: '',
    //   //   placeholder: ''
    //   // }
    // });

    const currentSelectedRow = ref<number>(-1);
    const currentSelectedCol = ref<number>(-1);

    // const showMoreSettingsDialog = (rowIdx: number, colIdx: number) => {
    //   currentSelectedRow.value = rowIdx;
    //   currentSelectedCol.value = colIdx;
    //   let editFieldValue = _.cloneDeep(props.fieldValue);
    //   currentSelectedCell.value =
    //     editFieldValue.rows[currentSelectedRow.value][currentSelectedCol.value];
    //   showMoreSettings.value = true;
    // };

    const deleteLinkedCell = (
      currentSelectedRow: number,
      currentSelectedCol: number
    ) => {
      let editFieldValue = _.cloneDeep(props.fieldValue);
      editFieldValue.rows[currentSelectedRow][
        currentSelectedCol
      ].correctResult.linkedCells = [];

      context.emit('input', editFieldValue);
    };

    const deleteColumnHeader = (idx: number) => {
      let editFieldValue = _.cloneDeep(props.fieldValue);
      editFieldValue.columns.splice(idx, 1);

      context.emit('input', editFieldValue);
    };

    const rowList = computed(() => {
      const maxRow = props.fieldValue.rows.map((col) => col.length);
      return maxRow;
    });
    const columnOptionList = computed(() => {
      const maxCols = Math.max.apply(null, rowList.value);

      return maxCols;
    });

    const deleteRow = (rowIdx: number) => {
      let editFieldValue = _.cloneDeep(props.fieldValue);
      editFieldValue.rows.splice(rowIdx, 1);

      context.emit('input', editFieldValue);
    };

    const tableCellStyle = (idx: number) => {
      if (idx !== -1)
        return {
          maxWidth: props.fieldValue.width[idx] + 'px',
          minWidth: props.fieldValue.width[idx] + 'px',
        };
    };

    // const changeColumnWidth = (colIdx: number, val: string) => {
    //   let editFieldValue = _.cloneDeep(props.fieldValue);
    //

    //   editFieldValue.width[colIdx] = val;
    //   context.emit('input', editFieldValue);
    // };

    const changeTableCell = (
      rowIdx: number,
      colIdx: number,
      newTableCell: TableCellDefinition
    ) => {
      let editFieldValue = _.cloneDeep(props.fieldValue);

      editFieldValue.rows[rowIdx][colIdx] = newTableCell;
      context.emit('input', editFieldValue);
    };

    const onClickInput = (colIdx: number, rowIdx: number, evt: Event) => {
      let payload: PayloadVal = {
        fieldType: 'tableField',
        colIdx: colIdx,
        rowIdx: rowIdx,
      };
      evt.stopPropagation();

      context.emit('click-option', payload);
    };

    // const color = (cell: TableCellDefinition) => {
    //   if (props.showWrongAnswers && !cell.isCorrect()) {
    //     return 'negative';
    //   } else {
    //     return 'primary';
    //   }
    // };

    return {
      OptionValue,
      linkCellRow,
      linkCellColumn,
      changeValue,
      changeLabel,
      onDelete,
      changeRows,
      changeInput,
      tableAddColumn,
      tableAddRow,
      changeColumnTitle,
      deleteCell,
      // showMoreSettings,
      currentSelectedCell,
      // showMoreSettingsDialog,
      currentSelectedRow,
      currentSelectedCol,
      deleteLinkedCell,
      deleteColumnHeader,
      rowList,
      columnOptionList,
      deleteRow,
      tableCellStyle,
      // changeColumnWidth,
      changeTableCell,
      onClickInput,
      // color,
    };
  },
});
