import { mapViewToRoute } from './../config/viewsConfig';
import { route } from 'quasar/wrappers';
import routes from './routes';
import { isLoginValid } from 'src/library/auth';
import VueRouter from 'vue-router';
import { Store } from 'vuex';
import store from 'src/store/directStore';
// interfaces
import { StoreInterface } from '../store';
// compositions
import authHandling from 'src/compositions/AuthHandling';
import { useRequestHandling } from 'src/compositions/RequestHandling';
/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

export default route<Store<StoreInterface>>(function ({ Vue }) {
  Vue.use(VueRouter);

  const Router = new VueRouter({
    scrollBehavior: () => ({ x: 0, y: 0 }),
    routes,

    // Leave these as is and change from quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    mode: process.env.VUE_ROUTER_MODE,
    base: process.env.VUE_ROUTER_BASE,
  });

  // eslint-disable-next-line
  Router.beforeEach(async (to, from, next) => {
    const { canView } = authHandling();

    const { refreshRecordList, refreshOrganisation } = useRequestHandling();

    // eslint-disable-next-line
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      try {
        await isLoginValid();

        if (store.state.deviceAdmin.organisation === undefined) {
          await refreshOrganisation();
        }
        if (store.state.deviceAdmin.roleConfigList.length === 0) {
          await refreshRecordList('roleConfig');
        }

        if (to.meta) {
          const meta = to.meta as { viewName: string };

          console.log('canView(to.fullPath)', canView(to.path));

          if (meta.viewName) {
            if (canView(meta.viewName)) {
              next();
            } else {
              if (store.state.deviceAdmin.auth) {
                const auth = store.state.deviceAdmin.auth;
                const role = store.state.deviceAdmin.roleConfigList.find(
                  (entry) => entry.name === auth.role
                );
                if (role) {
                  next(mapViewToRoute(role.startPage));
                }
              } else {
                next('/login');
              }
            }
          } else {
            next();
          }
        } else {
          next();
        }
      } catch (error) {
        next('/login');
      }
    }
    // eslint-disable-next-line
    else if (to.matched.some((record) => record.meta.guest)) {
      if (localStorage.getItem('token') == null) {
        next();
      } else {
        next('/');
      }
    } else {
      next();
    }
  });

  return Router;
});
