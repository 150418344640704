import { Type } from 'class-transformer';
import 'reflect-metadata';
import { v4 } from 'uuid';
//interfaces
import { Check, CheckDefinition } from './Check.Interface';
import { Device, DeviceType, PartnerConfigValue } from './Device.Interface';
import { Formular } from './Form.Interface';
import { OrganisationLocalLocation } from './Location.Interface';
import { RoleDefinition, RoleConfig } from './Roles.Interface';
import { OrganisationValue } from './Settings.Interface';
import { Customer, ServiceProvider, User, UserContact } from './User.Interface';
import { ApplicationLog } from './Log.Interface';
import { Report } from './Report.Interface';
import { ImportValue } from './Import.Interface';
import { ShippingTask } from './Shipping.Interface';
import { ActionRequest } from './Action.Interface';

export class BaseRecord {
  _id: string;
  _rev?: string;
  doctype: string;
  constructor(doctype: string, _rev?: string) {
    this._id = v4();
    this._rev = _rev;
    this.doctype = doctype;
  }
}

export class AssignedValue {
  user: string;
  userGroup: string;
  customer: string;
  type: string;
  constructor(user: string, userGroup: string, customer: string, type: string) {
    this.user = user;
    this.userGroup = userGroup;
    this.customer = customer;
    this.type = type;
  }
}

export class CarriedFromValue {
  type: string;
  user: string;
  group: string;
  constructor(type: string, user: string, group: string) {
    this.type = type;
    this.user = user;
    this.group = group;
  }
}

export type RecordTypes =
  | Device
  | Formular
  | DeviceType
  | CheckDefinition
  | User
  | OrganisationLocalLocation
  | ApplicationLog
  | ActionRequest
  | OrganisationValue
  | Notification
  | ServiceProvider
  | Customer
  | RoleDefinition
  | PartnerConfigValue
  | FileMetadata
  | UserContact
  | ShippingTask
  | ImportValue
  | Report
  | RoleConfig;

export interface SamePeriodPartnerCheckList {
  carriedFromType: string;
  checkDate: Date;
  period: IntervallValue;
  partnerList: {
    partnerId: string;
    checkDefinitionId: string;
    checkDate: Date;
  }[];
}

export interface TablePaginationValue {
  sortBy: string;
  // descending: boolean;
  // page: number;
  rowsPerPage: number;
  // rowsNumber: number;
}
export interface ServerConnectionStatusValue {
  color: string;
  status: string;
  message: string;
}

export interface MailServerValue {
  host: string;
  port: number;
  user: string;
  pass: string;
  mailHeaderLogo: string;
  contactMail: string;
  contactPhone: string;
}
export interface NavigationConfigValue {
  label: string;
  icon: string;
  name: string;
}

export interface NavigationDefinition {
  [key: string]: {
    navList: RouterValue[];
  };
}
export interface RouterValue {
  title: string;
  caption: string;
  icon: string;
  link: string;
  name: string;
}
export class Request {
  _id?: string;
  _rev?: string;
  doctype = 'request';
  deviceId: string;
  date: Date;
  status: string;
  userId: string;
  constructor(deviceId: string, date: Date, status: string, userId: string) {
    this.deviceId = deviceId;
    this.date = date;
    this.status = status;
    this.userId = userId;
  }
}

export class Notification {
  _id?: string;
  _rev?: string;
  doctype: string;
  read: boolean;
  text: string;
  category: string;
  @Type(() => AssignedValue)
  assignedTo: AssignedValue;
  @Type(() => Date)
  created: Date;
  actionRequestId?: string;
  linkTo?: string;
  linkId?: string;

  constructor(
    text: string,
    category: string,
    actionRequestId: string | undefined = undefined,
    assignedTo: AssignedValue = new AssignedValue('', '', '', 'system'),
    read = false,
    created = new Date(),
    linkTo = '',
    linkId = ''
  ) {
    this.doctype = 'notification';
    this.text = text;
    this.category = category;
    this.assignedTo = assignedTo;
    this.read = read;
    this.created = created;
    this.actionRequestId = actionRequestId;
    this.linkTo = linkTo;
    this.linkId = linkId;
  }
}

export class FileMetadata {
  _id?: string;
  _rev?: string;
  doctype = 'fileMetadata';
  name: string;
  type?: string;
  constructor(name: string, type = 'none') {
    this.name = name;
    this.type = type;
  }
}
export class Task {
  _id?: string;
  _rev?: string;
  doctype = 'task';
  done: boolean;
  text: string;
  constructor(done: boolean, text: string) {
    this.done = done;
    this.text = text;
  }
}
export class SystemLog {
  _id?: string;
  _rev?: string;
  doctype: string;
  logType: string;
  date: Date | string;
  constructor(doctype: string, logType: string, date: Date | string) {
    this.doctype = doctype;
    this.logType = logType;
    this.date = date;
  }
}

export class OptionValue {
  value: string | boolean | number;
  label: string;
  constructor(value: string | number | boolean, label: string) {
    this.value = value;
    this.label = label;
  }
}
export class ExtendOptionValue extends OptionValue {
  belongDefinition: string;
  constructor(value: string | number, label: string, belongDefinition: string) {
    super(value, label);
    this.belongDefinition = belongDefinition;
  }
}

export class OptionValueIndex {
  value: string;
  index: number;
  constructor(value: string, index: number) {
    this.value = value;
    this.index = index;
  }
}

export interface LanguageValue {
  value: string;
  label: string;
}

export class IntervallValue {
  value: number;
  unit: string;
  constructor(value = -1, unit = 'none') {
    this.value = value;
    this.unit = unit;
  }
  displayValue() {
    return this.value.toString() + ' ' + this.unit;
  }
}

// export interface AuthServerResponse {
//   // isLoggedIn: boolean;
//   // token: string;
//   // user: {
//   firstname: string;
//   lastname: string;
//   email: string;
//   // userId: string;
//   role: string;
//   // pages: { [key: string]: boolean };
//   // };
// }

export interface ValidUser {
  firstname: string;
  lastname: string;
  email: string;
  role: string;
  userId: string;
  organisation: string;
}

export interface AuthValue {
  isLoggedIn: boolean;
  token: string;
  database: string;
  firstname: string;
  lastname: string;
  email: string;
}

export interface ItemListValue {
  id: string;
  label: string;
  caption: string;
}

export class Patient {
  firstname: string;
  lastname: string;
  id: string;
  constructor(id: string, firstname: string, lastname: string) {
    this.id = id;
    this.firstname = firstname;
    this.lastname = lastname;
  }
}

////
///
/// switch(command)
///case: "fill-check-form"
///case "spac-shipping-date"
///case ""
///case ""
///
///
export interface EntityData {
  [key: string]: string;
}

export interface KeyStringObjectValue {
  [key: string]: string | KeyStringObjectValue[];
}

export interface LocationTreeValue {
  _id: string;
  label: string;
  icon: string;
  children: LocationTreeValue[];
}

export class SettingValue {
  label: string;
  name: string;
  value: string | boolean;
  type: string;
  category: string;
  icon: string;

  constructor(
    label: string,
    name: string,
    value: string | boolean,
    type: string,
    category = 'general',
    icon = ''
  ) {
    this.label = label;
    this.name = name;
    this.value = value;
    this.type = type;
    this.category = category;
    this.icon = icon;
  }
}

export class CommandData {
  command: string;
  // payload: StartDeliveryCheckCommand | EditCheckCommand;
  payload: CheckCommandValue;
  constructor(
    command: string,
    // payload: StartDeliveryCheckCommand | EditCheckCommand
    payload: CheckCommandValue
  ) {
    this.command = command;
    this.payload = payload;
  }
}

export interface StartDeliveryCheckCommand {
  deviceId: string;
}

export interface EditCheckCommand {
  deviceId: string;
  check: Check;
}
export interface StartCheckCommand {
  deviceId: string;
  checkDefinition: string;
}
export interface CheckCommandValue {
  deviceId: string;
  checkDefinition?: string;
  check?: Check;
}
