




























































































import { defineComponent, ref, onMounted } from '@vue/composition-api';
import _ from 'lodash';
// import Vue from 'vue';
//components
import {
  FormSection,
  FormSectionStepDefinition,
  Formular,
  TableFieldValue,
} from 'src/interfaces/Form.Interface';
import gwRadioField from 'src/components/formular/fieldComponents/gwRadioField/gwRadioField.vue';
import gwCheckboxField from 'src/components/formular/fieldComponents/gwCheckboxField/gwCheckboxField.vue';
import gwValueTable from 'src/components/formular/fieldComponents/gwValueTable/gwValueTable.vue';
import gwTextField from 'src/components/formular/fieldComponents/gwTextField/gwTextField.vue';
import GwTextEditor from '../basic/gwTextEditor/gwTextEditor.vue';
//interfaces

export default defineComponent({
  name: 'FormShower',
  components: {
    gwRadioField,
    gwCheckboxField,
    gwValueTable,
    gwTextField,
    GwTextEditor,
  },
  props: {
    value: {
      type: Object as () => Formular,
      required: true,
    },
    previewFormId: {
      type: String,
    },
    getIdFrom: {
      type: String,
    },
    showTestCheckButton: {
      type: Boolean,
      default: false,
    },
    formReadonly: {
      type: Boolean,
      default: false,
    },
    showTitleToolbar: {
      type: Boolean,
      default: false,
    },
    previewMode: {
      type: Boolean,
      default: true,
    },
    showFormSectionStepper: {
      type: Boolean,
      default: true,
    },
    stepperNavigation: {
      type: Boolean,
      default: false,
    },
    submitOnlyIfCompleted: {
      type: Boolean,
      default: true,
    },
    submitOnlyIfSuccesfull: {
      type: Boolean,
      default: true,
    },
    editMode: {
      type: Boolean,
      default: false,
    },
    hideEmptyFieldDescription: {
      type: Boolean,
      default: false,
    },
    currentUserType: {
      type: String,
      required: true,
    },
    highlightWrongFields: {
      type: Boolean,
      default: true,
    },
    showWrongAnswers: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    // const previewFormular = ref<Formular>(_.cloneDeep(props.value));

    onMounted(() => {
      // console.log('formShower onMounted', previewFormular.value);

      if (props.value.currentStep === -1) {
        // previewFormular.value.currentStep = 0;
        const editFormular = _.cloneDeep(props.value);
        editFormular.currentStep = 0;
        context.emit('input', editFormular);
      }
    });

    const canShowSection = (section: FormSection) => {
      let show = false;

      if (props.previewMode) {
        if (
          props.currentUserType === props.value.getCurrentCarriedFrom()?.type
        ) {
          const find = props.value.steps[
            props.value.currentStep
          ].sectionIds.find((entry) => entry === section.id);
          if (find) show = true;
        }
      } else {
        let stepDefinition: undefined | FormSectionStepDefinition;
        for (const step of props.value.steps) {
          for (const sectionId of step.sectionIds) {
            if (sectionId === section.id) {
              stepDefinition = step;
            }
          }
        }

        if (stepDefinition) {
          if (
            props.currentUserType !== stepDefinition.carriedFrom.type &&
            stepDefinition.onlyVisibleForInspector
          ) {
            show = false;
          } else {
            show = true;
          }
        } else {
          show = true;
        }
      }

      return show;
    };

    const step = ref<number>(0);

    const onClickPreviousSection = () => {
      step.value--;
    };

    const onClickNextSection = () => {
      if (step.value === props.value.sections.length - 1)
        context.emit('finish-form');

      step.value++;
    };

    const changeInput = (
      secIdx: number,
      fieldIdx: number,
      val: TableFieldValue
    ) => {
      // console.log('changeInput secIdx', secIdx);
      // console.log('changeInput fieldIdx', fieldIdx);
      // console.log('changeInput val', val);

      const editFormular = _.cloneDeep(props.value);

      editFormular.sections[secIdx].fields[fieldIdx].optionList = val;
      // Vue.set(
      //   previewFormular.value.sections[secIdx].fields[fieldIdx],
      //   'optionList',
      //   val
      // );

      context.emit('input', editFormular);
      // console.log('changeInput', editFormular);
    };

    const testCheck = () => {
      if (props.value.isCompleteFilledOut()) {
        context.root.$q.notify('Alle erforderlichen Felder ausgefüllt');
      } else {
        context.root.$q.notify('Nicht alles ausgefüllt');
      }
      if (props.value.isSuccessful()) {
        context.root.$q.notify('Erfolgreich');
      } else {
        context.root.$q.notify('Antworten nicht korrekt');
      }
    };

    const isFieldDescriptionReadonly = () => {
      let tmp = true;
      if (props.editMode) {
        tmp = false;
      }
      return tmp;
    };

    const isfieldReadonly = (section: FormSection) => {
      let readonly = false;

      if (props.formReadonly === true && props.editMode === false) {
        readonly = true;
      } else {
        let stepDef: FormSectionStepDefinition | undefined;
        for (const step of props.value.steps) {
          let find;
          find = step.sectionIds.find((entry) => entry === section.id);
          if (find) {
            stepDef = step;
          }
        }

        if (stepDef && !props.editMode) {
          if (stepDef.carriedFrom.type !== props.currentUserType) {
            if (stepDef.onlyCurrentInspectorCanEdit) {
              readonly = true;
            } else {
            }
          }
        }
      }

      return readonly;
    };

    const changeStep = (step: number) => {
      const editFormular = _.cloneDeep(props.value);
      editFormular.currentStep = step;
      context.emit('input', editFormular);
    };

    return {
      // previewFormular,
      step,
      canShowSection,
      onClickPreviousSection,
      onClickNextSection,
      changeInput,
      testCheck,
      isFieldDescriptionReadonly,
      isfieldReadonly,
      changeStep,
    };
  },
});
