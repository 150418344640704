export class ColumnValue {
  name: string;
  label: string;
  field: string;
  align: string;
  sortable: boolean;
  link?: string;
  type?: string;
  style: { [key: string]: string };
  constructor(
    name: string,
    label = '',
    field = '',
    align = 'left',
    sortable = true,
    link: undefined | string = undefined,
    type: undefined | string = undefined,
    style = {}
  ) {
    this.name = name;
    this.label = label;
    this.field = field;
    this.align = align;
    this.sortable = sortable;
    this.link = link;
    this.type = type;
    this.style = style;
  }
}

// export interface LinkValue {
//   link:string
// }

export interface TableData {
  [key: string]:
    | string
    | boolean
    | number
    | undefined
    | Date
    | { name: string; icon: string; style: string; tooltip: string };
}
interface UserTableData {
  id: string;
  rev: string;
  firstname: string;
  lastname: string;
  email: string;
}
interface DeviceTableRowValue {
  // _id: string;
  // name: string;
  // status: number;
  // devicetype: string;
  // inventoryNumber: device.inventoryNumber;
  // user: assignedToUserName;
  [key: string]: string | number;
}

// export type RecordTableData = UserTableData;
export interface RecordTableData {
  [key: string]: string | Date | boolean | undefined;
  _id: string;
  _rev: string;
  archived?: boolean;
}

export function isRecordTableData(obj: unknown): obj is RecordTableData[] {
  if (Array.isArray(obj)) {
    if (obj.length > 0) {
      if ('_id' in obj[0]) {
        if ('_rev' in obj[0]) return true;
      }
    } else return true;
  }
  return false;
}
