import axios from 'axios';
import store from 'src/store/directStore';

//config
import { apiServer } from 'src/config/serverConfig';
import { ValidUser } from 'src/interfaces/Common.Interface';

export const logout = async () => {
  await axios.get(apiServer + '/auth/logout', { withCredentials: true });
};

export const isLoginValid = async () => {
  try {
    const user = await axios.get<ValidUser>(apiServer + '/auth/valid', {
      withCredentials: true,
    });
    store.commit.deviceAdmin.setAuth(user.data);
  } catch (error) {
    throw Error('not valid login');
  }
};

export const resetPassword = () => {
  return;
};
