import { RecordTypes } from './Common.Interface';
import { Type } from 'class-transformer';
import 'reflect-metadata';

export class ChangeValue {
  @Type(() => Date)
  date: Date;
  authorId: string;
  authorName: string;
  authorType?: string;
  changes: PropertyChangedValue;
  constructor(
    date: Date,
    authorId: string,
    authorName: string,
    changes: PropertyChangedValue,
    authorType?: string
  ) {
    this.date = date;
    this.authorId = authorId;
    this.authorName = authorName;
    this.changes = changes;
    this.authorType = authorType;
  }
}

export class CreateDocumentValue {
  @Type(() => Date)
  date: Date;
  authorId: string;
  authorName: string;
  document: RecordTypes;
  constructor(
    date: Date,
    authorId: string,
    authorName: string,
    document: RecordTypes
  ) {
    this.date = date;
    this.authorId = authorId;
    this.authorName = authorName;
    this.document = document;
  }
}

export class ApplicationLog {
  _id?: string;
  _rev?: string;
  doctype: string;
  logDocumentId: string;
  logDocumentDoctype: string;
  @Type(() => ChangeValue)
  changeList: ChangeValue[];
  @Type(() => CreateDocumentValue)
  createdDocument: CreateDocumentValue;
  deletedDocument?: CreateDocumentValue;
  constructor(
    logDocumentId: string,
    logDocumentDoctype: string,
    createdDocument: CreateDocumentValue,
    changeList: ChangeValue[] = [],
    deletedDocument?: CreateDocumentValue
  ) {
    this.doctype = 'applicationLog';
    this.logDocumentId = logDocumentId;
    this.logDocumentDoctype = logDocumentDoctype;
    this.createdDocument = createdDocument;
    this.changeList = changeList;
    this.deletedDocument = deletedDocument;
  }
}

export interface PropertyChangedValue {
  [key: string]: {
    action: string;
  };
}
