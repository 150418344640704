





































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import _ from 'lodash';
import store from 'src/store/directStore';
//components
//interfaces
import { ColumnValue } from '../basic/gwTable/gwTableInterface';
import { CostEntry } from '../../interfaces/Costs.Interface';
import { OptionValue } from 'src/interfaces/Common.Interface';
//confígs
import { dateFormat } from '../../config/settingsConfig';
//compositions

export default defineComponent({
  name: 'CostList',
  components: {},
  props: {
    value: {
      type: Array as () => CostEntry[],
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    showDeviceInventoryNumber: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, context) {
    const data = computed(() => {
      let list: { [key: string]: string }[] = [];

      for (const costEntry of props.value) {
        console.log('unitNUmber', costEntry.cost);

        let inventoryNumber = '';

        if (props.showDeviceInventoryNumber) {
          if (costEntry.deviceId) {
            inventoryNumber =
              store.getters.deviceAdmin.getDeviceInventoryNumberById(
                costEntry.deviceId
              );
          }
        }

        list.push({
          _id: costEntry._id,
          label: costEntry.label,
          cost: costEntry.cost.cssValue(),
          date: costEntry.date.toLocaleDateString('de', dateFormat),
          comment: costEntry.comment,
          inventoryNumber: inventoryNumber,
        });
      }

      return list;
    });

    const columns = ref<ColumnValue[]>([]);
    columns.value.push(new ColumnValue('label', 'Bezeichnung', 'label'));
    columns.value.push(new ColumnValue('cost', 'Kosten', 'cost'));
    columns.value.push(new ColumnValue('date', 'Datum', 'date'));
    columns.value.push(new ColumnValue('comment', 'Kommentar', 'comment'));

    if (props.showDeviceInventoryNumber) {
      columns.value.push(
        new ColumnValue('inventoryNumber', 'Inventarnummer', 'inventoryNumber')
      );
    }

    if (!props.readonly) {
      columns.value.push(new ColumnValue('edit', '', 'edit'));
    }

    const addCostsEntry = () => {
      costEntry.value = new CostEntry();
      showAddDialog.value = true;
    };

    const submitNewCostEntry = () => {
      const editCostList = _.cloneDeep(props.value);

      if (costEntry.value) {
        if (editCostList) {
          let find = editCostList.findIndex(
            (entry) => entry._id === costEntry.value?._id
          );
          if (find !== -1) {
            editCostList[find] = costEntry.value;
          } else editCostList.push(costEntry.value);
        }
        context.emit('input', editCostList);
        showAddDialog.value = false;
      }
    };

    const costEntry = ref<CostEntry>();
    const showAddDialog = ref<boolean>();

    const filter = ref<string>();

    const abortNewEntry = () => {
      costEntry.value = undefined;
      showAddDialog.value = false;
    };

    const onClickEdit = (row: { [key: string]: string; _id: string }) => {
      const editCostList = _.cloneDeep(props.value);
      if (editCostList) {
        const find = editCostList.find((entry) => entry._id === row._id);
        if (find) {
          costEntry.value = find;
          showAddDialog.value = true;
        }
      }
    };

    const serviceProviderOptions = computed(() => {
      const list: OptionValue[] = [];

      for (const serviceProvider of store.state.deviceAdmin
        .serviceProviderList) {
        if (serviceProvider._id) {
          list.push(new OptionValue(serviceProvider._id, serviceProvider.name));
        }
      }

      return list;
    });

    return {
      data,
      columns,
      addCostsEntry,
      filter,
      costEntry,
      showAddDialog,
      submitNewCostEntry,
      abortNewEntry,
      onClickEdit,
      serviceProviderOptions,
    };
  },
});
