import { store } from 'quasar/wrappers';
import DeviceAdminStateInterface  from './deviceAdmin/deviceAdminStateInterface';

/*
 * If not building with SSR mode, you can
 * directly export the Store instantiation
 */

export interface StoreInterface {
  // Define your own store structure, using submodules if needed
  deviceAdmin: DeviceAdminStateInterface;
  // Declared as unknown to avoid linting issue. Best to strongly type as per the line above.
  // example: unknown;
}
import directStore from 'src/store/directStore';
export default store(function() {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return directStore.original;
});
