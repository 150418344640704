
























































import { defineComponent, computed, ref } from '@vue/composition-api';

//components
import FormularList from 'src/components/formular/gwFormularList.vue';
import GWTaskList from 'src/components/basic/GWTaskList.vue';
import DisplayCardNumber from 'src/components/basic/cards/DisplayCardNumber.vue';
import gwKnobCard from 'components/basic/cards/gwKnobCard.vue';
import IncomingCard from 'src/components/shipping/IncomingCard.vue';

import Calendar from 'src/components/dashboard/Calendar.vue';
//interfaces
import { Task } from 'src/interfaces/Common.Interface';
interface TutorialTourValue {
  target: string;
  header?: {
    title: string;
  };
  content: string;
}

//compositions
import { useRequestHandling } from 'src/compositions/RequestHandling';

export default defineComponent({
  name: 'Dashboard',
  components: {
    FormularList,
    GWTaskList,
    DisplayCardNumber,
    gwKnobCard,
    IncomingCard,

    Calendar,
  },
  setup(props, context) {
    const {} = useRequestHandling();

    const taskList = ref<Task[]>([
      new Task(true, 'Task1'),
      new Task(false, 'Task2'),
      new Task(false, 'Task3'),
    ]);
    const deviceCounter = computed(() => {
      return context.root.$store.direct.state.deviceAdmin.deviceList.length;
    });
    const deviceTypeCounter = computed(() => {
      return context.root.$store.direct.state.deviceAdmin.deviceTypeList.length;
    });
    const locationCounter = computed(() => {
      return context.root.$store.direct.state.deviceAdmin.locationList.length;
    });
    const changeDone = (payload600: { index: number; val: boolean }) => {
      taskList.value[payload600.index].done = payload600.val;
    };

    const steps = ref<TutorialTourValue[]>([
      {
        target: '#v-step-0', // We're using document.querySelector() under the hood
        header: {
          title: 'Get Started',
        },
        content: 'Discover <strong>Vue Tour</strong>!',
      },
      {
        target: '#v-step-1',
        content: 'An awesome plugin made with Vue.js!',
      },
    ]);

    return {
      deviceCounter,
      deviceTypeCounter,
      taskList,
      changeDone,
      locationCounter,
      steps,
    };
  },
});
