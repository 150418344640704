
















































import { defineComponent } from '@vue/composition-api';
// components
import DeviceQrCode from 'components/device/DeviceQrCode.vue';
// interfaces
import { Device } from 'src/interfaces/Device.Interface';
//confígs
import { statusConfig } from 'src/config/statusConfig';

export default defineComponent({
  name: 'ShippingDeviceTable',
  components: { DeviceQrCode },
  props: {
    selectedDeviceList: {
      type: Array as () => Device[],
      required: true,
    },
  },
  setup(props, context) {
    const deleteDevice = (deviceIndex: number) => {
      //   selectedDeviceList.value.splice(deviceIndex, 1);
      context.emit('delete-device', deviceIndex);
    };

    return { statusConfig, deleteDevice };
  },
});
